export enum ScaleMode {
    ActualSize = 0,
    PageFit=1,
    PageWidth = 2,
    Scale50 = 3,
    Scale75 = 4,
    Scale100 = 5,
    Scale125 = 6,
    Scale150 = 7,
    Scale200 = 8,
    Scale300 = 9,
    Scale400 = 10,
    ScaleVar = 11,
}