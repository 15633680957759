import ProductivitySection from "./Components/ProductivitySection";
import LearnMoreSection from "./Components/LearnMoreSection";
import TemplateSection from "./Components/TemplateSection";
import FeaturesSection from "./Components/FeaturesSection";
import CustomerFeedbackSection from "./Components/CustomerFeedbackSection";
import ChooseUsSection from "./Components/ChooseUsSection";
import AppLayout from "../../Layouts/AppLayout";
import useDeviceQuery, {ScreenType} from "../../Hooks/useDeviceQuery";
import React from "react"

const Home = () => {
    const device = useDeviceQuery()
    if (device === ScreenType.Macbook){
        return <HomeMacBook/>
    } else {
        return <HomeIpad/>
    }
}

const HomeIpad = () => {
    return (
        <AppLayout>
            <ProductivitySection/>
            <LearnMoreSection/>
            {/*<PlansSection/>*/}
            <TemplateSection/>
            <CustomerFeedbackSection/>
        </AppLayout>
    )
}
const HomeMacBook = () => {
  return (
      (
          <AppLayout>
              <ProductivitySection/>
              <LearnMoreSection/>
              <TemplateSection/>
              <FeaturesSection/>
              <ChooseUsSection/>
              <CustomerFeedbackSection/>
              {/*<PlansSection/>*/}
          </AppLayout>
      )
  )
}

export default Home