import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './receipt';
import { apiRoutes } from 'Api/apiRoutes';
import { ICoinbaseResponse, ICoinbaseResquest,} from 'Types/Interface/ICheckout';
import {getProductionHost} from "./utils";

export const coinbaseApi = createApi({
    reducerPath: 'coinbaseApi',
    baseQuery,
    endpoints: (builder) => ({
        createCharge: builder.mutation<{"chargeResponse":ICoinbaseResponse}, {"chargeRequest":ICoinbaseResquest}>({
            query(body) {
                const data = {
                    ...body,
                    chargeRequest: {
                        ...body.chargeRequest,
                        redirect_Url: `${getProductionHost()}/return`
                    }}
                return {
                    url: apiRoutes.coinbase,
                    method: 'POST',
                    body: data
                };
            },
        })
    }),
});

export const { useCreateChargeMutation } = coinbaseApi;