import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import {IGetQuote, IQuotes} from "../../Types/Interface/IQuotes";
import {reqClient} from "../../Api/reqClient";
import {QuoteSubmissionSchema} from "../../schemas/QuoteSchema";

const submitQuote = async (data: IQuotes, guid?: string, logo?: string): Promise<IFormResponse> => {
    const url = `/Quotes/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const body = {
        taxAmount: data.taxAmount,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        from: {
            id: data.from.id,
            isCompletedForm: true,
            address: {
                id: data.from.address.id,
                isCompletedForm: true,
                addressLine1: data.from.address.addressLine1,
                addressLine2: data.from.address.addressLine2,
                postalCode: data.from.address.postalCode,
                city: data.from.address.city,
                regionOrState: data.from.address.regionOrState,
                country: "United State"
            },
            name: data.from.name,
            email: data.from.email,
            websiteUrl: data.from.websiteUrl,
            phoneNumber: data.from.phoneNumber,
            logo: logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        "for": {
            id: data.for.id,
            isCompletedForm: true,
            address: {
                id: data.for.address.id,
                isCompletedForm: true,
                addressLine1: data.for.address.addressLine1,
                addressLine2: data.for.address.addressLine2,
                postalCode: data.for.address.postalCode,
                city: data.for.address.city,
                regionOrState: data.for.address.regionOrState,
                country: "United state"
            },
            name: data.for.name,
            email: data.for.email,
            websiteUrl: data.for.websiteUrl,
            phoneNumber: data.for.phoneNumber,
            logo: data.for.logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem, index) => ({...lineItem, id: index, isCompletedForm: true})),
        "quoteHtmlString": "",
        "email": data.from.email
    }
    const parsedQuote = QuoteSubmissionSchema.parse(body)
    const response = await reqClient.post(url, parsedQuote)
    return response.data
}

export const getQuote = async (guid: string): Promise<IGetFormResponse<IQuotes>> => {
    const url = `/Quotes/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGetQuote> = response.data
    const transformedData: IQuotes = {
        quoteId: "",
        dueDate: "",
        accept: false,
        agree: false,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        email: "",
        for: {
            address: {
                addressLine1: data.for.address.addressLine1,
                addressLine2: "",
                city: data.for.address.city,
                country: data.for.address.country,
                id: data.for.address.id,
                postalCode: data.for.address.postalCode,
                regionOrState: data.for.address.regionOrState
            },
            email: data.for.email,
            id: data.for.id,
            logo: data.for.logo,
            name: data.for.name,
            phoneNumber: data.for.phoneNumber,
            websiteUrl: data.for.websiteUrl
        },
        from: {
            address: {
                addressLine1: data.from.address.addressLine1,
                addressLine2: "",
                city: data.from.address.city,
                country: data.from.address.country,
                id: data.from.address.id,
                postalCode: data.from.address.postalCode,
                regionOrState: data.from.address.regionOrState
            },
            email: data.from.email,
            id: data.from.id,
            logo: data.from.logo,
            name: data.from.name,
            phoneNumber: data.from.phoneNumber,
            websiteUrl: data.from.websiteUrl
        },
        id: data.id,
        lineItems: data.lineItems.map((lineItem) => ({...lineItem, id: Date.now(), isCompletedForm: undefined})),
        logoUrl: "",
        note: "",
        quoteHtmlString: "",
        taxAmount: data.taxAmount

    }
    return {isSuccess, data: transformedData, errorMessage}
}

export default submitQuote