import useFill1099Nec from "../../../Hooks/useFill1099Nec";
import {Box, Checkbox} from "@chakra-ui/react";
import FormPreviewLayout from "../../../Layouts/FormPreviewLayout";
import React, {memo, useEffect} from "react";
import {I1099Nec} from "../validator";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";
import {set1099NecState} from "../../../Store/Slices/1099NecSlice";
import FormPreviewBar from "../../Components/FormPreviewBar";
import {setDocumentType} from "../../../Store/Slices";
import {documentTypeEnums, FormType} from "../../../Types/Enums/documentTypeEnums";
import {FormikErrors} from "formik";
import PdfViewerV2 from "../../Components/PdfViewerV2";

type SetValues = (field: string, value: boolean, shouldValidate?: boolean) => (Promise<FormikErrors<I1099Nec>> | Promise<void>)

const NecPreview = memo(function NecPreview ({values, setValues, submit}: {values: I1099Nec, setValues: SetValues, submit?: () => void, isMobile?: boolean}) {
    const {data: pdfData, refreshData, loading} = useFill1099Nec(values)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(set1099NecState(values))
    }, [values]);

  return (
      <FormPreviewLayout>
          <Box >
              <PdfViewerV2 file={pdfData} refresh={() => { refreshData() }} loading={loading}></PdfViewerV2>
          </Box>
          <Box borderBottom={"1px"} pb={"24px"} mt={"24px"} borderBottomColor={"#00000080"}>
              <Checkbox colorScheme={"green"} name={"info.agree"} isChecked={values.info.agree} onChange={() => setValues("info.agree", !values.info.agree).catch(() => {})} mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
              <Checkbox colorScheme={"green"} isChecked={values.info.accept} onChange={() => setValues("info.accept", !values.info.accept).catch(() => {})} name={"info.accept"}> I accept our Term of Use and Privacy Policy</Checkbox>
          </Box>
          <FormPreviewBar setDocumentType={() => {
              dispatch(setDocumentType(documentTypeEnums.F1099))
          }} submit={submit} formType={FormType.F1099Nec} background={!(values.info.accept && values.info.agree) ? "grey" : "primary"} disabled={!(values.info.accept && values.info.agree)} />
      </FormPreviewLayout>
  )
})

export default NecPreview