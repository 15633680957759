import {useLayoutEffect, useState} from "react";

const useMobilePreview = () => {
    const [isNextPage, setIsNextPage] = useState(false)

    useLayoutEffect(() => {
        // scrolls to top once component in view
        window.scrollTo({top:0, left:0, behavior: "auto"})
    }, [isNextPage]);

  return {isNextPage, setIsNextPage}
}

export default useMobilePreview