import {
    Box,
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import {DocumentMap, FormType} from "../Types/Enums/documentTypeEnums";
import React from "react";

type PreviewModalProps = {
    isOpen: boolean
    onClose: () => void
    formType?: FormType
    preview: boolean
    setPreview: (preview: boolean) => void
    disabled: boolean
    submit?: () => void
    background: string
}

const PreviewModal = ({setPreview, preview, submit, disabled, formType, isOpen, background, onClose}: PreviewModalProps) => {
  return (
      <Modal isOpen={isOpen} size={"3xl"} onClose={onClose} useInert={false}>
          <ModalOverlay />
          <ModalContent height={"95%"} margin={2} >
              <ModalHeader>{formType}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                  <Box height={"100%"}>
                      {preview && formType ? DocumentMap({ formType }) : "Form not available"}
                  </Box>
              </ModalBody>

              <ModalFooter>
                  <Button mr={3} onClick={() => {
                      setPreview(false)
                      onClose()
                  }}>
                      Close
                  </Button>
                  <Button type={"submit"} disabled={disabled} onClick={submit} background={background}>
                      Checkout
                  </Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
  )
}

export default PreviewModal