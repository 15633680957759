import { reqClient } from "Api/reqClient"
import { useCallback, useEffect, useState } from "react"
import {useDebouncedCallback} from "use-debounce";
import I1099Misc from "../Types/Interface/I1099Misc";
import {F1099MiscFillSchema} from "../schemas/F1099MiscSchema";


export const transformMiscData = (f1099Data: I1099Misc) => {
    return {
        "_1099MISCDto": {
        "type": 0,
            "year": parseInt(f1099Data.info.year ?? new Date().getFullYear().toString()),
            "numberOfForms": f1099Data.info.formCount,
            "lastYearIncomeBeforeTaxes": f1099Data.recipients[0].options.otherIncome ?? 0,
            "email": f1099Data.info.email ?? "",
            "correctedIfChecked": "string",
            "accountNumber": f1099Data.recipients[0].options.accountNumber ?? "",
            "formCount": f1099Data.info.formCount,
            "payer": {
            "id": 0,
                "name":  f1099Data.payer.name,
                "tin":  f1099Data.payer.tin,
                "address": f1099Data.payer.address.stateAddress,
                "state": f1099Data.payer.address.state,
                "city": f1099Data.payer.address.city,
                "zipCode": f1099Data.payer.address.zipcode,
                "payerMadeDirectSalesOver5000": f1099Data.recipients[0].options.directSalesHigh ? "On" : "Off",
                "stateIncome": f1099Data.recipients[0].options.stateIncome ?? 0,
                "stateTaxWithheld": f1099Data.recipients[0].options.stateTax ?? 0,
                "federalIncomeTaxWithHeld": f1099Data.recipients[0].options.federalTaxIncomeWithheld ?? 0,
                "statePayerStateNumber": f1099Data.recipients[0].options.statePayerNo
        },
        "recipient": {
            "id": 0,
                "name": f1099Data.recipients[0].name,
                "address": f1099Data.recipients[0].address.stateAddress,
                "street":  "",
                "state":  f1099Data.recipients[0].address.state,
                "city":  f1099Data.recipients[0].address.city,
                "zipCode":  f1099Data.recipients[0].address.zipcode,
                "tin":  f1099Data.recipients[0].tin
        },
        "options": {
            "fatcaFiling": f1099Data.recipients[0].options.FATCAFiling ? "On" : "Off",
                "accountNumber": f1099Data.recipients[0].options.accountNumber ?? "",
                "cropInsurance": f1099Data.recipients[0].options.cropInsurance ?? 0,
                "directSalesHigh": f1099Data.recipients[0].options.directSalesHigh ? 1 : 0,
                "fishPurchased": f1099Data.recipients[0].options.fishPurchased ?? 0,
                "fishingBoatProceeds": f1099Data.recipients[0].options.fishingBoatProceeds ?? 0,
                "excessGoldenParachute": f1099Data.recipients[0].options.goldenParachute ?? 0,
                "grossProceeds": f1099Data.recipients[0].options.grossProceeds ?? 0,
                "stateIncome": f1099Data.recipients[0].options.stateIncome ?? 0,
                "medicalHealthcarePayments": f1099Data.recipients[0].options.medicalHealthPayments ?? 0,
                "nonqualifiedDefferedCompensation": f1099Data.recipients[0].options.nonQualifiedCompensation ?? 0,
                "rents": f1099Data.recipients[0].options.rents ?? 0,
                "royalties": f1099Data.recipients[0].options.royalties ?? 0,
                "section409AReferals": f1099Data.recipients[0].options.section409 ?? 0,
                "stateTaxWithheld": f1099Data.recipients[0].options.stateTax ?? 0,
                "subPayments": f1099Data.recipients[0].options.subPayments ?? 0,
                "otherIncome": f1099Data.recipients[0].options.otherIncome ?? 0,
                "federalTaxIncomeWithheld": f1099Data.recipients[0].options.federalTaxIncomeWithheld ?? 0
        }
    }
    }
}

const useFill1099Misc = (f1099Data: I1099Misc) => {
    const [data, setData] = useState<File>()
    const [loading, setLoading] = useState(true);
    const refreshData = useCallback(async () => {
        setLoading(true)
        try {
            const fData = await reqClient.post("/Fill1099Misc", F1099MiscFillSchema.parse(transformMiscData(f1099Data)), {responseType: "blob"})
            setData(fData.data)
        } catch (e) {
           // throw new Error("Error occurred while fetching 1099")
        }

        setLoading(false)
    }, [f1099Data])

    const debounce = useDebouncedCallback(
        refreshData, 1000
    )

    useEffect(() => {
        debounce()
    }, [debounce, refreshData])

    return { data, refreshData, loading }
}


export default useFill1099Misc