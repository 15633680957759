import { z } from "zod";
import {currentYear} from "../Services/utils";

export const W2SubmissionSchema = z.object({
    w2Dto: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        taxYear: z.coerce.number().min(2015, {message: `Year must be between 2015 and ${currentYear()}`}).max(currentYear(), {message: `Year must not be greater than ${currentYear()}`}),
        numberOfForms: z.number(),
        employerId: z.number(),
        employerInfo: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            name: z.string().nonempty({message: "Employer name must be provided"}),
            email: z.string().nonempty({message: "Employer email must be provided"}),
            addressLine1: z.string().nonempty({message: "Employer Address must be provided"}),
            addressLine2: z.string(),
            zipCode: z.string().nonempty({message: "Employer Zip code must be provided"}),
            phoneNumber: z.string(),
            logo: z.string(),
            imageFilePath: z.string(),
            imageFileName: z.string(),
            ein: z.string(),
            stateTaxEinId: z.string().nonempty({message: "Employer Tax id must be provided"}),
            controlNumber: z.string().optional(),
            state: z.string().nonempty("Employer state must be provided"),
            city: z.string().nonempty({message: "Employer city must be provided"}),
            employerStateIdNumber: z.string().nonempty({message: "Employer employer state id must be provided"}),
        }),
        w2Employees: z.array(
            z.object({
                firstName: z.string().nonempty("Employee first name must be provided"),
                lastName: z.string().nonempty("Employee last name must be provided"),
                addressLine1: z.string().nonempty({message: "Employee address must be provided"}),
                addressLine2: z.string(),
                zip: z.string().nonempty({message: "Employee zip code must be provided"}),
                city: z.string().nonempty({message: "Employee city must be provided"}),
                state: z.string().nonempty({message: "Employee state must be provided"}),
                ssn: z.string().length(11, {message: "Employee ssn not valid"}),
                employeeNumber: z.string(),
                email: z.string().nonempty({message: "Employee email must be provided"}),
                isMarried: z.boolean(),
                annualIncome: z.number().gte(0, {message: "Provide annual income"}),
                dependentCareBenefits: z.number(),
                socialSecurityTips: z.number(),
                socialSecurityWages: z.number(),
                allocatedTips: z.number(),
                statutoryEmployee: z.string(),
                retirementPlan: z.string(),
                thirdPartySickPay: z.string(),
                taxCode12A: z.string(),
                taxCode12AValue: z.number(),
                taxCode12B: z.string(),
                taxCode12BValue: z.number(),
                taxCode12C: z.string(),
                taxCode12CValue: z.number(),
                taxCode12D: z.string(),
                taxCode12DValue: z.number(),
                localWagesTips: z.number(),
                localIncomeTax: z.number(),
                wagesTipsOtherCompensation: z.number(),
                tax: z.object({
                    id: z.number(),
                    isCompletedForm: z.boolean(),
                    federalIncomeTax: z.number(),
                    socialSecurityTax: z.number(),
                    medicareTax: z.number(),
                    stateIncomeTax: z.number(),
                }),
                suffix: z.string(),
                initials: z.string(),
                zipCode: z.string(),
                medicareWagesAndTips: z.number(),
                stateWagesTips: z.number(),
                stateIncomeTax: z.number(),
                localityName: z.string(),
                controlNumber: z.string().optional(),
            })
        ),
    }),
});