import {
    Box,
    Button,
    Center,
    Flex,
    useDisclosure
} from "@chakra-ui/react";
import { FormType } from "../../Types/Enums/documentTypeEnums";
import React, { useState } from "react";
import { Document } from "Store/Slices";
import PreviewModal from "../../Components/PreviewModal";

const FormPreviewBar = ({ background, disabled, setDocumentType, formType, submit }: { background: string, disabled: boolean, setDocumentType: () => void, values?: Document, formType?: FormType, submit?: () => void }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [preview, setPreview] = useState(false)

    return (
        <>
            <PreviewModal
                formType={formType}
                onClose={onClose}
                isOpen={isOpen}
                background={background}
                setPreview={(prev) => setPreview(prev)}
                preview={preview}
                disabled={disabled}
                submit={submit}/>
            <Center>
                <Flex direction={"column"} align={"center"}>
                    <Flex gap={"24px"} mt={"24px"}>
                        <Button onClick={submit} disabled={disabled} background={background}>
                            Checkout
                        </Button>
                        <Button variant={"outline"} onClick={() => {
                            onOpen()
                            setDocumentType()
                            setPreview(true)
                        }}>
                            Preview
                        </Button>
                    </Flex>
                </Flex>
            </Center>
            <Box h={"20"}>
            </Box>
        </>
    )
}

export default FormPreviewBar