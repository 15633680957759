import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import IW2, { IGetW2,} from "../../Types/Interface/IW2";
import {transformW2Data} from "../../Hooks/useFillW2";
import {reqClient} from "../../Api/reqClient";
import {W2SubmissionSchema} from "../../schemas/W2Schema";
import {v4 as uuidv4} from "uuid";


const submitW2 = async (data: IW2, guid?: string): Promise<IFormResponse> => {
    const url = `/W2/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformW2Data(data)
    const body = {
        w2Dto: {
            ...transformedData.dto,
            id: 0,
            isCompletedForm: true,
            employerId: 0,
            employerInfo: {...transformedData.dto.employerInfo, isCompletedForm: true, id: 0},
            w2Employees: [
                {
                    ...transformedData.dto.employee,
                    tax: {...transformedData.dto.employee.tax, isCompletedForm: true, id: 0},
                }
            ]
        }
    }
    const parsedData = W2SubmissionSchema.parse(body)
    const response = await reqClient.post(url, parsedData)
    return response.data
}

export const getW2 = async (guid: string): Promise<IGetFormResponse<IW2>> => {
    const url = `/W2/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGetW2> = response.data
    const transformedData: IW2 = {
        accept: false,
        agree: false,
        downloaded: false,
        employees: [
            {
                id: uuidv4(),
                firstName: data.w2Employees[0].firstName,
                lastName: data.w2Employees[0].lastName,
                address: {
                    country: "United States",
                    stateAddress: data.w2Employees[0].addressLine1,
                    zipcode: data.w2Employees[0].zip,
                    city: data.w2Employees[0].city,
                    state: data.w2Employees[0].state,
                },
                email: data.w2Employees[0].email,
                ssn: data.w2Employees[0].ssn,
                maritalStatus: data.w2Employees[0].isMarried ? "married" : "single",
                income: data.w2Employees[0].annualIncome,
                isStatutory: data.w2Employees[0].statutoryEmployee === "On",
                hasRetirementPlan: data.w2Employees[0].retirementPlan === "On",
                receivesSickPay: data.w2Employees[0].thirdPartySickPay === "On",
                socialSecurityWages: data.w2Employees[0].socialSecurityWages,
                medicareWagesAndTips: data.w2Employees[0].medicareWagesAndTips,
                tax: {
                    federalIncome: data.w2Employees[0].tax.federalIncomeTax,
                    socialSecurity: data.w2Employees[0].tax.socialSecurityTax,
                    medicare: data.w2Employees[0].tax.medicareTax,
                    stateIncome: data.w2Employees[0].tax.stateIncomeTax,
                },
                tips: {
                    socialSecurity: data.w2Employees[0].socialSecurityTips,
                    allocated: data.w2Employees[0].allocatedTips
                },
                controlNumber: data.w2Employees[0].controlNumber,
                dependentCareBenefits: data.w2Employees[0].dependentCareBenefits,
                others: [
                ],
                localIncome: {
                    income: data.w2Employees[0].localWagesTips,
                    tax: data.w2Employees[0].localIncomeTax,
                    name: data.w2Employees[0].localityName
                },
                taxCodeA: {
                  code: data.w2Employees[0].taxCode12A,
                  amount: data.w2Employees[0].taxCode12AValue,
                },
                taxCodeB: {
                    code: data.w2Employees[0].taxCode12B,
                    amount: data.w2Employees[0].taxCode12BValue
                },
                taxCodeC: {
                    code: data.w2Employees[0].taxCode12C,
                    amount: data.w2Employees[0].taxCode12CValue
                },
                taxCodeD: {
                    code: data.w2Employees[0].taxCode12D,
                    amount: data.w2Employees[0].taxCode12DValue
                },
            }
        ],
        employer: {
            companyName: data.employerInfo.name,
            taxId: data.employerInfo.ein,
            stateId: data.employerInfo.employerStateIdNumber,
            email: data.employerInfo.email,
            address: {
                state: data.employerInfo.state,
                city: data.employerInfo.city,
                stateAddress: data.employerInfo.addressLine1,
                country: "United State",
                zipcode: data.employerInfo.zipCode
            },
        },
        formCount: data.w2Employees.length,
        state: data.w2Employees[0].state,
        // year: data.taxYear.toString()
        year: new Date().getFullYear().toString()
    }
    return {isSuccess, data: transformedData, errorMessage}
}

export default submitW2