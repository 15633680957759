import { Box, Flex, Wrap, WrapItem, Text, FormControl, FormLabel, Input, Checkbox } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react"
import { ICompany } from "../../../../Types/Interface/IPaystub";
import StateDropSelect from "../../../Components/StateDropSelect";

type CompanyFormProps = {
    handleChange?: (e: ChangeEvent) => void
    company: ICompany,
    handleLogo: (image: File) => Promise<void>
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

type BaseInputProp = {
    value?: number | string,
    isChecked?: boolean,
    handleChange?: (e: ChangeEvent) => void
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

const CompanyInfoForm = ({ company, handleChange, handleLogo, setFieldValue }: CompanyFormProps) => {
    const [showLogo, setShowLogo] = useState(false)

    const displayLogoUpload = () => {
        setShowLogo(!showLogo)

        if (showLogo && setFieldValue)
        {
            setFieldValue("company.logo", "")
        }
    }

    return (
        <Box mb={"49px"}>
            <CompanyInfoHeading />
            <CompanyEmailInput value={company.email} handleChange={handleChange}/>
            <Wrap justify={"space-between"} gap={"24px"} mb={"16px"} mt={"16px"}>
                <WrapItem w={{base: "100%", md: "37%"}}>
                    <CompanyNameInput value={company.name} handleChange={handleChange} />
                </WrapItem>
                <WrapItem w={{base: "100%", md: "60%"}}>
                    <CompanyAddressInput value={company.address} handleChange={handleChange} />
                </WrapItem>
            </Wrap>
            <Wrap justify={"space-between"} gap={"24px"} align={"end"} mb={"16px"}>
                <WrapItem w={{base: "100%", md: "37%"}}>
                    <CompanyCityInput value={company.city} handleChange={handleChange} />
                </WrapItem>
                <WrapItem w={{base: "45%", md: "30%"}}>
                    <CompanyStateSelector value={company.state} handleChange={handleChange} />
                </WrapItem>
                <WrapItem w={{base: "50%", md: "27%"}}>
                    <CompanyZipCodeInput value={company.zipcode} handleChange={handleChange} />
                </WrapItem>
            </Wrap>
            <Flex mt={"10px"} gap={"20px"}>
                <Checkbox flexBasis={"100%"} onChange={displayLogoUpload}> Add Logo? </Checkbox>
                    {
                    showLogo && 
                    <FormControl flexBasis={"80%"}>
                        <Input type={'file'} onChange={async (e) => {
                                if (e.target.files !== null) {
                                    await handleLogo(e.target.files[0])
                                }
                            }
                        } />
                    </FormControl>
                }
                
            </Flex>
        </Box>
    )
}

const CompanyInfoHeading = () => {
    return (
        <Box mb={"30px"} borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"} >
                Company&apos;s Information
            </Text>
        </Box>
    )
}

const CompanyCityInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl isRequired flexBasis={"100%"}>
            <FormLabel>
                City
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.city"} type={"text"} />
        </FormControl>
    )
}
const CompanyEmailInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl isRequired flexBasis={"100%"}>
            <FormLabel>
                Company Email
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.email"} type={"email"} />
        </FormControl>
    )
}

const CompanyAddressInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl isRequired>
            <FormLabel>
                Company Address
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.address"} type={"text"} />
        </FormControl>
    )
}

const CompanyNameInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl flexBasis={"100%"} isRequired>
            <FormLabel>
                Company Name
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.name"} type={"text"} />
        </FormControl>
    )
}

const CompanyStateSelector = ({ handleChange, value }: BaseInputProp) => {
    return (
        <StateDropSelect isRequired flexBasis={"100%"} label={"State"} placeholder={"Select state"} notFormik name={"company.state"} value={value} onChange={handleChange} />
    )
}

const CompanyZipCodeInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl flexBasis={"100%"} isRequired>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.zipcode"} type={"text"} maxLength={5} minLength={5} />
        </FormControl>
    )
}

export default CompanyInfoForm
