import {IGetReceipt, IReceipt} from "../../Types/Interface/IReceipt";
import {reqClient} from "../../Api/reqClient";
import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import {ReceiptSubmissionSchema} from "../../schemas/RecieptSchema";
import {format} from "date-fns";

const submitReceipt = async (data: IReceipt, guid?: string, logo?: string): Promise<IFormResponse> => {
    const url = `/receipts/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const body = {
        taxAmount: typeof data.taxAmount === "string" ? 0 : data.taxAmount,
        discountAmount: typeof data.discountAmount === "string" ? 0 : data.discountAmount,
        discountPercentage: typeof data.discountPercentage === "string" ? 0 : data.discountPercentage,
        invoiceNumber: data.invoiceNumber,
        note: data.note,
        dueDate: data.receiptDate,
        receiptDate: data.receiptDate,
        from: {
            ...data.from,
            logo: logo,
            phoneNumber: data.from.phoneNumber.toString(),
            isCompletedForm: true,
            address: {
                ...data.from.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        for: {
            ...data.for,
            isCompletedForm: true,
            phoneNumber: data.for.phoneNumber.toString(),
            address: {
                ...data.for.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem, index) => ({...lineItem, id: index, isCompletedForm: true})),
        quoteHtmlString: "",
        email: data.from.email
    }
    const parsedReceipt = ReceiptSubmissionSchema.parse(body)
    const response = await reqClient.post(url, parsedReceipt)
    return response.data
}

export const getReceipt = async (guid: string): Promise<IGetFormResponse<IReceipt>> => {
    const url = `/receipts/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGetReceipt> = response.data
    const transformedData: IReceipt = {
        dueDate: format(data.dueDate, "yyyy-MM-dd"),
        invoiceNumber: data.invoiceNumber,
        receiptDate: format(data.receiptDate, "yyyy-MM-dd"),
        receiptHtmlString: "",
        accept: false,
        agree: false,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        email: "",
        for: {
            address: {
                addressLine1: data.for.address.addressLine1,
                addressLine2: "",
                city: data.for.address.city,
                country: data.for.address.country,
                id: data.for.address.id,
                postalCode: data.for.address.postalCode,
                regionOrState: data.for.address.regionOrState
            },
            email: data.for.email,
            id: data.for.id,
            logo: data.for.logo,
            name: data.for.name,
            phoneNumber: data.for.phoneNumber,
            websiteUrl: data.for.websiteUrl
        },
        from: {
            address: {
                addressLine1: data.from.address.addressLine1,
                addressLine2: "",
                city: data.from.address.city,
                country: data.from.address.country,
                id: data.from.address.id,
                postalCode: data.from.address.postalCode,
                regionOrState: data.from.address.regionOrState
            },
            email: data.from.email,
            id: data.from.id,
            logo: data.from.logo,
            name: data.from.name,
            phoneNumber: data.from.phoneNumber,
            websiteUrl: data.from.websiteUrl
        },
        id: data.id,
        lineItems: data.lineItems.map((lineItem) => ({...lineItem, id: Date.now(), isCompletedForm: undefined})),
        logoUrl: "",
        note: data.note,
        taxAmount: data.taxAmount
    }
    return {isSuccess, data: transformedData, errorMessage}
}


export default submitReceipt