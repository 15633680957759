// export enum paymentModeEnums {
//     Hourly = 0,
//     Salary = 1
// }
//
// export enum paymentScheduleEnums {
//     Weekly = 'Weekly',
//     BiWeekly= 'Bi-Weekly',
//     SemiMonthly = 'Semi-Monthly',
//     Monthly = 'Monthly',
//     Quarterly = 'Quarterly',
//     SemiAnnually = 'Semi-Annually',
//     Annually = 'Annually'
// }
//
// export const payPeriodMultiplier  = {
//     [paymentScheduleEnums.Weekly]: 52,
//     [paymentScheduleEnums.BiWeekly]: 26,
//     [paymentScheduleEnums.SemiMonthly]: 24,
//     [paymentScheduleEnums.Monthly]: 12,
//     [paymentScheduleEnums.Quarterly]: 4,
//     [paymentScheduleEnums.SemiAnnually]: 2,
//     [paymentScheduleEnums.Annually]: 1
// };
//
// export const  totalHoursMultiplier = {
//     [paymentScheduleEnums.Weekly]: 40, // Example hours for weekly schedule
//     [paymentScheduleEnums.BiWeekly]: 80,
//     [paymentScheduleEnums.SemiMonthly]: 87, // Example for semi-monthly (calculated 52 weeks / 12 months / 2)
//     [paymentScheduleEnums.Monthly]: 173, // Example for monthly
//     [paymentScheduleEnums.Quarterly]: 173, // Example for quarterly
//     [paymentScheduleEnums.SemiAnnually]: 173, // Example for semi-annually
//     [paymentScheduleEnums.Annually]: 173 // Example for annually
// }
//
// export enum totalHoursMultiplierEnum {
//     weekly = 40,
//     biWeekly = 80,
//     semiMonthly = 87,
//     monthly = 173,
//     quarterly = 173,
//     semiAnnually = 173,
//     annually = 173
// }
import { getDaysInMonth, getDaysInYear, getISOWeek, getISOWeeksInYear, getMonth, getQuarter } from "date-fns";
import { getSemiMonthlyPeriod } from "../../Services/utils";

export enum PaymentMode {
    Hourly = "Hourly",
    Salary = "Salary",
}

export enum DeductionType {
    AdditionalDeduction = "AdditionalDeduction",
    StateTax = "StateTax",
    FederalTax = "FederalTax",
    Medicare = "Medicare",
    SocialSecurity = "SocialSecurity"
}

export enum PaySchedule {
    Weekly = "Weekly",
    BiWeekly = "Bi-Weekly",
    SemiMonthly = "Semi-Monthly",
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    SemiAnnually = "Semi-Annually",
    Annually = "Annually",
}
export enum MaritalStatus {
    Married = "Married",
    Single = "Single",
}

interface IPaymentScheduleInfo {
    schedule: number
    periodRange: number
    hours: number
    payPeriods: () => number
    getCurrentPayPeriod: (payDate?: string | Date, startDate?: Date) => number
}

export const convertNumToPaySchedule = (value: number) => {
    switch (value) {
        case 1:
            return PaySchedule.Weekly
        case 2:
            return PaySchedule.BiWeekly
        case 3:
            return PaySchedule.SemiMonthly
        case 4:
            return PaySchedule.Monthly
        case 5:
            return PaySchedule.Quarterly
        case 6:
            return PaySchedule.SemiAnnually
        case 7:
            return PaySchedule.Annually
        default:
            throw new Error(`Unknown value for ${value}`)
    }
}

export const paymentScheduleInfo: { [key in PaySchedule]: IPaymentScheduleInfo } = {
    [PaySchedule.Weekly]: {
        schedule: 1,
        periodRange: 7,
        hours: 40,
        payPeriods: () => getISOWeeksInYear(new Date()),
        getCurrentPayPeriod: (payDate, startDate) => {

            const isoWeek = payDate ? getISOWeek(payDate) : getISOWeek(new Date())

            if (!startDate) {
                return isoWeek
            }

            const startWeek = getISOWeek(startDate)

            return isoWeek - startWeek
        },
    },
    [PaySchedule.BiWeekly]: {
        schedule: 2,
        periodRange: 14,
        hours: 80,
        payPeriods: () => 26,
        getCurrentPayPeriod: (payDate, startDate) => {
            const isoWeek = payDate ? Math.ceil(getISOWeek(payDate) / 2) : Math.ceil(getISOWeek(new Date()) / 2)

            if (!startDate)
                return isoWeek

            const startWeek = Math.ceil(getISOWeek(startDate) / 2)

            return isoWeek - startWeek
        }
    },
    [PaySchedule.SemiMonthly]: {
        schedule: 3,
        periodRange: 15,
        hours: 87,
        payPeriods: () => 24,
        getCurrentPayPeriod: (payDate, startDate) => {
            const currentPeriod = payDate ? getSemiMonthlyPeriod(payDate) : getSemiMonthlyPeriod(new Date())

            if (!startDate)
                return currentPeriod

            const startPeriod = getSemiMonthlyPeriod(startDate)
            return currentPeriod === startPeriod ? 1 : currentPeriod - startPeriod
        }
    },
    [PaySchedule.Monthly]: {
        schedule: 4,
        periodRange: getDaysInMonth(new Date()),
        hours: 176,
        payPeriods: () => 12,
        getCurrentPayPeriod: (payDate, startDate) => {

            if (!startDate) {
                return (payDate ? getMonth(payDate) : getMonth(new Date())) + 1
            }

            const startMonth = getMonth(startDate)

            if (payDate) {
                const payMonth = getMonth(payDate)

                return payMonth - startMonth
            }

            return getMonth(new Date()) + 1
        }
    },
    [PaySchedule.Quarterly]: {
        schedule: 5,
        periodRange: 90,
        hours: 528,
        payPeriods: () => 4,
        getCurrentPayPeriod: (payDate, startDate) => {
            if (payDate) {
                if (!startDate) {
                    return payDate ? getQuarter(payDate) : getQuarter(new Date())
                }

                const employmentQuater = getQuarter(startDate)
                const currentQuater = getQuarter(payDate)

                return currentQuater - employmentQuater + 1
            }

            return getQuarter(new Date())
        }
    },
    [PaySchedule.SemiAnnually]: {
        schedule: 6,
        periodRange: 182,
        hours: 2080,
        payPeriods: () => 2,
        getCurrentPayPeriod: (payDate, startDate) => {
            if (payDate) {
                const currentPayPeriod = Math.ceil(getQuarter(payDate) / 2)
                if (!startDate) {
                    return currentPayPeriod
                }

                const startPeriod = Math.ceil(getQuarter(startDate) / 2)
                return currentPayPeriod == startPeriod ? 1 : 2
            }

            return Math.ceil(getQuarter(new Date()) / 2)
        }
    },
    [PaySchedule.Annually]: {
        schedule: 7,
        periodRange: getDaysInYear(new Date()),
        hours: 2080,
        payPeriods: () => 1,
        getCurrentPayPeriod: () => 1
    }
}
