import { ScaleContext } from "Context/ScaleContextProvider";
import React, {ChangeEvent, useEffect, useRef, useState, memo, useContext, useLayoutEffect, useMemo} from "react";
import {NumericFormat} from "react-number-format";
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import {useDebouncedCallback} from "use-debounce";
import {ScaleMode} from "../../Types/Enums/ScaleMode";
import styles from "../../Styles/PdfViewer.module.css"
import WaterMark from "../Templates/WaterMark";
import {Box, Spinner} from "@chakra-ui/react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type Scale = {
    label: string
    mode: ScaleMode
    scale?: number
}

const Scales: Scale[] = [
    {
        label: "Actual Size",
        mode: ScaleMode.ActualSize
    },
    {
        label: "Page Fit",
        mode: ScaleMode.PageFit
    },
    {
        label: "Page Width",
        mode: ScaleMode.PageWidth,
        scale: 1
    },
    {
        label: "50%",
        mode: ScaleMode.Scale50,
        scale: 0.5
    },
    {
        label: "75%",
        mode: ScaleMode.Scale75,
        scale: 0.75
    },
    {
        label: "100%",
        mode: ScaleMode.Scale100,
        scale: 1
    },
    {
        label: "125%",
        mode: ScaleMode.Scale125,
        scale: 1.25
    },
    {
        label: "150%",
        mode: ScaleMode.Scale150,
        scale: 1.5
    },
    {
        label: "200%",
        mode: ScaleMode.Scale200,
        scale: 2
    },
    {
        label: "300%",
        mode: ScaleMode.Scale300,
        scale: 3
    },
    {
        label: "400%",
        mode: ScaleMode.Scale400,
        scale: 4
    },
    {
        label: "",
        mode: ScaleMode.ScaleVar,
    },
]


export default function PdfViewerV2({file, refresh, loading}: {file?: File, refresh?: () => void, loading: boolean}) {
    const [scaleMode, setScaleMode] = useState(ScaleMode.PageWidth)
    const [currentPage, setCurrentPage] = useState("1")
    const [parsedCurrentPage, setParsedCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState<number | undefined>()
    const [scaleData, setScaleData] = useState(1)
    const scrollable = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let newX = 0
        let newY = 0
        let startX = 0
        let startY = 0
        const draggableTarget = scrollable.current

        const handleMouseDown = (e: MouseEvent) => {
            startX = e.clientX
            startY = e.clientY

            if (document){
                document.addEventListener("mousemove", handleMouseMove)
                document.addEventListener("mouseup", handleMouseUp)
            }
        }
        const handleMouseMove = (e: MouseEvent) => {
            newX = startX - e.clientX
            newY = startY - e.clientY
            startX = e.clientX
            startY = e.clientY
            draggableTarget!.scrollTop += (newY / 1.5)
            draggableTarget!.scrollLeft += (newX / 1.5)
        }
        const handleMouseUp = () => {
            draggableTarget!.className.replace("cursor-grabbing", "cursor-grab")
            document.removeEventListener("mousemove", handleMouseMove)
        }

        draggableTarget?.addEventListener("mousedown", handleMouseDown)


        return () => {
            draggableTarget?.removeEventListener("mousedown", handleMouseDown)
            document.removeEventListener("mousemove", handleMouseMove)
            document.removeEventListener("mouseup", handleMouseUp)
        }
    }, []);

    const onScaleModeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newScaleMode = parseInt(e.target.value)
        setScaleMode(newScaleMode)
        if (newScaleMode === ScaleMode.PageFit || newScaleMode === ScaleMode.PageWidth || newScaleMode === ScaleMode.ScaleVar || newScaleMode === ScaleMode.ActualSize) {
            return;
        } else {
            let newScale: number | undefined
            Scales.forEach((scaleVar) => {
                if ( scaleVar.mode === newScaleMode){
                    newScale = scaleVar.scale
                }
            })
            if (newScale !== undefined) {
                setScaleData(newScale)
            }
        }

    }

    const increaseScale = () => {
        setScaleMode(ScaleMode.ScaleVar)
        if (scaleData < 4){
            const newScale = (scaleData + 0.1).toFixed(1)
            setScaleData(parseFloat(newScale))
        }
    }

    const decreaseScale = () => {
        setScaleMode(ScaleMode.ScaleVar)
        if (scaleData > 0.2){
            const newScale = (scaleData - 0.1).toFixed(1)
            setScaleData(parseFloat(newScale))
        }
    }


    return (
        <ScaleContext.Provider value={scaleData}>
            {
                file === undefined ? (
                    <NoDataComponent loading={loading} refresh={refresh}/>
                ) : (
                    <div className={styles.pdfViewerContainer}>
                        <div
                            className={styles.pdfViewerToolBar}>
                            <div className={styles.toolBarButtonRow1}>
                                <button
                                    type={"button"}
                                    onClick={() => {
                                        if (parsedCurrentPage > 1 && parsedCurrentPage !== undefined) {
                                            const newPage = parsedCurrentPage - 1
                                            setParsedCurrentPage(newPage)
                                            setCurrentPage(`${newPage}`)
                                        }
                                    }}
                                    className={styles.toolBarButton}>
                                    <div className={styles.svgIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5}
                                             stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"/>
                                        </svg>
                                    </div>
                                </button>
                                <div className={styles.divider}></div>
                                <button
                                    type={"button"}
                                    onClick={() => {
                                        if (pageCount !== undefined && pageCount > parsedCurrentPage) {
                                            const newPage = parsedCurrentPage + 1
                                            setParsedCurrentPage(newPage)
                                            setCurrentPage(`${newPage}`)
                                        }
                                    }}
                                    className={styles.toolBarButton}>

                                    <div className={styles.svgIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5}
                                             stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"/>
                                        </svg>
                                    </div>
                                </button>
                                <div className={styles.invisibleDivider}></div>
                               <div style={{display: "flex", gap: "8px", height: "100%", alignItems: "center"}}>
                                   <NumericFormat
                                       className={styles.pdfPageNumInput}
                                       value={currentPage} onChange={(e) => {
                                       const pageNum = parseInt(e.target.value)
                                       if (!isNaN(pageNum) && pageNum !== 0 && pageCount !== undefined && pageNum <= pageCount) {
                                           setParsedCurrentPage(pageNum)
                                       }
                                       setCurrentPage(e.target.value)
                                   }}/>
                                   <div>
                                       of
                                   </div>
                                   <NumericFormat
                                       className={styles.pdfPageNumInputDisabled}
                                       value={pageCount}
                                       disabled
                                   />
                               </div>
                            </div>

                            <div className={styles.toolBarButtonRow2}>
                                {/* Decrease button */}
                                <button onClick={decreaseScale}
                                        type={"button"}
                                        className={styles.toolBarButton2}>
                                    <div className={styles.svgIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                             stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14"/>
                                        </svg>
                                    </div>
                                </button>
                                <div className={styles.divider2}></div>
                                {/* Increase scale button */}
                                <button onClick={increaseScale}
                                        type={"button"}
                                        className={styles.toolBarButton2}>
                                    <div className={styles.svgIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                             stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                                        </svg>
                                    </div>
                                </button>
                                <select
                                    value={scaleMode}
                                    className={styles.pdfViewerScaleSelector}
                                    onChange={onScaleModeChange}>
                                    {
                                        Scales.filter((scaleObject) => scaleMode !== ScaleMode.ScaleVar ? scaleObject.mode !== ScaleMode.ScaleVar : true).map((scaleObject) => {
                                            if (scaleObject.mode !== ScaleMode.ScaleVar) {
                                                return (<option key={scaleObject.mode}
                                                                value={scaleObject.mode}>{scaleObject.label}</option>)
                                            } else {
                                                return (<option key={scaleObject.mode}
                                                                value={scaleObject.mode}>{Math.floor(scaleData * 100)}%</option>)
                                            }
                                        })
                                    }
                                </select>
                            </div>
                            {/*Refresh button*/}
                            <button
                                type={"button"}
                                onClick={refresh}
                                className={refresh !== undefined ? styles.toolBarButton2 : styles.toolBarButton2Invisible}>
                                <div className={styles.svgIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                         stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div className={styles.pdfViewerScrollable} ref={scrollable}>
                            <PdfDocument
                                file={file}
                                pageNum={parsedCurrentPage}
                                scaleMode={scaleMode}
                                setPageCount={(num) => setPageCount(num)}
                            />
                        </div>
                    </div>
                )
            }
        </ScaleContext.Provider>
    );
}


const PdfDocument = memo(function DocumentLoader({ pageNum, scaleMode, setPageCount, file}: {pageNum: number, scaleMode: ScaleMode, setPageCount: (num: number) => void, file?: File}) {
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0)
    const pageScale = useContext(ScaleContext)
    const [scale, setScale] = useState(pageScale ?? 1)
    const ratio = useRef<number | undefined>(undefined)
    const [pdfWidth, setPdfWidth] = useState<undefined | number>()
    const getContainerWidth = (): number | undefined => {
        if (typeof window !== "undefined"){
            const position = container.current?.getBoundingClientRect()
            return position?.width
        }
        return undefined
    }

    const debouncedResizeHandler = useDebouncedCallback(
        () => {
            let newWidth = getContainerWidth()
            if (newWidth !== undefined ){
                newWidth = (newWidth - 50) * scale
                setWidth(newWidth)
            }
        }, 1000
    )


    useLayoutEffect(()=> {
        const handleScreenSize = (): void => {
            debouncedResizeHandler()
        }
        if (window){
            handleScreenSize()
            window.addEventListener("resize", handleScreenSize)
        }

        return () => {
            window.removeEventListener("resize", handleScreenSize)
        }
    }, [debouncedResizeHandler])

    useEffect(() => {
        if (pageScale !== undefined) {
            let newWidth = getContainerWidth()
            if (newWidth !== undefined ){
                newWidth = (newWidth - 50) * pageScale
                setWidth(newWidth)
            }
            setScale(pageScale)
        }
    }, [pageScale])

    const pageWidth = useMemo(() => {
        if (scaleMode === ScaleMode.PageFit){
            if (ratio.current != undefined){
                return 400 * ratio.current
            }
        } else if ( scaleMode === ScaleMode.PageWidth) {
            const newWidth = getContainerWidth()
            if (newWidth !== undefined){
                return newWidth - 50
            }
        } else if (scaleMode === ScaleMode.ActualSize){
            return undefined
        } else {
            return width
        }
    }, [ scaleMode, width])

const watermarkFontSize = useMemo(() => {
        if (scaleMode === ScaleMode.PageFit){
            if (ratio.current != undefined){
                return (96 * ratio.current)
            }
        } else if ( scaleMode === ScaleMode.PageWidth) {
            return 96
        } else if (scaleMode === ScaleMode.ActualSize){
            if (pdfWidth !== undefined && ratio.current != undefined){
                return ((pdfWidth * ratio.current) / 400) * 96
            }
        }
    return 96 * scale
    }, [ scaleMode, pdfWidth, ratio.current, scale])


    const onRenderSuccess = (pdf: {width: number, height: number})=> {
        setPdfWidth(pdf.width)
        ratio.current = pdf.width / pdf.height
    }

    const onLoadSuccess = ({numPages}: {numPages: number}) => {
        setPageCount(numPages)
    }


    return (
        <>
            <div className={styles.pdfDocumentContainer} ref={container}>
                <div style={{ padding: "12px", position: "relative" }}>
                    <Box position={"absolute"} style={{width: pdfWidth !== undefined && getContainerWidth() !== undefined && pdfWidth > getContainerWidth()! ? `${pdfWidth}px` : "calc(100% - 24px)"}} zIndex={"docked"} overflow={"clip"}>
                        <Box style={{width: pageWidth ?? pdfWidth, marginInline: "auto" }} h={pdfWidth === undefined || ratio.current === undefined ? "400px" : `${(pageWidth ?? pdfWidth)/ratio.current}px`} overflow={"clip"} >
                            <WaterMark font={`${watermarkFontSize}px`} />
                        </Box>
                    </Box>
                    <div style={{ width: pageWidth ?? pdfWidth, marginInline: "auto"}}>
                        <Document file={file} onLoadSuccess={onLoadSuccess}>
                            <Page
                                width={pageWidth}
                                renderMode="canvas"
                                onRenderSuccess={onRenderSuccess}
                                renderAnnotationLayer={false}
                                pageNumber={pageNum}
                                renderTextLayer={true}></Page>
                        </Document>
                    </div>
                </div>
            </div>
        </>
    )
})


const NoDataComponent = ({refresh, loading}: {refresh?: () => void, loading: boolean}) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

  return (
      <div className={styles.noDataContainer}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              {
                  isLoading ? (
                      <>
                          <Spinner/>
                          <p style={{fontSize: "20px"}}>Pdf Preview Loading</p>
                      </>
                      ) : (
                      <>
                          <p style={{fontSize: "20px"}}>A problem occurred while loading preview.</p>
                          <button onClick={refresh} type={"button"} style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "8px",
                              borderWidth: "1px",
                              background: "transparent",
                              marginTop: "12px",
                              paddingInline: "16px",
                              height: "42px"
                          }}>
                              <div className={styles.svgIcon} style={{marginRight: "8px"}}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                       stroke="currentColor" className="size-6">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                  </svg>
                              </div>
                              <span style={{fontSize: "16px"}}>
                      Refresh
                  </span>
                          </button>
                      </>
                  )
              }
          </div>
      </div>
  )
}