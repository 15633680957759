import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import I1099Misc, {IGet1099Misc} from "../../Types/Interface/I1099Misc";
import {transformMiscData} from "../../Hooks/useFill1099Misc";
import {reqClient} from "../../Api/reqClient";
import {F1099MiscSubmissionSchema} from "../../schemas/F1099MiscSchema";

const submit1099Misc = async (data: I1099Misc, guid?: string): Promise<IFormResponse> => {
    const url = `/_1099Misc/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformMiscData(data)
    const body = {
        "_1099MISCDto": {
            ...transformedData._1099MISCDto,
            payer: {...transformedData._1099MISCDto.payer, isCompletedForm: true},
            recipient: {
                ...transformedData._1099MISCDto.recipient, isCompletedForm: true
            },
        }
    }
    const res  = F1099MiscSubmissionSchema.parse(body)
    const response = await reqClient.post(url, res)
    return response.data
}

export const get1099Misc = async (guid: string): Promise<IGetFormResponse<I1099Misc>> => {
    const url = `/_1099Misc/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGet1099Misc> = response.data
    const transformedData: I1099Misc = {
        downloaded: false,
        info: {
            accept: false,
            agree: false,
            email: data.email,
            formCount: data.formCount,
            year: data.year.toString()
        },
        payer: {
            address: {
                city: data.payer.city,
                country: "",
                state: data.payer.state,
                stateAddress: data.payer.address,
                zipcode: data.payer.zipCode
            },
            email: "",
            name: data.payer.name,
            phoneNumber: "",
            sid: "",
            tin: data.payer.tin
        },
        recipients: [
            {
                address: {
                    city: data.recipient.city,
                    country: "",
                    state: data.recipient.state,
                    stateAddress: data.recipient.address,
                    zipcode: data.recipient.zipCode
                },
                email: "",
                id: 0,
                name: data.recipient.name,
                options: {
                    FATCAFiling: data.options.fatcaFiling === "On",
                    accountNumber: data.options.accountNumber,
                    cropInsurance: data.options.cropInsurance,
                    directSalesHigh: data.options.directSalesHigh === 1,
                    fishPurchased: data.options.fishPurchased,
                    fishingBoatProceeds: data.options.fishingBoatProceeds,
                    goldenParachute: data.options.excessGoldenParachute,
                    grossProceeds: data.options.grossProceeds,
                    otherIncome: data.options.otherIncome,
                    medicalHealthPayments: data.options.medicalHealthcarePayments,
                    nonQualifiedCompensation: data.options.nonqualifiedDefferedCompensation,
                    rents: data.options.rents,
                    royalties: data.options.royalties,
                    section409: data.options.section409AReferals,
                    stateIncome: data.options.stateIncome,
                    stateTax: data.options.stateTaxWithheld,
                    subPayments: data.options.subPayments,
                    federalTaxIncomeWithheld: data.options.federalTaxIncomeWithheld,
                    statePayerNo: data.payer.statePayerStateNumber
                },
                tin: data.recipient.tin
            }
        ]
    }
    return {isSuccess, data: transformedData, errorMessage}
}

export default submit1099Misc