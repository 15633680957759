import { MaritalStatus, PaymentMode, paymentScheduleInfo, PaySchedule } from "../Types/Enums/PaymentModeEnum";
import { IEarning, IPayStub } from "../Types/Interface/IPaystub";
import { getDate, getMonth, } from "date-fns";
import { stateIncomeTaxPercentage } from "./taxes";

type Env = 'production' | 'development' | 'localhost';
type currency = 'USD' | 'GBP';
// type style = 'currency';

export const getAppEnv: () => Env = () => {
    return process.env.REACT_APP_ENVIRONMENT as Env || "develop"
}

export const getStripeKey = () => {
    return getAppEnv() === "production" ? process.env.REACT_APP_STRIPE_PROMISE_PROD : process.env.REACT_APP_STRIPE_PROMISE_DEV
}
export const getProductionHost = () => {
    return getAppEnv() === "production" ? process.env.REACT_APP_PROD : process.env.REACT_APP_DEV
}

export const getGAKey = () => {
    return getAppEnv() === "production" ? process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_PROD : process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_DEV
}

export const currencyFormatter = (currency: currency, value: number | string) => {
    const newVal = typeof value === 'string' ? Number(value) : value;
    return Intl.NumberFormat('default', { style: 'currency', currency }).format(newVal);
}



export const formatAddress = (address: string) => address.length > 0 ? `${address.trim()},` : address

export const currentYearFirstDay = () => {
    const year = new Date().getFullYear()
    const janFirst = new Date(year, 0, 1)

    return janFirst.toISOString().split("T")[0]
}

export const currentYear = () => {
    return new Date().getFullYear()
}

export const addDays = (payEndDateString: string) => {
    const payEndDate = new Date(payEndDateString)
    const newDate = new Date(payEndDate)

    payEndDate.setDate(payEndDate.getDate() + 1)

    return newDate.toISOString().split("T")[0]
}

interface ITaxRate {
    lowerLimit: number;
    upperLimit?: number;
    taxPercent: number;
}

const singleTaxRates: ITaxRate[] = [
    {
        lowerLimit: 0,
        upperLimit: 11600,
        taxPercent: 10 / 100
    },
    {
        lowerLimit: 11601,
        upperLimit: 47150,
        taxPercent: 12 / 100
    },
    {
        lowerLimit: 47151,
        upperLimit: 100525,
        taxPercent: 22 / 100
    },
    {
        lowerLimit: 100526,
        upperLimit: 191950,
        taxPercent: 24 / 100
    },
    {
        lowerLimit: 191951,
        upperLimit: 243725,
        taxPercent: 32 / 100
    },
    {
        lowerLimit: 243726,
        upperLimit: 609350,
        taxPercent: 35 / 100
    },
    {
        lowerLimit: 609351,
        taxPercent: 37 / 100
    },
]
const marriedTaxRates: ITaxRate[] = [
    {
        lowerLimit: 0,
        upperLimit: 23200,
        taxPercent: 10 / 100
    },
    {
        lowerLimit: 23201,
        upperLimit: 94300,
        taxPercent: 12 / 100
    },
    {
        lowerLimit: 94301,
        upperLimit: 201050,
        taxPercent: 22 / 100
    },
    {
        lowerLimit: 201051,
        upperLimit: 383900,
        taxPercent: 24 / 100
    },
    {
        lowerLimit: 383901,
        upperLimit: 487450,
        taxPercent: 32 / 100
    },
    {
        lowerLimit: 487451,
        upperLimit: 731200,
        taxPercent: 35 / 100
    },
    {
        lowerLimit: 731201,
        taxPercent: 37 / 100
    },
]

const getPercentageFromList = (income: number, taxRates: ITaxRate[]) => {
    let taxPercentage: number = 0
    taxRates.forEach((rate) => {
        if (rate.upperLimit === undefined) {
            if (income >= rate.lowerLimit) {
                taxPercentage = rate.taxPercent
            }
        } else {
            if (income >= rate.lowerLimit && income <= rate.upperLimit) {
                taxPercentage = rate.taxPercent
            }
        }
    })
    return taxPercentage
}

export const getTaxPercentageByStatus = (income: number, status: MaritalStatus) => {
    if (status === "Single") {
        return getPercentageFromList(income, singleTaxRates)
    } else {
        return getPercentageFromList(income, marriedTaxRates)
    }
}

export const getSemiMonthlyPeriod = (currentDate: string | Date) => {
    const dayOfMonth = getDate(currentDate)
    const currentMonth = getMonth(currentDate) + 1
    if (dayOfMonth <= 15) {
        return (currentMonth * 2) - 1
    } else {
        return currentMonth * 2
    }
}

export const calculateYTD = (x: number | undefined, payPeriod: number) => x != undefined ? x * payPeriod : 0
export const calculateSocialSecurity = (income: number) => 6.2 / 100 * income
export const calculateMedicareTax = (income: number) => 1.45 / 100 * income
export const calculateStatetax = (stateCode: string, isMarried: boolean, income: number) => stateIncomeTaxPercentage(stateCode, isMarried, income) * income / 100

export const calculateEarningYtd = (index: number, payStubs: IPayStub[], paySchedule: PaySchedule, to: string, paymentMode: PaymentMode, salary: number, hourlyRate?: number, startDate?: Date) => {
    if (paymentMode === PaymentMode.Hourly) {
        const hourlyTotal = (hourlyRate === undefined ? 20 : hourlyRate) * paymentScheduleInfo[paySchedule].hours
        const firstPayStubPeriod = paymentScheduleInfo[paySchedule].getCurrentPayPeriod(to, startDate)
        const initialYtd = calculateYTD(hourlyTotal, firstPayStubPeriod - 1)
        const ytd = payStubs.reduce((acc, current, currentIndex) => {

            if (currentIndex >= index) {
                return acc + current.earnings[0].total
            } else {
                return 0
            }

        }, 0)
        return ytd + initialYtd
    } else {
        return calculateYTD(salary, paymentScheduleInfo[paySchedule].getCurrentPayPeriod(to, startDate))
    }
}

export const mapEarningsTotal = (earnings: IEarning[]) => {
    return [...earnings.map((earning, index) => (index !== 0 ? {
        ...earning,
        total: (earning.hours && earning.rate) ? earning.hours * earning.rate : 0
    } : earning))]
}

export const toDecimalPlaces = (value: number, decimalPlaces: number) => {
    return +(value.toFixed(decimalPlaces))
}

export const generateQuoteId = () => {
    const now = new Date()
    const array = new Uint32Array(1);
    const randId = crypto.getRandomValues(array)[0];
    const month = now.getMonth() + 1
    return `QUO-${randId.toString().slice(0, 5)}-${now.getFullYear()}${month.toString().length === 1 ? `0${month}` : month}${now.getDate()}`
}