import {Box, Button, Flex, Text} from "@chakra-ui/react";
import React from "react";

type PreviewProps = {
    goBack: () => void
}

const PreviewHeader = ({goBack}: PreviewProps ) => {
  return (
      <Flex gap={"8px"}>
          <Button onClick={goBack}>
              <Box w={"24px"} h={"24px"}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                       stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                  </svg>
              </Box>
          </Button>
          <Text color={"primary"} fontSize={"24px"} fontWeight={"semibold"}>
              Preview And Checkout
          </Text>
      </Flex>
  )
}

export default PreviewHeader