import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import I1099Misc, { I1099MiscBasic, IPayer, IRecipient, IRecipientOptions } from "../../Types/Interface/I1099Misc";
import { IAddress } from "../../Types";
import { RootState } from "../index";

const initial1099Info: I1099MiscBasic = {
    email: "", formCount: 1, year: new Date().getFullYear().toString(), accept: false, agree: false
}

const initialAddress: IAddress = {
    city: "", country: "", state: "AK", stateAddress: "", zipcode: ""
}

const initialPayer: IPayer = {
    address: initialAddress, email: null, name: "", phoneNumber: "", sid: "", tin: ""
}

const initialRecipientOption: IRecipientOptions = {
    FATCAFiling: null,
    accountNumber: null,
    cropInsurance: null,
    directSalesHigh: null,
    fishPurchased: null,
    fishingBoatProceeds: null,
    goldenParachute: null,
    grossProceeds: null,
    otherIncome: null,
    medicalHealthPayments: null,
    nonQualifiedCompensation: null,
    rents: null,
    royalties: null,
    section409: null,
    stateIncome: null,
    stateTax: null,
    subPayments: null,
    federalTaxIncomeWithheld: null,
    statePayerNo: null
}

const initialRecipient: IRecipient = {
    id: 0,
    address: initialAddress, email: null, name: "", options: initialRecipientOption, tin: ""
}

const initial1099State: I1099Misc = {
    info: initial1099Info, payer: initialPayer, recipients: [
        initialRecipient
    ],
    downloaded: false,
}

type F1099Payload<T> = {
    field: keyof T
    value: T[keyof T]
}

type PayerPayload = F1099Payload<Omit<IPayer, "address">>
type F1099AddressPayload = F1099Payload<IAddress>

type Array1099Payload<T> = F1099Payload<T> & { index: number }

type RecipientPayload = Array1099Payload<Omit<IRecipient, "options" | "id" | "address">>

type RecipientOptionPayload = Array1099Payload<IRecipientOptions>


const f1099MiscSlice = createSlice({
    name: "f1099MiscSlice",
    initialState: initial1099State,
    reducers: {
        set1099InfoValues: (state, action: PayloadAction<F1099Payload<I1099MiscBasic>>) => {
            const { field, value } = action.payload
            const info = state.info
            Object.assign(info, { [field]: value })
            if (field === "formCount") {
                if (state.recipients.length > parseInt(value.toString())) {
                    // recipients form count decrease
                    state.recipients = state.recipients.slice(0, parseInt(value.toString()))
                } else {
                    const array = Array.from(Array(parseInt(value.toString()))).map((i, index) => ({ ...initialRecipient, id: index }))
                    state.recipients = state.recipients.concat(array.slice(state.recipients.length))
                }
            }
        },
        set1099MiscPayerValues: (state, action: PayloadAction<PayerPayload>) => {
            const { field, value } = action.payload
            const payer = state.payer
            Object.assign(payer, { [field]: value })
        },
        set1099MiscPayerAddress: (state, action: PayloadAction<F1099AddressPayload>) => {
            const { field, value } = action.payload
            const address = state.payer.address
            Object.assign(address, { [field]: value })
        },
        set1099MiscRecipientValues: (state, action: PayloadAction<RecipientPayload>) => {
            const { field, value, index } = action.payload
            const recipient = state.recipients[index]
            Object.assign(recipient, { [field]: value })
        },
        set1099MiscRecipientAddress: (state, action: PayloadAction<Array1099Payload<IAddress>>) => {
            const { field, value, index } = action.payload
            const address = state.recipients[index].address
            Object.assign(address, { [field]: value })
        },
        set1099MiscRecipientOptions: (state, action: PayloadAction<RecipientOptionPayload>) => {
            const { field, value, index } = action.payload
            const options = state.recipients[index].options
            Object.assign(options, { [field]: value })
        },
        reset1099Misc: () => {
            return initial1099State
        },
        setMiscDownloaded: (state) => {
            state.downloaded = true
        },
        load1099Misc: (_, action: PayloadAction<I1099Misc>) => {
            return action.payload
        }
    }
})


export const select1099State = (state: RootState) => state.f1099Misc
export const select1099Basic = (state: RootState) => state.f1099Misc.info
export const select1099Payer = (state: RootState) => state.f1099Misc.payer
export const select1099PayerAddress = (state: RootState) => state.f1099Misc.payer.address
export const select1099Recipient = (state: RootState, index: number) => state.f1099Misc.recipients[index]
export const select1099RecipientAddress = (state: RootState, index: number) => state.f1099Misc.recipients[index].address
export const select1099RecipientOptions = (state: RootState, index: number) => state.f1099Misc.recipients[index].options
export const select1099RecipientLength = (state: RootState) => state.f1099Misc.recipients.length;


export const {
    set1099InfoValues,
    set1099MiscPayerValues,
    set1099MiscRecipientValues,
    set1099MiscRecipientAddress,
    set1099MiscRecipientOptions,
    set1099MiscPayerAddress,
    reset1099Misc,
    setMiscDownloaded,
    load1099Misc
} = f1099MiscSlice.actions


export default f1099MiscSlice.reducer