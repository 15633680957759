import { useFormik } from "formik";
import { I1099Nec } from "../validator";
import {Box, Flex} from "@chakra-ui/react";
import FormsLayout from "../../../Layouts/FormsLayout";
import FormHeading from "../../Components/FormHeading";
import NecBasicInfo from "./NecBasicInfo";
import NecPayerForm from "./NecPayerForm";
import NecRecipientsForm from "./NecRecipientsForm";
import NecPreview from "./NecPreview";
import React, {useEffect} from "react";
import {setDocumentCount, setDocumentType, setFormType} from "../../../Store/Slices";
import { documentTypeEnums, FormType } from "../../../Types/Enums/documentTypeEnums";
import { AppRoutes } from "../../../Routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../Hooks/useToast";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import useFormSubmit from "../../../Hooks/useFormSubmit";
import submit1099Nec from "../../../Services/api/F1099Nec";
import PreviewHeader from "../../../Components/PreviewHeader";
import NextButtonPreviewBar from "../../../Components/NextButtonPreviewBar";
import useMobilePreview from "../../../Hooks/useMobilePreview";

const NecForm = ({ data }: { data: I1099Nec }) => {
    const navigate = useNavigate();
    const { alert } = useToast()
    const dispatch = useAppDispatch()
    const {submit, isSuccess, isLoading} = useFormSubmit(submit1099Nec, FormType.F1099Nec)
    const {isNextPage, setIsNextPage} = useMobilePreview()


    const { handleSubmit, handleChange, values, setFieldValue } = useFormik<I1099Nec>({
        initialValues: data,
        validate: (values) => {
            const errors: {[k: string]: string} = {}
            if (!values.payer.address.state){
                errors.payerState = "The payer state isn't filled"
                alert("error", "The payer state isn't filled")
            }
            for (const recipient of values.recipients!){
                if (!recipient.address.state){
                    alert("error", "A Recipient state isn't filled")
                    errors.state = "A Recipient state isn't filled"
                }
            }
            return errors
        },
        validateOnChange: false,
        onSubmit: (values, formikHelpers) => {
            formikHelpers.setSubmitting(true)
            formikHelpers.validateForm().then(r => {
                if (r.recipients) {
                    alert("error", r.recipients)
                }
                if (r.payer?.address?.state) {
                    alert("error", r.payer?.address?.state)
                }
            })
            if (!values.info.agree || !values.info.accept) {
                alert("error", "Accept our TOS and agree to not use it for illegal activities")
            } else {
                if (!isLoading){
                    submit(values)
                }
            }
            formikHelpers.setSubmitting(false)
        }

    })

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.F1099))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.F1099Nec))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess]);


    return (
        <form onSubmit={handleSubmit}>
            <Flex w={"full"} display={{base: "none", lg: "flex" }} direction={{ base: "column", md: "row" }} px={"50px"} py={"50px"}
                gap={"32px"} pos={"relative"}>
                <FormsLayout>
                    <FormHeading title={"Create 1099 NEC"} description={"Fill in your information below"} />
                    <NecBasicInfo handleChange={handleChange} info={values.info} />
                    <NecPayerForm handleChange={handleChange} payer={values.payer} />
                    <NecRecipientsForm setValues={setFieldValue} handleChange={handleChange}
                        recipients={values.recipients === undefined ? [] : values.recipients} />
                </FormsLayout>
                <NecPreview setValues={setFieldValue} values={values} submit={handleSubmit}/>
            </Flex>
            <Box display={{lg: "none" }} px={"16px"} position={"relative"}>
                {
                    isNextPage ? (
                        <>
                            <PreviewHeader goBack={()=> setIsNextPage(false)}/>
                            <NecPreview isMobile={true} setValues={setFieldValue} values={values} submit={handleSubmit}/>
                        </>
                    ) : (
                        <>
                            <FormsLayout>
                                <FormHeading title={"Create 1099 NEC"} description={"Fill in your information below"} />
                                <NecBasicInfo handleChange={handleChange} info={values.info} />
                                <NecPayerForm handleChange={handleChange} payer={values.payer} />
                                <NecRecipientsForm setValues={setFieldValue} handleChange={handleChange}
                                                   recipients={values.recipients === undefined ? [] : values.recipients} />
                            </FormsLayout>
                            <NextButtonPreviewBar formType={FormType.F1099Nec} setNextPage={() => setIsNextPage(true)}/>
                        </>
                    )
                }
            </Box>
        </form>
    )
}

export default NecForm