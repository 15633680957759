import {Flex, Spinner} from "@chakra-ui/react";
import React from "react";

const LoadingScreen = () => {
    return (
        <Flex zIndex={"modal"} background={"white"} insetX={0} height={"100vh"} justifyContent={"center"} alignItems={"center"}>
            <Spinner/>
        </Flex>
    )
}

export default LoadingScreen