import BusinessFormsHeading from "./BusinessFormsHeading";
import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import FormikTextArea from "../Formik/FormikTextArea";
import React from "react"

type BusinessInvoiceInfoProps = {
    formHeading: string
    currentStep: number
    steps: number
}

const BusinessInvoiceInfo = ({ formHeading, currentStep, steps }: BusinessInvoiceInfoProps) => {
    return (
        <Box mb={"64px"} w={'full'}>
            <BusinessFormsHeading title={formHeading} steps={steps} currentStep={currentStep} />
            <Wrap gap={"16px"}>
                <WrapItem w={{base: "100%", md: "40%"}}>
                    <FormikInput isRequired flexBasis={"100%"} label={"Invoice Number"} name={`invoiceNumber`} type={"text"} />
                </WrapItem>
                <WrapItem w={{base: "100%", md: "30%"}}>
                    <FormikInput isRequired flexBasis={"100%"} label={"Date"} name={`receiptDate`}
                                 type={"date"} />
                </WrapItem>
            </Wrap>
            <FormikTextArea label={"Note"} name={"note"} />
        </Box>
    )
}

export default BusinessInvoiceInfo