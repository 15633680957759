import BusinessTaxDeduction from "Pages/Components/Forms/BusinessTaxDeduction";
import FormHeading from "../../Components/FormHeading";
import BusinessLineItems from "../../Components/Forms/BusinessLineItems";
import ReceiptFrom from "./Forms/ReceiptFrom";
import ReceiptTo from "./Forms/ReceiptTo";
import BusinessInvoiceInfo from "../../Components/Forms/BusinessInvoiceInfo";
import {IReceipt} from "../../../Types/Interface/IReceipt";
import FormsLayout from "../../../Layouts/FormsLayout";
import React, {useEffect} from "react"
import usePreferredPriceDiscount from "../../../Hooks/usePreferredPriceDiscount";
import {setDocumentData} from "../../../Store/Slices";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";
import {FormType} from "../../../Types/Enums/documentTypeEnums";

const ReceiptForms = () => {
    const {values} = usePreferredPriceDiscount<IReceipt>()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setDocumentData({data: values, formType: FormType.Receipt}));
    }, [values]);

  return (
      <FormsLayout>
          <FormHeading title={"Create Receipt"} description={"Fill in your information below"}/>
          <ReceiptFrom/>
          <ReceiptTo/>
          <BusinessInvoiceInfo formHeading={"Receipt Details"} currentStep={3} steps={4}/>
          <BusinessLineItems currentStep={4} steps={4} values={values}/>
          <BusinessTaxDeduction/>
      </FormsLayout>
  )
}

export default ReceiptForms;