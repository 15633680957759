import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import dottedLine from "../../../Assets/Images/DottedPath.png"
import learnMoreImage from "../../../Assets/Images/LearnMoreTax.png"
import {theme} from "../../../Theme/Theme";
import useDeviceQuery, { ScreenType } from "Hooks/useDeviceQuery";
import React from "react"

const LearnMoreSection = () => {
    const device = useDeviceQuery()
  return (
      <Box pos={"relative"} overflow={"clip"} backgroundImage={device === ScreenType.Iphone ? undefined : dottedLine} backgroundPosition={'center'} backgroundRepeat={"no-repeat"} h={{base: "50vh", md: "80vh"}}>
         <Flex zIndex={"docked"} direction={device === ScreenType.Ipad ? "column": "row"} h={"full"} align={"center"} mb={"24px"} px={{base: "30px", md: "80px"}} pos={"relative"}>
              <Flex direction={"column"} flexGrow={"1"} align={"center"} justifyContent={"center"}>
                  <Box fontSize={device === ScreenType.Ipad ? "25px" : {base: "16px", md: "35px"}} maxW={"780"} fontWeight={"bold"} mb={"65px"}>
                      <Text textAlign={"center"}>
                          <span style={{color: theme.primary}}>
                              &#34;
                          </span>
                          Join thousands of satisfied users who rely on PhcWorkHub for their document generation needs. Sign up today at  <span style={{color: theme.primary}}>phcworkhub.com</span> and experience the difference
                          <span style={{color: theme.primary}}>
                              &#34;
                          </span>
                      </Text>
                  </Box>
                  {/* Todo change to link component later*/}
                  <Button w={273} h={{base: "42px", md: "68.43px"}} borderRadius={"12px"} py={"18px"} fontWeight={"medium"} fontSize={{base: "lg", md: "3xl"}} variant={"outline"} background={theme.onSecondary} borderColor={theme.primary} color={theme.primary} borderWidth={{base: "2px", md: "6px"}}>
                      Learn more
                  </Button>
              </Flex>
              {
                device === ScreenType.Macbook && <Image src={learnMoreImage} h={594} w={594} ml={"4"}/>
              }
          </Flex>
      </Box>
  )
}

export default LearnMoreSection