import {
    Box,
    Flex, NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text, Wrap, WrapItem,
} from "@chakra-ui/react";
import FormHeading from "../../Components/FormHeading";
import EmployeeInfoForm from "./Forms/EmployeeInfoForm";
import PayStubInfoForm from "./Forms/PayStubInfoForm";
import CompanyInfoForm from "./Forms/CompanyInfoForm";
import PeriodicPayStubSection from "./PeriodicPayStubSection";
import React, { ChangeEvent } from 'react';
import { IDeduction, IEarning, IPaystubData } from "../../../Types/Interface/IPaystub";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import { setPaystubCount } from "../../../Store/Slices/paystubSlice";

type HandleChange = (e: ChangeEvent) => void
type HandleLogo = (image: File) => Promise<void>
type SetFieldValue = (field: string, value: string | boolean | number | IEarning[] | IEarning | IDeduction | IDeduction[]) => void

const PayStubForms = ({ handleChange, values, setFieldValue, handleLogo }: { handleChange: HandleChange, values: IPaystubData, setFieldValue: SetFieldValue, handleLogo: HandleLogo }) => {

    return (
        <Box flexBasis={"50%"}>
            <FormHeading title={"Create Paystub"}
                description={"Fill in your information below."} />
            <EmployeeInfoForm employeeData={values.employee} payStubs={values.payStubs} handleChange={handleChange} setFieldValue={setFieldValue} />
            <PayStubInfoForm info={values.info} handleChange={handleChange} />
            <CompanyInfoForm company={values.company} handleChange={handleChange} handleLogo={handleLogo} setFieldValue={setFieldValue} />
            <PayStubsCountInput value={values.info.payStubCount} setFieldValue={setFieldValue} />
            <PeriodicPayStubSection paystubs={values.payStubs} info={values.info} handleChange={handleChange} setFieldValue={setFieldValue} />
        </Box>
    )
}

const PayStubsCountInput = ({  value }: { setFieldValue: SetFieldValue, value: number }) => {
    const dispatch = useAppDispatch()
    return (
        <Wrap mb={"24px"} justify={"space-between"}>
            <WrapItem w={{base: "100%", md: "40%"}}>
                <Text mr={"24px"} fontSize={'20px'} flexBasis={"100%"} fontWeight={"semibold"} color={"#208c55"}>
                    How many paystubs do you need
                </Text>
            </WrapItem>
            <WrapItem w={{base: "100%", md: "50%"}}>
                <NumberInput value={value} min={1} max={12} flexBasis={"100%"} onChange={(valueString) => dispatch(setPaystubCount(valueString.length > 0 ? parseInt(valueString) : 1))} name={"info.payStubCount"}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </WrapItem>
        </Wrap>
    )
}

export default PayStubForms