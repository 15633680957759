import { z } from "zod";

export const F1099MiscSubmissionSchema = z.object({
    _1099MISCDto: z.object({
        type: z.number(),
        year: z.number().gte(2000, {message: "year must be greater than 2000"})
            .lte(new Date().getFullYear(), `Year must be less than or equal to  ${new Date().getFullYear()}`),
        numberOfForms: z.number(),
        lastYearIncomeBeforeTaxes: z.coerce.number(),
        email: z.string().email({message: "Invalid email, please provide a valid email"}),
        correctedIfChecked: z.string(),
        accountNumber: z.string(),
        formCount: z.number(),
        payer: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            name: z.string().nonempty({message: "Payer's name must be provided"}),
            tin: z.string().nonempty({message: "Payer's tin (company's ein) must be provided"}),
            address: z.string().nonempty({message: "Payer's address must be provided"}),
            state: z.string().nonempty({message: "Payer's state must be provided"}),
            city: z.string().nonempty({message: "Payer's city must be provided"}),
            zipCode: z.string().min(4, "Payer zip code must be 4 or more characters long."),
            payerMadeDirectSalesOver5000: z.string(),
            stateIncome: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            statePayerStateNumber: z.coerce.string()
        }),
        recipient: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            name: z.string().nonempty({message: "Recipient's name must be provided"}),
            address: z.string().nonempty({message: "Recipient's address must be provided"}),
            street: z.string(),
            state: z.string().nonempty({message: "Recipient's state must be provided"}),
            city: z.string().nonempty({message: "Recipient's city must be provided"}),
            zipCode: z.string().min(4, "Recipient's zip code must be 4 or more characters long."),
            tin: z.string().length(11, {message: "Recipient's tin(ssn) not valid"}),
        }),
        options: z.object({
            fatcaFiling: z.coerce.string(),
            accountNumber: z.coerce.string(),
            cropInsurance: z.coerce.number(),
            directSalesHigh: z.coerce.number(),
            fishPurchased: z.coerce.number(),
            fishingBoatProceeds: z.coerce.number(),
            excessGoldenParachute: z.coerce.number(),
            grossProceeds: z.coerce.number(),
            stateIncome: z.coerce.number(),
            medicalHealthcarePayments: z.coerce.number(),
            nonqualifiedDefferedCompensation: z.coerce.number(),
            rents: z.coerce.number(),
            royalties: z.coerce.number(),
            section409AReferals: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            subPayments: z.coerce.number(),
            federalTaxIncomeWithheld: z.coerce.number().default(0),
            otherIncome: z.coerce.number().default(0),
        }),
    }),
});
export const F1099MiscFillSchema = z.object({
    _1099MISCDto: z.object({
        type: z.number(),
        year: z.number(),
        numberOfForms: z.number(),
        lastYearIncomeBeforeTaxes: z.coerce.number(),
        email: z.string(),
        correctedIfChecked: z.string(),
        accountNumber: z.string(),
        formCount: z.number(),
        payer: z.object({
            id: z.number(),
            name: z.string(),
            tin: z.string(),
            address: z.string(),
            state: z.string(),
            city: z.string(),
            zipCode: z.string(),
            payerMadeDirectSalesOver5000: z.string(),
            stateIncome: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            statePayerStateNumber: z.coerce.string()
        }),
        recipient: z.object({
            id: z.number(),
            name: z.string(),
            address: z.string(),
            street: z.string(),
            state: z.string(),
            city: z.string(),
            zipCode: z.string(),
            tin: z.string(),
        }),
        options: z.object({
            fatcaFiling: z.coerce.string(),
            accountNumber: z.coerce.string(),
            cropInsurance: z.coerce.number(),
            directSalesHigh: z.coerce.number(),
            fishPurchased: z.coerce.number(),
            fishingBoatProceeds: z.coerce.number(),
            excessGoldenParachute: z.coerce.number(),
            grossProceeds: z.coerce.number(),
            stateIncome: z.coerce.number(),
            medicalHealthcarePayments: z.coerce.number(),
            nonqualifiedDefferedCompensation: z.coerce.number(),
            rents: z.coerce.number(),
            royalties: z.coerce.number(),
            section409AReferals: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            subPayments: z.coerce.number(),
            federalTaxIncomeWithheld: z.coerce.number().default(0),
            otherIncome: z.coerce.number().default(0),
        }),
    }),
});
