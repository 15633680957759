import AppLayout from "../../Layouts/AppLayout";
import {Form, Formik} from "formik";
import {IReceipt} from "../../Types/Interface/IReceipt";
import {IAddress, IItems, ISender} from "../../Types/Interface/IQuotes";
import {Box, Flex} from "@chakra-ui/react";
import ReceiptForms from "./Components/ReceiptForms";
import ReceiptPreview from "./Components/ReceiptPreview";
import {resetDocumentState, resetLogo, setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import {AppRoutes} from "../../Routes/AppRoutes";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../Hooks/useAppSelector";
import {useToast} from "../../Hooks/useToast";
import React, {useEffect} from "react"
import useFormSubmit from "../../Hooks/useFormSubmit";
import submitReceipt, {getReceipt} from "../../Services/api/receipt";
import PreviewHeader from "../../Components/PreviewHeader";
import NextButtonPreviewBar from "../../Components/NextButtonPreviewBar";
import useMobilePreview from "../../Hooks/useMobilePreview";
import useFetchFormData from "../../Hooks/useFetchFormData";

const initialLineItem: IItems = {
    description: "", id: 0, lineTotal: 0, quantity: 0, rate: 0
}

const initialAddress: IAddress = {
    addressLine1: "", addressLine2: "", city: "", country: "", id: 0, postalCode: "", regionOrState: "AK"
}

const initialSender: ISender = {
    address: initialAddress, email: "", id: 0, logo: "", name: "", phoneNumber: "", websiteUrl: ""
}
const initialReceiver: ISender = {
    address: initialAddress, email: "", id: 0, logo: "", name: "", phoneNumber: "", websiteUrl: ""
}

const initialReceipt: IReceipt = {
    discountAmount: 0,
    discountPercentage: 0,
    dueDate: "",
    email: "",
    for: initialReceiver,
    from: initialSender,
    id: 0,
    invoiceNumber: "",
    lineItems: [
        initialLineItem
    ],
    logoUrl: "",
    note: "",
    receiptDate: "",
    receiptHtmlString: "",
    taxAmount: 0,
    accept: false,
    agree: false
}

const Receipt = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const downloaded = useAppSelector((state) => state.document.downloaded)
    const data = useAppSelector((state) => state.document)
    const {alert} = useToast()
    const {submit, isSuccess, isLoading} = useFormSubmit(submitReceipt, FormType.Receipt)
    const {isNextPage, setIsNextPage} = useMobilePreview()
    const {loading: dataLoading, data: fetchedReceipt} = useFetchFormData(getReceipt, FormType.Receipt)



    useEffect(() => {
        dispatch(resetLogo())
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(resetDocumentState())
        }
    }, [])

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.Receipt))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.Receipt))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess]);

    const handleSubmit = (values: IReceipt) => {
        if (!values.agree || !values.accept ){
            alert("error", "Please agree or accept to our Terms")
        } else if (Object.keys(data).length > 0) {
            if (!isLoading){
                submit(values)
            }
        }
    }

    const handleMobileSubmit = () => {
        const storedData = data.documentData.receipt
        if (storedData === undefined) {
            alert("error", "Please fill form")
            return
        }
        if (!storedData.agree || !storedData.accept) {
            alert("error", "Please agree or accept to our Terms")
        } else if (Object.keys(storedData).length > 0) {
            if (!isLoading){
                submit(storedData)
            }
        }
    }

    return (
      <AppLayout isForm loading={dataLoading}>
          <Formik<IReceipt> initialValues={fetchedReceipt !== undefined ? fetchedReceipt : (data.documentData.receipt === undefined || downloaded) ? initialReceipt : data.documentData.receipt} onSubmit={handleSubmit}>
              {
                  () => (
                      <Form>
                          <Flex w={"full"} display={{base: "none", lg: "flex" }} direction={{base: "column", lg: "row"}} px={"50px"}  py={"50px"} gap={"32px"} pos={"relative"}>
                              <ReceiptForms/>
                              <ReceiptPreview />
                          </Flex>
                          <Box display={{lg: "none" }} px={"16px"} position={"relative"}>
                              {
                                  isNextPage ? (
                                      <>
                                          <PreviewHeader goBack={()=> setIsNextPage(false)}/>
                                          <ReceiptPreview submit={handleMobileSubmit} />
                                      </>
                                  ) : (
                                      <>
                                          <ReceiptForms/>
                                          <NextButtonPreviewBar formType={FormType.Receipt} setNextPage={() => setIsNextPage(true)}/>
                                      </>
                                  )
                              }
                          </Box>

                      </Form>
                  )
              }
          </Formik>
      </AppLayout>
  )
}

export default Receipt