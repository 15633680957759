import {useAppSelector} from "../Hooks/useAppSelector";
import React, {useRef} from "react";
import {Box, Button, Center} from "@chakra-ui/react";
import useFill1099Nec from "../Hooks/useFill1099Nec";
import {select1099NecState, setNecDownloaded} from "../Store/Slices/1099NecSlice";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {useAppDispatch} from "../Hooks/useAppDispatch";
import useVerifyPayment from "../Hooks/useVerifyPayment";
import PdfViewerV2 from "../Pages/Components/PdfViewerV2";

const F1099Document = ({hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const data = useAppSelector(select1099NecState)
    const {data: pdfData, refreshData, loading} = useFill1099Nec(data)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const dispatch = useAppDispatch()
    const {isSuccess, checkoutLoading} = useVerifyPayment(hasPaid, session)

    const downloadPdf = () => {
        const pdfUrl = URL.createObjectURL(pdfData as Blob)
        if (anchorRef.current){
            anchorRef.current.href = pdfUrl
            anchorRef.current.download = "1099 Nec"
            anchorRef.current.click()
        }
        dispatch(setNecDownloaded())
    }

    if (hasPaid && session) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <>
                                <Box position="absolute" top="-9999px" left="-9999px">
                                    <a href={'#'} ref={anchorRef}>Download file</a>
                                </Box>
                                <Button onClick={downloadPdf}>
                                    Download 1099 Nec
                                </Button>
                            </>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }

    return (
        <PdfViewerV2 file={pdfData} refresh={() => { refreshData() }} loading={loading}></PdfViewerV2>
    )
}

export default F1099Document