import { z } from "zod";

export const PaystubSchema = z.object({
    payStubDto: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        company: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            address: z.object({
                id: z.number(),
                isCompletedForm: z.boolean(),
                addressLine1: z.string().nonempty({message: "Company address must be provided."}),
                addressLine2: z.string(),
                postalCode: z.string().nonempty({message: "Company zip code must be provided."}),
                city: z.string().nonempty({message: "Company city must be provided."}),
                regionOrState: z.string().nonempty({message: "Company State must be provided."}),
                country: z.string(),
            }),
            name: z.string().nonempty({message: "Company's name must be provided"}),
            email: z.string().email({message: "Company email invalid."}),
            websiteUrl: z.string(),
            phoneNumber: z.string(),
            logo: z.string().optional(), // come back to it
            ein: z.string(),
            taxId: z.string(),
            isContractor: z.boolean(),
        }),
        employee: z.object({
            id: z.number(),
            name: z.string().nonempty({message: "Employee name must be provided"}),
            firstName: z.string(),
            lastName: z.string(),
            middleName: z.string(),
            addressLine1: z.string().nonempty({message: "Employee address must be provided"}),
            zip: z.string().min(4, {message: "Invalid employee zip"}),
            city: z.string().nonempty({message: "City name must be provided"}),
            state: z.string().nonempty({message: "Employee state must be provided"}),
            ssn: z.string().length(4, "Last 4 digits of employee ssn must be provided"),
            employeeNumber: z.string().nonempty({message: "Employee id must be provided"}),
            role: z.string(),
            email: z.string(),
            phoneNumber: z.string(),
            isMarried: z.boolean(),
        }),
        schedule: z.number(),
        paymentMode: z.number(),
        externalId: z.string().optional(),
        hourlyRate: z.number(),
        earnings: z.array(
            z.object({
                id: z.number(),
                isCompletedForm: z.boolean(),
                paymentSchedule: z.number(),
                paymentMode: z.number(),
                payDate: z.string(),
                from: z.string(),
                to: z.string(),
                totalHours: z.number(),
                hourlyRate: z.number(),
                additionalEarnings: z.array(
                    z.object({
                        id: z.number(),
                        description: z.string(),
                        rate: z.number(),
                        totalHoursOrQuantity: z.number().optional(),
                        earningStubId: z.number(),
                        employeeId: z.number(),
                    })
                ),
                customDeductions: z.array(
                    z.object({
                        id: z.number(),
                        isCompletedForm: z.boolean(),
                        rate: z.number(),
                        hours: z.number(),
                        earningStubId: z.number(),
                        description: z.string(),
                    })
                ),
                medicare: z.number(),
                stateTax: z.number(),
                stubDetailId: z.number(),
                socialSecurity: z.number(),
                federalTax: z.number(),
                exemption_Allowance: z.number(),
                adviceNumber: z.string(),
            })
        ),
    }),
});