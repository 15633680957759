import React, {useState} from "react"
import {Button, Flex, Box, useDisclosure} from "@chakra-ui/react";
import PreviewModal from "./PreviewModal";
import {FormType} from "../Types/Enums/documentTypeEnums";

type NextButtonPrevBarProps = {
    formType: FormType,
    setNextPage: () => void
}

const NextButtonPreviewBar = ({formType, setNextPage}: NextButtonPrevBarProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [preview, setPreview] = useState(false)
    return (
        <>
            <PreviewModal
                formType={formType}
                onClose={onClose}
                isOpen={isOpen}
                background={"grey"}
                setPreview={(prev) => setPreview(prev)}
                preview={preview}
                disabled={true}/>
            <Box background={"primary"} onClick={()=> {
                setPreview(true)
                onOpen()
            }} _hover={{background: "#1FBC6CA8"}} borderRadius={"8px"} w={"min-content"} h={"min-content"} p={"8px"} m={"8px"} cursor={"pointer"} position={"fixed"} right={"0"} bottom={"0"} zIndex={"modal"}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.0001 21.6667C15.0001 22.9928 15.5269 24.2646 16.4645 25.2022C17.4022 26.1399 18.674 26.6667 20.0001 26.6667C21.3262 26.6667 22.5979 26.1399 23.5356 25.2022C24.4733 24.2646 25.0001 22.9928 25.0001 21.6667C25.0001 20.3406 24.4733 19.0689 23.5356 18.1312C22.5979 17.1935 21.3262 16.6667 20.0001 16.6667C18.674 16.6667 17.4022 17.1935 16.4645 18.1312C15.5269 19.0689 15.0001 20.3406 15.0001 21.6667ZM33.3334 32.65V13.3334L23.3334 3.33337H10.0001C9.11603 3.33337 8.26818 3.68456 7.64306 4.30968C7.01794 4.93481 6.66675 5.78265 6.66675 6.66671V33.3334C6.66675 34.2174 7.01794 35.0653 7.64306 35.6904C8.26818 36.3155 9.11603 36.6667 10.0001 36.6667H30.0001C30.7501 36.6667 31.4167 36.4167 31.9834 36L24.6001 28.6167C23.2667 29.4834 21.6667 30 20.0001 30C17.7899 30 15.6703 29.1221 14.1075 27.5593C12.5447 25.9965 11.6667 23.8768 11.6667 21.6667C11.6667 19.4566 12.5447 17.337 14.1075 15.7742C15.6703 14.2113 17.7899 13.3334 20.0001 13.3334C22.2102 13.3334 24.3298 14.2113 25.8926 15.7742C27.4554 17.337 28.3334 19.4566 28.3334 21.6667C28.3334 23.3334 27.8167 24.9334 26.9501 26.25L33.3334 32.65Z"
                        fill="white"/>
                </svg>
            </Box>
            <Flex justifyContent={"space-between"} mb={"24px"}>
                <Button disabled={true}>
                    Save and Continue Later
                </Button>
                <Button onClick={setNextPage}>
                    Next
                </Button>
            </Flex>
        </>
    )
}


export default NextButtonPreviewBar