import {I1099Nec} from "../Pages/1099Nec/validator";
import {useCallback, useEffect, useState} from "react";
import {reqClient} from "../Api/reqClient";
import {useDebouncedCallback} from "use-debounce";
import {F1099FillDto} from "../schemas/F1099NecSchema";


export const transformNecData = (f1099Data: I1099Nec) => {
    const recipients = f1099Data.recipients === undefined ? [] : f1099Data.recipients;
    return {
        "_1099Dto": {
        "type": 0,
            "year": f1099Data.info.year,
            "numberOfForms": f1099Data.info.formCount ?? 1,
            "lastYearIncomeBeforeTaxes": recipients[0].options.income ?? 0,
            "email": f1099Data.info.email ?? "",
            "correctedIfChecked": "On",
            "accountNumber": recipients[0].options.accountNumber ?? "",
            "formCount": f1099Data.info.formCount ?? 1,
            "payer": {
            "id": 0,
                "name": f1099Data.payer.name ?? "",
                "tin": f1099Data.payer.tin ?? "",
                "address": f1099Data.payer.address.stateAddress ?? "",
                "state": f1099Data.payer.address.state ?? "",
                "city": f1099Data.payer.address.city ?? "",
                "zipCode": f1099Data.payer.address.zipcode ?? "",
                "payerMadeDirectSalesOver5000": recipients[0].options.directSales ? "On" : "Off",
                "stateIncome": recipients[0].options.stateIncome ?? 0,
                "stateTaxWithheld": recipients[0].options.stateTax ?? 0,
                "federalIncomeTaxWithHeld": recipients[0].options.incomeTax ?? 0,
                "statePayerStateNumber": recipients[0].options.payersNo ?? ""
        },
        "recipient": {
            "id": 0,
                "name": recipients[0].name ?? "",
                "address": recipients[0].address.stateAddress ?? "",
                "street": "",
                "state": recipients[0].address.state ?? "",
                "city": recipients[0].address.city ?? "",
                "zipCode": recipients[0].address.zipcode ?? "",
                "tin": recipients[0].ssn ?? ""
        },
        "_1099NECOptions": {
            "id": 0,
                "nonEmployeeCompensation": recipients[0].options.nonEmployeeCompensation ?? 0,
                "directSalesOver5000": recipients[0].options.directSales ?? 0,
                "directSalesOver5000Amount": 0,
                "federalIncomeTaxWithHeld": recipients[0].options.incomeTax ?? 0,
                "stateTaxWithHeld": recipients[0].options.stateTax ?? 0,
                "stateIncome": recipients[0].options.stateIncome ?? 0
        }
    }
    }
}

const useFill1099Nec = (f1099Data: I1099Nec) => {
    const [data, setData] = useState<File>()
    const [loading, setLoading] = useState(true);

    const refreshData = useCallback(async () => {
        setLoading(true)
        try {
            const fData = await reqClient.post("/fill1099", F1099FillDto.parse(transformNecData(f1099Data)), {responseType: "blob"})
            setData(fData.data)
        } catch (e) {
        }

        setLoading(false)
    }, [f1099Data])

    const debounce = useDebouncedCallback(
        refreshData, 2000
    )

    useEffect(() => {
        debounce()
    }, [debounce, f1099Data])

    return { data, refreshData, loading }
}

export default useFill1099Nec