import { Center, Box} from "@chakra-ui/react"
import React from "react";
import TopNavBar from "../Components/TopNavBar";
import { createPortal } from "react-dom";
import AppFooterV2 from "../Components/AppFooterV2";
import LoadingScreen from "../Components/LoadingScreen";

type AppLayoutProps = {
    loading?: boolean
    children: React.ReactNode
    isForm?: boolean
}

const AppLayout = ({ children, isForm, loading }: AppLayoutProps) => {
    return (
        <Center w={"full"}>
            {/* General app is constrained to max width of 1512px and
                centers in screen once viewport is more than constraint*/}
            <Box w={"full"} maxW={"1512px"} pos={"relative"} mt={isForm ? '20' : "0"}>
                {/* Nav bar */}
                <>
                    {
                        createPortal(<TopNavBar />, document.body)
                    }
                    {
                        loading ? (<LoadingScreen/>) : isForm ? (
                            <Center w={"full"}>
                                <Box w={"full"} maxW={"1380px"}>
                                    {children}
                                </Box>
                            </Center>
                        ) : children
                    }
                    <AppFooterV2/>
                </>
            </Box>
        </Center>
    )
}


export default AppLayout