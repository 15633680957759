import FormHeading from "../../Components/FormHeading";
import {Box, Wrap, WrapItem} from "@chakra-ui/react"
import QuoteFrom from "./Forms/QuoteFrom";
import QuoteFor from "./Forms/QuoteFor";
import QuoteItems from "./Forms/QuoteItems";
import BusinessTaxDeduction from "../../Components/Forms/BusinessTaxDeduction";
import React, {useEffect} from "react"
import usePreferredPriceDiscount from "../../../Hooks/usePreferredPriceDiscount";
import {IQuotes} from "../../../Types/Interface/IQuotes";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";
import {setDocumentData} from "../../../Store/Slices";
import BusinessFormsHeading from "../../Components/Forms/BusinessFormsHeading";
import FormikInput from "../../Components/Formik/FormikInput";
import {FormType} from "../../../Types/Enums/documentTypeEnums";

const QuoteForms = () => {
    const {values} = usePreferredPriceDiscount<IQuotes>()

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setDocumentData({data: values, formType: FormType.Quotes}));
    }, [values]);

  return (
      <Box flexBasis={{base: "45%", lg: "60%"}}>
          <FormHeading color={"#1fbc6c"} title={"Create Quotation"} description={"Fill in your information below"}/>
          <QuoteFrom/>
          <QuoteFor/>
          <Box mb={"64px"} w={'full'}>
              <BusinessFormsHeading title={"Quote Details"} />
              <Wrap gap={"16px"}>
                  <WrapItem w={{base: "100%", md: "40%"}}>
                      <FormikInput isRequired flexBasis={"100%"} isDisabled={true} label={"Quote Number"} name={`quoteId`} type={"text"} />
                  </WrapItem>
                  <WrapItem w={{base: "100%", md: "25%"}}>
                       <FormikInput isRequired flexBasis={"30%"} label={"Date Due"} name={"dueDate"} type={"date"} />
                  </WrapItem>
              </Wrap>
          </Box>
          <QuoteItems/>
          <BusinessTaxDeduction/>
      </Box>
  )
}

export default QuoteForms