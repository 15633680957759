import {z} from "zod";

export const F1099SubmissionDto = z.object({
    _1099Dto: z.object({
        type: z.number(),
        year: z.coerce.number().gte(2000, {message: "year must be greater than 2000"})
            .lte(new Date().getFullYear(), `Year must be less than or equal to  ${new Date().getFullYear()}`),
        numberOfForms: z.coerce.number(),
        lastYearIncomeBeforeTaxes: z.coerce.number(),
        email: z.string().email({message: "Invalid email, please provide a valid email"}),
        correctedIfChecked: z.string(),
        accountNumber: z.string(),
        formCount: z.coerce.number(),
        payer: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            name: z.string().nonempty({message: "Payer's name must be provided"}),
            tin: z.string().nonempty({message: "Payer's tin (company's ein) must be provided"}),
            address: z.string().nonempty({message: "Payer's address must be provided"}),
            state: z.string().nonempty({message: "Payer's state must be provided"}),
            city: z.string().nonempty({message: "Payer's city must be provided"}),
            zipCode: z.string().min(4, "Payer zip code must be 4 or more characters long."),
            payerMadeDirectSalesOver5000: z.string(),
            stateIncome: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            statePayerStateNumber: z.string(),
        }),
        recipient: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            name: z.string().nonempty({message: "Recipient's name must be provided"}),
            address: z.string().nonempty({message: "Recipient's address must be provided"}),
            street: z.string(),
            state: z.string().nonempty({message: "Recipient's state must be provided"}),
            city: z.string().nonempty({message: "Recipient's city must be provided"}),
            zipCode: z.string().min(4, "Recipient's zip code must be 4 or more characters long."),
            tin: z.string().length(11, {message: "Recipient's tin(ssn) not valid"}),
        }),
        _1099NECOptions: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            nonEmployeeCompensation: z.coerce.number(),
            directSalesOver5000: z.boolean(),
            directSalesOver5000Amount: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            stateTaxWithHeld: z.coerce.number(),
            stateIncome: z.coerce.number(),
        }),
    }),
});
export const F1099FillDto = z.object({
    _1099Dto: z.object({
        type: z.number(),
        year: z.coerce.number(),
        numberOfForms: z.number(),
        lastYearIncomeBeforeTaxes: z.coerce.number(),
        email: z.string(),
        correctedIfChecked: z.string(),
        accountNumber: z.string(),
        formCount: z.number(),
        payer: z.object({
            id: z.number(),
            name: z.string(),
            tin: z.string(),
            address: z.string(),
            state: z.string(),
            city: z.string(),
            zipCode: z.string(),
            payerMadeDirectSalesOver5000: z.string(),
            stateIncome: z.coerce.number(),
            stateTaxWithheld: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            statePayerStateNumber: z.string(),
        }),
        recipient: z.object({
            id: z.number(),
            name: z.string(),
            address: z.string(),
            street: z.string(),
            state: z.string(),
            city: z.string(),
            zipCode: z.string(),
            tin: z.string(),
        }),
        _1099NECOptions: z.object({
            id: z.number(),
            nonEmployeeCompensation: z.coerce.number(),
            directSalesOver5000: z.boolean(),
            directSalesOver5000Amount: z.coerce.number(),
            federalIncomeTaxWithHeld: z.coerce.number(),
            stateTaxWithHeld: z.coerce.number(),
            stateIncome: z.coerce.number(),
        }),
    }),
});