import { z } from "zod";

export const ReceiptSubmissionSchema = z.object({
    taxAmount: z.number(),
    discountAmount: z.number(),
    discountPercentage: z.number(),
    invoiceNumber: z.string().nonempty({message: "Invoice number is required"}),
    note: z.string().optional(),
    dueDate: z.string().date("Invalid due date"),
    receiptDate: z.string().date("Invalid Date in receiptDetails.date"),
    from: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        address: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            addressLine1: z.string().nonempty({message: "Company address must be provided."}),
            addressLine2: z.string(),
            postalCode: z.string().nonempty({message: "Company zip code must be provided."}),
            city: z.string().nonempty({message: "Company city must be provided."}),
            regionOrState: z.string().nonempty("Company state must be provided."),
            country: z.string(),
        }),
        name: z.string().nonempty({message: "Company name must be provided."}),
        email: z.string().email({message: "Company email invalid."}),
        websiteUrl: z.string(),
        phoneNumber: z.string().nonempty({message: "Company Phone number must be provided"}),
        logo: z.string().optional(),
        ein: z.string(),
        taxId: z.string(),
        isContractor: z.boolean(),
    }),
    for: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        address: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            addressLine1: z.string().nonempty({message: "Customer's address must be provided."}),
            addressLine2: z.string(),
            postalCode: z.string().nonempty({message: "Customer's zip code must be provided."}),
            city: z.string().nonempty({message: "Customer's city must be provided."}),
            regionOrState: z.string().nonempty("Customer's state must be provided."),
            country: z.string(),
        }),
        name: z.string().nonempty({message: "Customer's name must be provided."}),
        email: z.string().email({message: "Customer's email invalid."}),
        websiteUrl: z.string(),
        phoneNumber: z.string().nonempty({message: "Customer's Phone number must be provided"}),
        logo: z.string().optional(),
        ein: z.string(),
        taxId: z.string(),
        isContractor: z.boolean(),
    }),
    lineItems: z.array(
        z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            description: z.string().nonempty({message: "A Service description in one of your items is missing."}),
            quantity: z.number().min(1, {message: "Quantity must be a positive integer."}),
            rate: z.number().gt(0, {message: "Rate must be a positive integer."}),
            lineTotal: z.number(),
        })
    ),
    email: z.string(),
});