import { Box, Button, Flex, Image, Spacer, Text, Center, Menu, MenuButton, MenuList, MenuItem, } from "@chakra-ui/react"
import logo from "../Assets/Images/logo.png"
import { NavLink, useNavigate } from "react-router-dom"
import { theme } from "../Theme/Theme";
import { AppRoutes } from "../Routes/AppRoutes";
import useDeviceQuery, { ScreenType } from "../Hooks/useDeviceQuery";
import React, {useEffect, useState} from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";

type Page = {
    label: string,
    basePath?: string,
    hidden: boolean,
    items?: Item[]
}

type Item = {
    label: string,
    hidden: boolean,
    path: string
}

type TopNavBarProps = {
    pages: Page[]
}

const pages: Page[] = [
    {
        label: "Home",
        hidden: false,
        basePath: AppRoutes.home
    },
    {
        label: "Pay Stub",
        hidden: false,
        basePath: AppRoutes.payStub
    },
    {
        label: "W2",
        hidden: false,
        basePath: AppRoutes.wforms,
        items: [
            {
                label: "w-2",
                hidden: false,
                path: AppRoutes.w2
            },
            {
                label: "w-4",
                hidden: true,
                path: AppRoutes.w4
            },
            {
                label: "w-9",
                hidden: true,
                path: AppRoutes.w9
            }
        ]
    },
    {
        label: "1099",
        hidden: false,
        basePath: AppRoutes.f1099,
        items: [
            {
                label: "1099 Nec",
                hidden: false,
                path: AppRoutes.f1099Nec
            },
            {
                label: "1099 Misc",
                hidden: false,
                path: AppRoutes.f1099Misc
            },
            {
                label: "1099 K",
                hidden: true,
                path: AppRoutes.f1099K
            }
        ]
    },
    {
        label: "Real Estate",
        hidden: true,
        basePath: AppRoutes.realEstate,
        items: [
            {
                label: "Lease Agreement",
                hidden: true,
                path: AppRoutes.leaseAgreement
            },
            {
                label: "Sublease Agreement",
                hidden: true,
                path: AppRoutes.subleaseAgreement,
            },
            {
                label: "Eviction Notice",
                hidden: true,
                path: AppRoutes.evictionNotice
            }
        ]
    },
    {
        label: "Business",
        hidden: false,
        basePath: AppRoutes.receipt,
        items: [
            {
                label: "Quote",
                hidden: false,
                path: AppRoutes.quotes
            },
            {
                label: "Invoice",
                hidden: true,
                path: AppRoutes.invoice
            },
            {
                label: "Receipt",
                hidden: false,
                path: AppRoutes.receipt
            },
            {
                label: "Vehicle Bill of Sales",
                hidden: true,
                path: AppRoutes.vehicleBos
            },
            {
                label: "Void Check",
                hidden: true,
                path: AppRoutes.voidCheck
            },
            {
                label: "Power of Attorney",
                hidden: true,
                path: AppRoutes.powerAttorney
            },
            {
                label: "Employment Letter",
                hidden: true,
                path: AppRoutes.employmentLetter
            },
            {
                label: "Termination Letter",
                hidden: true,
                path: AppRoutes.terminationLetter
            },
            {
                label: "Truck Driver's Agreement",
                hidden: true,
                path: AppRoutes.truckDriverAgreement
            },
        ]
    },
    {
        label: "Pdf Editor",
        hidden: true,
        basePath: AppRoutes.pdfEditor
    },

]

const pagesIpad: Page[] = [
    {
        label: "Home",
        hidden: false,
        basePath: AppRoutes.home
    },
    {
        label: "Pay Stub",
        hidden: false,
        basePath: AppRoutes.payStub
    },
    {
        label: "Tax Forms",
        hidden: false,
        items: [
            {
                label: "w-2",
                hidden: false,
                path: AppRoutes.w2
            },
            {
                label: "w-4",
                hidden: true,
                path: AppRoutes.w4
            },
            {
                label: "w-9",
                hidden: true,
                path: AppRoutes.w9
            },
            {
                label: "1099 Nec",
                hidden: false,
                path: AppRoutes.f1099Nec
            },
            {
                label: "1099 Misc",
                hidden: false,
                path: AppRoutes.f1099Misc
            },
            {
                label: "1099 K",
                hidden: true,
                path: AppRoutes.f1099K
            },
            {
                label: "Lease Agreement",
                hidden: true,
                path: AppRoutes.leaseAgreement
            },
            {
                label: "Sublease Agreement",
                hidden: true,
                path: AppRoutes.subleaseAgreement,
            },
            {
                label: "Eviction Notice",
                hidden: true,
                path: AppRoutes.evictionNotice
            }
        ]
    },
    {
        label: "Business Forms",
        hidden: false,
        items: [
            {
                label: "Quote",
                hidden: false,
                path: AppRoutes.quotes
            },
            {
                label: "Invoice",
                hidden: true,
                path: AppRoutes.invoice
            },
            {
                label: "Receipt",
                hidden: false,
                path: AppRoutes.receipt
            },
            {
                label: "Vehicle Bill of Sales",
                hidden: true,
                path: AppRoutes.vehicleBos
            },
            {
                label: "Void Check",
                hidden: true,
                path: AppRoutes.voidCheck
            },
            {
                label: "Power of Attorney",
                hidden: true,
                path: AppRoutes.powerAttorney
            },
            {
                label: "Employment Letter",
                hidden: true,
                path: AppRoutes.employmentLetter
            },
            {
                label: "Termination Letter",
                hidden: true,
                path: AppRoutes.terminationLetter
            },
            {
                label: "Truck Driver's Agreement",
                hidden: true,
                path: AppRoutes.truckDriverAgreement
            },
        ]
    },
]

const TopNavBar = () => {
    const device = useDeviceQuery()
    if (device === ScreenType.Iphone) {
        return (
            <Box position={"relative"}>
                <TopNavIphone />
            </Box>
        )
    } else if (device === ScreenType.Ipad) {
        return <TopNavIpad />
    } else if (device === ScreenType.Macbook) {
        return <TopNavMacbook />
    } else {
        return <></>
    }
}

const TopNavMacbook = () => {
    return (
        <Flex h={'20'} align={"center"} background={"white"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
            <Flex direction={"column"} align={"center"} h={"full"} ml={"24px"} py={'12px'}>
                <Image h={"calc(100% - 16px)"} src={logo} objectFit={"contain"} />
                <Text color={"primary"}>PhcWorkHub</Text>
            </Flex>
            <Spacer />
            <TopNavLinks pages={pages} />
            <Spacer />
            <ButtonRow />
        </Flex>
    )
}

const TopNavDropDown = ({ page }: { page: Page }) => {
    return (
        <Menu >
            <MenuButton w={"max-content"} as={Flex} align={"center"} h={"full"} color={theme.primary} mr={"5"} fontSize={{ xl: "16px", base: "14px" }} fontWeight={"extrabold"} px={'2.5'}
                borderBottom={"2px"} borderBottomColor={"transparent"}>
                {page.label.toUpperCase()}
                <ChevronDownIcon />
            </MenuButton>
            <MenuList minW={"max-content"}>
                {
                    page.items?.map((item, index) => <MenuItem hidden={item.hidden} key={index} m={"0"} w={"max-content"}><TopNavLink hidden={item.hidden} label={item.label} basePath={item.path} /></MenuItem>)
                }
            </MenuList>
        </Menu>
    )
}

const TopNavIpad = () => {
    return (
        <Flex h={'20'} align={"center"} justifyContent={"space-between"} background={"white"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
            <Flex direction={"column"} align={"center"} h={"full"} ml={"24px"} py={'12px'}>
                <Image src={logo} h={"calc(100% - 16px)"} objectFit={"contain"} />
                <Text flexGrow={'1'} color={"primary"}>PhcWorkHub</Text>
            </Flex>
            <Flex fontSize={{ xl: "20px", base: "14px" }}>
                <TopNavLinks pages={pagesIpad} />
            </Flex>
            <ButtonRow isIpad />
        </Flex>
    )
}
const TopNavIphone = () => {
    const [navOpen, setNavOpen] = useState(false)
    const navigate = useNavigate();
    const toggleNav = () => {
        setNavOpen(!navOpen)
        if (navOpen) {
            document.body.style.overflowY = "auto"
        } else {
            document.body.style.overflowY = "hidden"
        }
    }
    
    useEffect(() => {
        // cleans up use nav effect on nav dismount
    return () => {
        if (navOpen) {
            document.body.style.overflowY = "auto"
        }
    }
    }, [navOpen]);

    return (
        <>
            <Flex h={'64px'} background={'white'} align={"center"} justifyContent={"space-between"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
                <Center h={"full"} ml={"16px"} py={"12px"}>
                    <Image src={logo} h={"full"} objectFit={"contain"} />
                </Center>
                <Text fontWeight={"700"} fontSize={"md"}>
                    PHC WORK HUB
                </Text>
                <Box h={"24px"} w={"24px"} color={"primary"} mr={"24px"} cursor={"pointer"} onClick={toggleNav}>
                    {
                        navOpen ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                            </svg>
                            ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"/>
                            </svg>
                        )
                    }
                </Box>
            </Flex>
            {
                navOpen && (
                    <Box position={"fixed"} left={"0px"} right={"0px"} top={"64px"} bottom={0} zIndex={"docked"}
                         background={"blackAlpha.400"} color={"primary"}>
                       <Box p={"32px"} maxH={"calc(100vh - 60px)"} background={"white"} overflowY={"scroll"}>
                           {
                               pages.map((page) => {
                                   if (page.items !== undefined) {
                                       return (
                                           !page.hidden && <MobileNavDrop page={page} key={page.label}/>
                                       )
                                   }
                                   return (
                                       !page.hidden && (
                                           <NavLink to={`${page.basePath}`} key={page.label} onClick={() => {document.body.style.overflowY = "auto"}}>
                                               <Text key={page.label} fontSize={"18px"} fontWeight={"bold"} py={"16px"}>{page.label}</Text>
                                           </NavLink>
                                       )
                                   )
                               })
                           }
                           <Button hidden={true} variant={"outline"}  w={"100%"} mb={"16px"} onClick={() => {
                               navigate(AppRoutes.signUp)
                           }}>
                               Sign Up
                           </Button>
                           <Button hidden={true} variant={"solid"} w={"100%"}  onClick={() => {
                               navigate(AppRoutes.login)
                           }}>
                               Login
                           </Button>
                       </Box>
                    </Box>
                )
            }
        </>
    )
}

const MobileNavDrop = ({page} : {page: Page}) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box maxH={isOpen ? undefined : "59px"} overflowY={"hidden"}>
           <Flex onClick={()=> setIsOpen(!isOpen)} py={"16px"} alignItems={"center"} cursor={"pointer"}>
               <Text fontWeight={"bold"} mr={"8px"} fontSize={"18px"}>{page.label}</Text>
               <Box w={"20px"} h={"20px"}>
                   {
                       isOpen ? (
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                               <path fillRule="evenodd" d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z" clipRule="evenodd" />
                           </svg>
                       ) : (
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                               <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                           </svg>
                       )
                   }
               </Box>
           </Flex>
            {
                page.items?.map((pageItem) => {
                    return (
                        !pageItem.hidden && (
                            <NavLink to={`${pageItem.path}`} key={pageItem.label} onClick={() => {document.body.style.overflowY = "auto"}}>
                                <Text key={pageItem.label} fontSize={"14px"} py={"16px"}>{pageItem.label}</Text>
                            </NavLink>
                        )
                        )
                })
            }
        </Box>
    )
}

const ButtonRow = ({ isIpad }: { isIpad?: boolean }) => {
    const navigate = useNavigate();
    return (
        <Flex direction={"row"} mr={"10"} align={"center"}>
            {
                isIpad && (
                    <Box visibility={"hidden"} mr={"16px"} color={"primary"} h={"24px"} w={"24px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </Box>
                )
            }
            <Button visibility={"hidden"} variant={"solid"} size={{ base: "sm", xl: "lg" }} onClick={() => {
                navigate(AppRoutes.login)
            }}>
                Login
            </Button>
            <Button visibility={"hidden"} variant={"outline"} size={{ base: "sm", xl: "lg" }} ml={"16px"} onClick={() => {
                navigate(AppRoutes.signUp)
            }}>
                Sign Up
            </Button>
        </Flex>
    )
}

const TopNavLinks = ({ pages }: Readonly<TopNavBarProps>) => {
    const createNavLink = (page: Page, index: number) => {
        if (page.items !== undefined && !page.hidden) {
            return <TopNavDropDown page={page} key={page.label + " " + index} />
        } else if (page.hidden) {
            return (<Box hidden key={page.label + " " + index}></Box>)
        } else {
            return <TopNavLink hidden={page.hidden} key={page.label + " " + index} label={page.label}
                basePath={page.basePath} />
        }
    }
    return (
        <Flex>
            {
                pages.map(createNavLink)
            }
        </Flex>
    )
}

const TopNavLink = (page: Page) => {
    if (page.hidden) {
        return (<></>)
    }
    return (
        <NavLink to={`${page.basePath}`}>
            {
                ({ isActive }) =>
                    <Flex align={"center"} h={"full"} color={theme.primary} mr={"5"} fontSize={{ xl: "16px", base: "14px" }} fontWeight={"extrabold"} px={'2.5'}
                        borderBottom={"2px"} borderBottomColor={isActive ? theme.primary : "transparent"}>
                        {page.label.toUpperCase()}
                    </Flex>

            }
        </NavLink>
    )
}


export default TopNavBar