export const AppRoutes = {
	home: '/',
	pricing: '/pricing',
	login: '/login',
	signUp: '/signUp',
	quotes: '/quotes',
	editQuotes: '/Quotes/:guid',
	receipt: '/receipt',
	editReceipt: '/Receipts/:guid',
	checkout: '/checkout',
	return: '/return',
	payStub: '/paystub',
	editPaystub: '/Paystubs/:guid',
	editW2: '/W2/:guid',
	invoice: "/invoice",
	wforms: "/w-forms",
	w2: "/w2",
	w4: "/w4",
	w9: "/w9",
	f1099: "/1099",
	edit1099Nec: "/1099/:guid",
	f1099Nec: "/1099-nec",
	f1099Misc: "/1099-misc",
	edit1099Misc: "/1099Misc/:guid",
	f1099K: "/1099-k",
	realEstate: "/real-estate",
	leaseAgreement: "/lease-agreement",
	subleaseAgreement: "/sublease-agreement",
	evictionNotice: "/eviction-notice",
	vehicleBos: "/vehicle-bill-of-sales",
	voidCheck: "/void-check",
	powerAttorney: "/power-of-attorney",
	employmentLetter: "/employment-letter",
	terminationLetter: "/termination-letter",
	truckDriverAgreement: "/truck-driver-Agreement",
	pdfEditor: "/pdfEditor",
	privacyPolicy: "/privacy-policy",
	tAndC: "/terms-and-condition"
};
