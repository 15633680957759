import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IW2, {
    IAddress,
    IEmployee,
    IEmployer,
    IListValue,
    ILocalIncome,
    ITaxCode,
    ITips,
    IW2Tax,
} from "../../Types/Interface/IW2";
import { RootState } from "../index";
import { calculateFedTax, calculateMedicareTax, calculateSocialSecurityTax, calculateStateTax } from "Services/w2Service";
import { toDecimalPlaces } from "Services/utils";
import {v4 as uuidv4} from "uuid";

const taxCodesDEFG = ["D", "E", "F", "G"];

const initialAddress: IAddress = {
    city: "", country: "", state: "AK", stateAddress: "", zipcode: ""
}

const initialEmployer: IEmployer = {
    address: initialAddress, companyName: "", stateId: "", taxId: "", email: ""
}

const initialTax: IW2Tax = {
    federalIncome: undefined, medicare: undefined, socialSecurity: undefined,
    stateIncome: undefined, originalMedicareEntry: undefined, originalSocialSecurityEntry: undefined
}

const initialTaxCode: ITaxCode = {
    amount: undefined, code: ""
}

const initialTips: ITips = {
    allocated: undefined, socialSecurity: undefined, medicareWagesAndTips: undefined
}

const initialLocalIncome: ILocalIncome = {
    income: undefined, name: "", tax: undefined
}

export const initialEmployee: IEmployee = {
    id: `${uuidv4()}`,
    address: initialAddress,
    controlNumber: "",
    email: "",
    dependentCareBenefits: undefined,
    firstName: "",
    hasRetirementPlan: false,
    income: 0,
    medicareWagesAndTips: undefined,
    socialSecurityWages: undefined,
    isStatutory: false,
    lastName: "",
    localIncome: initialLocalIncome,
    maritalStatus: "single",
    others: [],
    receivesSickPay: false,
    ssn: "",
    tax: initialTax,
    taxCodeA: initialTaxCode,
    taxCodeB: initialTaxCode,
    taxCodeC: initialTaxCode,
    taxCodeD: initialTaxCode,
    tips: initialTips,
}

const initialW2: IW2 = {
    formCount: 1, state: "",
    employees: [
        initialEmployee
    ],
    employer: initialEmployer,
    year: "",
    agree: false,
    accept: false,
    downloaded: false,
}

const w2Slice = createSlice({
    name: "w2",
    initialState: initialW2,
    reducers: {
        setW2Data: (sliceState, action: PayloadAction<IW2>) => {
            const { year, employees, employer, formCount, state } = action.payload
            Object.assign(sliceState, { year, employees, employer, formCount, state })
        },
        setW2year: (state, action: PayloadAction<string>) => {
            state.year = action.payload
        },
        setFormCount: (state, action: PayloadAction<number>) => {
            const values = state
            const formCount = action.payload
            let employees
            if (state.employees.length > formCount) {
                // employee form count decrease
                employees = values.employees.slice(0, formCount)
            } else {
                // employee form count increases
                const employee: IEmployee = { ...initialEmployee, id: `${Date.now()}` }
                employees = values.employees.concat(Array<IEmployee>(formCount - state.employees.length).fill(employee))
            }
            state.employees = employees
            state.formCount = action.payload
        },
        setW2State: (state, action: PayloadAction<string>) => {
            state.state = action.payload
        },
        setAcceptTos: (state, action: PayloadAction<boolean>) => {
            state.accept = action.payload
        },
        setAgree: (state, action: PayloadAction<boolean>) => {
            state.agree = action.payload
        },
        setEmployerStateId: (state, action: PayloadAction<string>) => {
            state.employer.stateId = action.payload
        },
        setEmployerCompanyName: (state, action: PayloadAction<string>) => {
            state.employer.companyName = action.payload
        },
        setEmployerTaxId: (state, action: PayloadAction<string>) => {
            state.employer.taxId = action.payload
        },
        setEmployerEmail: (state, action: PayloadAction<string>) => {
            state.employer.email = action.payload
        },
        setEmployerAddress: (state, action: PayloadAction<string>) => {
            state.employer.address.stateAddress = action.payload
        },
        setEmployerZipCode: (state, action: PayloadAction<string>) => {
            state.employer.address.zipcode = action.payload
        },
        setEmployerState: (state, action: PayloadAction<string>) => {
            state.employer.address.state = action.payload
        },
        setEmployerCity: (state, action: PayloadAction<string>) => {
            state.employer.address.city = action.payload
        },
        setEmployeeFirstName: (state, action: PayloadAction<IListValue>) => {
            const employee = state.employees[action.payload.index];
            employee.firstName = action.payload.value
        },
        setEmployeeLastName: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].lastName = action.payload.value
        },
        setEmployeeEmail: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].email = action.payload.value
        },
        setEmployeeAddress: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].address.stateAddress = action.payload.value
        },
        setEmployeeAddressState: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].address.state = action.payload.value
        },
        setEmployeeZipcode: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].address.zipcode = action.payload.value
        },
        setEmployeeCity: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].address.city = action.payload.value
        },
        setEmployeeControlNumber: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].controlNumber = action.payload.value
        },
        setEmployeeIncome: (state, action: PayloadAction<IListValue>) => {
            const income = parseInt(action.payload.value)
            const status = state.employees[action.payload.index].maritalStatus
            const medicareTax = calculateMedicareTax(income);
            const fedTax = calculateFedTax(income, status)
            const socialSecurityTax = calculateSocialSecurityTax(income)
            const stateIncome = calculateStateTax(income, status)

            state.employees[action.payload.index].income = income
            state.employees[action.payload.index].tax.medicare = medicareTax
            state.employees[action.payload.index].tax.originalMedicareEntry = medicareTax
            state.employees[action.payload.index].tax.federalIncome = fedTax
            state.employees[action.payload.index].tax.socialSecurity = socialSecurityTax
            state.employees[action.payload.index].tax.originalSocialSecurityEntry = socialSecurityTax
            state.employees[action.payload.index].tax.stateIncome = stateIncome
        },
        
        setEmployeeFederalTax: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].tax.federalIncome = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeStateTax: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].tax.stateIncome = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeSocialTax: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].tax.originalSocialSecurityEntry = parseFloat(action.payload.value)
            state.employees[action.payload.index].tax.socialSecurity = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeMedicareTax: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].tax.originalMedicareEntry = parseFloat(action.payload.value)
            state.employees[action.payload.index].tax.medicare = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeSocialTips: (state, action: PayloadAction<IListValue>) => {
            const tips = state.employees[action.payload.index].tips
            tips.socialSecurity = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeAllocatedTips: (state, action: PayloadAction<IListValue>) => {
            const tips = state.employees[action.payload.index].tips
            tips.allocated = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeDependentCare: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].dependentCareBenefits = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeTaxCodeACode: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeA.code = action.payload.value
        },
        setEmployeeTaxCodeAAmount: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeA.amount = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeTaxCodeBCode: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeB.code = action.payload.value
        },
        setEmployeeTaxCodeBAmount: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeB.amount = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeTaxCodeCCode: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeC.code = action.payload.value
        },
        setEmployeeTaxCodeCAmount: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeC.amount = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeTaxCodeDCode: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeD.code = action.payload.value
        },
        setEmployeeTaxCodeDAmount: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].taxCodeD.amount = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setRecalculateMedicareAndSocialSecurityTax: (state, action: PayloadAction<number>) => {

            const taxCodeA = state.employees[action.payload].taxCodeA.code;
            const taxCodeB = state.employees[action.payload].taxCodeB.code;
            const taxCodeC = state.employees[action.payload].taxCodeC.code;
            const taxCodeD = state.employees[action.payload].taxCodeA.code;

            let amountA = 0,
                amountB = 0,
                amountC = 0,
                amountD = 0;

            if (taxCodesDEFG.includes(taxCodeA)) {
                amountA = state.employees[action.payload].taxCodeA.amount ?? 0;
            }
            if (taxCodesDEFG.includes(taxCodeB)) {
                amountB = state.employees[action.payload].taxCodeB.amount ?? 0;
            }
            if (taxCodesDEFG.includes(taxCodeC)) {
                amountC = state.employees[action.payload].taxCodeC.amount ?? 0;
            }
            if (taxCodesDEFG.includes(taxCodeD)) {
                amountD = state.employees[action.payload].taxCodeD.amount ?? 0;
            }

            const amount = state.employees[action.payload].income

            state.employees[action.payload].socialSecurityWages = amount + amountA + amountB + amountC + amountD;
            state.employees[action.payload].medicareWagesAndTips = amount + amountA + amountB + amountC + amountD;
        },
        setEmployeeIsStatutory: (state, action: PayloadAction<{ index: number, value: boolean }>) => {
            state.employees[action.payload.index].isStatutory = action.payload.value
        },
        setEmployeeRetirementPlan: (state, action: PayloadAction<{ index: number, value: boolean }>) => {
            state.employees[action.payload.index].hasRetirementPlan = action.payload.value
        },
        setEmployeeSickPay: (state, action: PayloadAction<{ index: number, value: boolean }>) => {
            state.employees[action.payload.index].receivesSickPay = action.payload.value
        },
        setEmployeeSsn: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].ssn = action.payload.value
        },
        setEmployeeMaritalStatus: (state, action: PayloadAction<IListValue>) => {
            let status: "single" | "married" = "single"
            switch (action.payload.value) {
                case "married":
                    status = "married"
            }
            state.employees[action.payload.index].maritalStatus = status

            const income = state.employees[action.payload.index].income
            state.employees[action.payload.index].tax.federalIncome = calculateFedTax(income, status)
            state.employees[action.payload.index].tax.stateIncome = calculateStateTax(income, status)
        },
        setEmployeeLocalIncome: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].localIncome.income = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeLocalTax: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].localIncome.tax = toDecimalPlaces(Number(action.payload.value), 2)
        },
        setEmployeeLocality: (state, action: PayloadAction<IListValue>) => {
            state.employees[action.payload.index].localIncome.name = action.payload.value
        },
        resetW2: () => {
            return initialW2
        },
        setW2Downloaded: (state) => {
            state.downloaded = true
        }
    },
})


export const {
    setW2Data,
    setEmployerEmail,
    setEmployerStateId,
    setEmployerTaxId,
    setEmployerCompanyName,
    setEmployerCity,
    setEmployerZipCode,
    setEmployerAddress,
    setEmployerState,
    setW2year,
    setW2State,
    setFormCount,
    setEmployeeFirstName,
    setEmployeeLastName,
    setEmployeeAddressState,
    setEmployeeZipcode,
    setEmployeeIsStatutory,
    setEmployeeAddress,
    setEmployeeCity,
    setEmployeeControlNumber,
    setEmployeeIncome,
    setEmployeeSocialTax,
    setEmployeeStateTax,
    setEmployeeMedicareTax,
    setRecalculateMedicareAndSocialSecurityTax,
    setEmployeeFederalTax,
    setEmployeeSocialTips,
    setEmployeeAllocatedTips,
    setEmployeeDependentCare,
    setEmployeeTaxCodeACode,
    setEmployeeTaxCodeAAmount,
    setEmployeeTaxCodeBAmount,
    setEmployeeTaxCodeBCode,
    setEmployeeTaxCodeCAmount,
    setEmployeeTaxCodeDCode,
    setEmployeeTaxCodeCCode,
    setEmployeeTaxCodeDAmount,
    setEmployeeRetirementPlan,
    setEmployeeSsn,
    setEmployeeMaritalStatus,
    setEmployeeSickPay,
    setEmployeeLocality,
    setEmployeeLocalIncome,
    setEmployeeLocalTax,
    setEmployeeEmail,
    setAcceptTos, setAgree, resetW2, setW2Downloaded
} = w2Slice.actions

export const selectW2 = (state: RootState): IW2 => state.w2
export const selectW2TaxYear = (state: RootState): string => state.w2.year
export const selectW2TaxState = (state: RootState): string => state.w2.state
export const selectW2TaxFormCount = (state: RootState): number => state.w2.formCount
export const selectEmployees = (state: RootState): IEmployee[] => state.w2.employees
export const selectEmployer = (state: RootState): IEmployer => state.w2.employer
export const selectEmployee = (state: RootState, index: number): IEmployee => state.w2.employees[index]


export default w2Slice.reducer