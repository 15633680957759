import BusinessFormsHeading from "../../../Components/Forms/BusinessFormsHeading";
import { Box, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useAppSelector } from "../../../../Hooks/useAppSelector";
import {
  selectEmployer, setEmployerAddress, setEmployerCity,
  setEmployerCompanyName, setEmployerEmail, setEmployerState, setEmployerStateId,
  setEmployerTaxId, setEmployerZipCode
} from "../../../../Store/Slices/w2Slice";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import { PatternFormat } from "react-number-format";
import TextInput from "Pages/Components/Inputs/TextInput";
import StateDropSelect from "../../../Components/StateDropSelect";
import React from "react"


const prefix = "employer";

const EmployerInfo = () => {
  const employer = useAppSelector(selectEmployer)
  const dispatch = useAppDispatch()

  return (
    <Box mb={"32px"}>
      <BusinessFormsHeading title={"Employer Info"} steps={3} currentStep={2} />
      <Flex gap={"24px"}>
        <TextInput flexBasis={"60%"} isRequired label={"Email"} value={employer.email} onChange={(event) => dispatch(setEmployerEmail(event.target.value))} name={`${prefix}.email`} type={"email"} />

        <FormControl isRequired mt={"20px"} flexBasis={"40%"}>
          <FormLabel>
            Tax Id
          </FormLabel>
          <PatternFormat
            focusBorderColor="primary"
            customInput={Input}
            value={employer.taxId}
            format={"##-#######"}
            onChange={(event) => dispatch(setEmployerTaxId(event.target.value))}
            name={`${prefix}.taxId`}
            type={"text"}
            placeholder={"Ein / Tax Id"} />
        </FormControl>
      </Flex>
      <Flex gap={"24px"}>
        <TextInput isRequired label={"Name"} flexBasis={'60%'} value={employer.companyName} onChange={(event) => dispatch(setEmployerCompanyName(event.target.value))} name={`${prefix}.companyName`} type={"text"} />
        <TextInput isRequired label={"State Id"} flexBasis={"40%"} value={employer.stateId} onChange={(event) => dispatch(setEmployerStateId(event.target.value))} name={`${prefix}.stateId`} type={"text"} />
      </Flex>
      <Flex gap={"24px"}>
        <TextInput isRequired label={"Address"} value={employer.address.stateAddress} onChange={(event) => dispatch(setEmployerAddress(event.target.value))} name={`${prefix}.address.stateAddress`} type={"text"} />
      </Flex>
      <Flex gap={"24px"} align={"end"}>
        <TextInput isRequired label={"City"} value={employer.address.city} onChange={(event) => dispatch(setEmployerCity(event.target.value))} name={`${prefix}.address.city`} type={"text"} />
        <StateDropSelect isRequired placeholder={"State"} notFormik={true} value={employer.address.state} onChange={(event) => dispatch(setEmployerState(event.target.value))} name={`${prefix}.address.state`} label={"State"} />
        <TextInput isRequired maxLength={8} value={employer.address.zipcode} onChange={(event) => dispatch(setEmployerZipCode(event.target.value))} label={"Zip code"} name={`${prefix}.address.zipcode`} type={"text"} />
      </Flex>
    </Box>
  )
}

export default EmployerInfo