import {FormType} from "../Types/Enums/documentTypeEnums";
import {IFormError, IGetFormResponse} from "../Types/Interface/IForm";
import {useCallback, useEffect, useState} from "react";
import {setFormType, setGuid} from "../Store/Slices";
import {useAppDispatch} from "./useAppDispatch";
import {useToast} from "@chakra-ui/react";
import {RenderProps} from "@chakra-ui/react/dist/types/toast/toast.types";
import {renderToast} from "./useToast";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useDebouncedCallback} from "use-debounce";

interface FetchCallback<T> {
    (guid: string): Promise<IGetFormResponse<T>>
}



const useFetchFormData = <T>(fn: FetchCallback<T>, formType: FormType) => {
    const [externalId, setExternalId] = useState("")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<T>()
    const {guid} = useParams()
    const dispatch = useAppDispatch()
    const toast = useToast()

    useEffect(() => {
        if (guid !== undefined && guid.length > 0) {
            setExternalId(guid)
            dispatch(setGuid(guid))
            dispatch(setFormType(formType))
        }
    },[guid])

    const fetchData = useCallback(async () => {
        if (externalId.length > 0) {
            let retry = 0;
            let success = false
            while (!success) {
                try {
                    const responseData = await fn(externalId)
                    success = true
                    setData(responseData.data)
                } catch (e) {
                    ++retry
                    if (retry === 3) {
                        success = true
                        if (axios.isAxiosError<IFormError>(e) && e.response){
                            if (e.response.status === 500){
                                toast({
                                    title: `Internal Server error`,
                                    description: `An error occurred on the server, try again later`,
                                    position: 'top-left',
                                    render: ({ description}: RenderProps) => renderToast("error", description)
                                })
                            } else {
                                const errData = e.response.data
                                for (const i of Object.keys(errData.errors)){
                                    for (const error of errData.errors[i]){
                                        toast({
                                            title: `${i}`,
                                            description: `${error}`,
                                            position: 'top-left',
                                            render: ({ description}: RenderProps) => renderToast("error", description)
                                        })
                                    }
                                }
                            }
                        } else {
                            toast({
                                title: `Unknown Error`,
                                description: `Error: ${e}`,
                                position: 'top-left',
                                render: ({ description}: RenderProps) => renderToast("error", description)
                            })
                        }
                        dispatch(setGuid())
                        dispatch(setFormType(formType))
                    }
                }
            }
        }
    }, [externalId])

    const debouncedFetch = useDebouncedCallback(() => {
        fetchData().finally(() => setLoading(false))
    }, 300)

    useEffect(() => {
        debouncedFetch()
    }, [debouncedFetch])

    return {loading, data}
}


export default useFetchFormData