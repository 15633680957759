import AppLayout from "../../Layouts/AppLayout";
import {Box, Flex} from "@chakra-ui/react";
import { useAppSelector } from "../../Hooks/useAppSelector";
import W2Forms from "./Components/W2Forms";
import W2Preview from "./Components/W2Preview";
import React, {FormEvent, useEffect} from "react";
import {resetW2, selectW2, setW2Data} from "../../Store/Slices/w2Slice";
import { useToast } from "../../Hooks/useToast";
import { AppRoutes } from "../../Routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import { useAppDispatch } from "../../Hooks/useAppDispatch";
import useFormSubmit from "../../Hooks/useFormSubmit";
import submitW2, {getW2} from "../../Services/api/w2";
import PreviewHeader from "../../Components/PreviewHeader";
import NextButtonPreviewBar from "../../Components/NextButtonPreviewBar";
import useMobilePreview from "../../Hooks/useMobilePreview";
import useFetchFormData from "../../Hooks/useFetchFormData";

const W2 = () => {
    const data = useAppSelector(selectW2)
    const { accept, agree, downloaded } = data
    const navigate = useNavigate();
    const { alert } = useToast()
    const dispatch = useAppDispatch()
    const {submit, isSuccess, isLoading} = useFormSubmit(submitW2, FormType.W2)
    const {isNextPage, setIsNextPage} = useMobilePreview()
    const {loading: dataLoading, data: fetchedW2} = useFetchFormData(getW2, FormType.W2)


    useEffect(() => {
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(resetW2())
        }
    }, [downloaded])

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.W2))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.W2))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess]);

    const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        dispatch(setDocumentType(documentTypeEnums.W2))
        if (!agree || !accept) {
            alert("error", "Accept our TOS and agree to not use it for illegal activities")
        } else {
            if (!isLoading){
                submit(data)
            }
        }
    }

    useEffect(() => {
        if  (fetchedW2 !== undefined){
            dispatch(setW2Data(fetchedW2))
        }
    }, [fetchedW2]);

    return (
        <AppLayout isForm loading={dataLoading}>
            <form onSubmit={handleSubmit}>
                <Flex w={"full"} display={{base: "none", lg: "flex" }}  direction={{ base: "column", md: "row" }} px={"50px"} py={"50px"}
                    gap={"32px"} pos={"relative"}>
                    <W2Forms />
                    <W2Preview submit={handleSubmit} />
                </Flex>
                <Box display={{lg: "none" }} px={"16px"} py={"12px"} position={"relative"}>
                    {
                        isNextPage ? (
                            <>
                                <PreviewHeader goBack={()=> setIsNextPage(false)}/>
                                <W2Preview submit={handleSubmit} />
                            </>
                        ) : (
                            <>
                                <W2Forms />
                                <NextButtonPreviewBar formType={FormType.W2} setNextPage={() => setIsNextPage(true)}/>
                            </>
                        )
                    }
                </Box>
            </form>
        </AppLayout>
    )
}

export default W2