import { z } from "zod";

export const QuoteSubmissionSchema = z.object({
    taxAmount: z.number(),
    discountAmount: z.number(),
    discountPercentage: z.number(),
    from: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        address: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            addressLine1: z.string().nonempty({message: "From address must be provided."}),
            addressLine2: z.string(),
            postalCode: z.string().nonempty({message: "From zip code must be provided."}),
            city: z.string().nonempty({message: "From city must be provided."}),
            regionOrState: z.string().nonempty("From state must be provided."),
            country: z.string(),
        }),
        name: z.string().nonempty({message: "From name must be provided."}),
        email: z.string().email({message: "From email invalid."}),
        websiteUrl: z.string(),
        phoneNumber: z.string().nonempty({message: "From Phone number must be provided"}),
        logo: z.string().optional(),
        ein: z.string(),
        taxId: z.string(),
        isContractor: z.boolean(),
    }),
    for: z.object({
        id: z.number(),
        isCompletedForm: z.boolean(),
        address: z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            addressLine1: z.string().nonempty({message: "For address must be provided."}),
            addressLine2: z.string(),
            postalCode: z.string().nonempty({message: "For zip code must be provided."}),
            city: z.string().nonempty({message: "For city must be provided."}),
            regionOrState: z.string().nonempty("For state must be provided."),
            country: z.string(),
        }),
        name: z.string().nonempty({message: "For name must be provided."}),
        email: z.string().email({message: "For email invalid."}),
        websiteUrl: z.string(),
        phoneNumber: z.string().nonempty({message: "For Phone number must be provided"}),
        logo: z.string().optional(),
        ein: z.string(),
        taxId: z.string(),
        isContractor: z.boolean(),
    }),
    lineItems: z.array(
        z.object({
            id: z.number(),
            isCompletedForm: z.boolean(),
            description: z.string().nonempty({message: "A Service description in one of your items is missing."}),
            quantity: z.number().min(1, {message: "Quantity must be a positive integer."}),
            rate: z.number().gt(0, {message: "Rate must be a positive integer."}),
            lineTotal: z.number(),
        })
    ),
    quoteHtmlString: z.string(),
    email: z.string(),
});