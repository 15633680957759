import React, {useEffect} from "react"
import AppLayout from "../../Layouts/AppLayout";
import {useAppSelector} from "../../Hooks/useAppSelector";
import {init1099NecState, resetNecState, select1099NecState} from "../../Store/Slices/1099NecSlice";
import NecForm from "./Components/NecForm";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import useFetchFormData from "../../Hooks/useFetchFormData";
import {FormType} from "../../Types/Enums/documentTypeEnums";
import {get1099Nec} from "../../Services/api/F1099Nec";

const F1099Nec = () => {
    const data = useAppSelector(select1099NecState)
    const dispatch = useAppDispatch()
    const {downloaded} = data
    const {loading: dataLoading, data: fetchedData} = useFetchFormData(get1099Nec, FormType.F1099Nec)


    useEffect(() => {
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(resetNecState())
        }
    }, [downloaded])

  return (
      <AppLayout isForm loading={dataLoading}>
          <NecForm data={fetchedData !== undefined ? fetchedData : data.downloaded ? init1099NecState : data}/>
      </AppLayout>
  )
}

export default F1099Nec