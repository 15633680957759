import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import {IGetPaystubData, IPaystubData} from "../../Types/Interface/IPaystub";
import {
    convertNumToPaySchedule,
    DeductionType,
    MaritalStatus,
    PaymentMode,
    paymentScheduleInfo
} from "../../Types/Enums/PaymentModeEnum";
import {reqClient} from "../../Api/reqClient";
import {PaystubSchema} from "../../schemas/PaystubSchema";
import {v4 as uuidv4} from "uuid";
import {generateDeduction, generateEarning} from "../payStubUtils";
import {format} from "date-fns";

const submitPaystub = async (data: IPaystubData, guid?: string): Promise<IFormResponse> => {
    const id = guid ?? "00000000-0000-0000-0000-000000000000"
    const url = `/paystubs/${id}`
    const body = {
        payStubDto: {
            id: 0,
            isCompletedForm: true,
            externalId: guid,
            company: {
                ...data.company,
                id: 0,
                isCompletedForm: true,
                address: {
                    id: 0,
                    addressLine1: data.company.address,
                    addressLine2: "",
                    postalCode: data.company.zipcode,
                    city: data.company.city,
                    regionOrState: data.company.state,
                    country: "united state",
                    isCompletedForm: true,
                },
                websiteUrl: "",
                phoneNumber: "",
                ein: "",
                taxId: "",
                isContractor: data.employee.isContractor
            },
            employee: {
                id: 0,
                name: data.employee.name,
                firstName: data.employee.name,
                lastName: "",
                middleName: "",
                addressLine1: data.employee.address,
                zip: data.employee.zipcode,
                city: data.employee.city,
                state: data.employee.taxState,
                ssn: data.employee.ssn,
                employeeNumber: data.employee.eid,
                role: "",
                email: "",
                phoneNumber: "",
                isMarried: data.employee.maritalStatus === MaritalStatus.Married
            },
            schedule: paymentScheduleInfo[data.info.paySchedule].schedule,
            paymentMode: data.info.paymentMode === PaymentMode.Hourly ? 0 : 1,
            hourlyRate: PaymentMode.Hourly === data.info.paymentMode ? data.info.hourlyRate : data.info.salary,
            earnings: data.payStubs.map((payStub, index) => {
                    const medicare = payStub.deductions.find((deduction) => deduction.type === DeductionType.Medicare)
                    const socialSecurity = payStub.deductions.find((deduction) => deduction.type === DeductionType.SocialSecurity)
                    const incomeFedTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.FederalTax)
                    const stateTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.StateTax)
                    return {
                        id: index,
                        isCompletedForm: true,
                        payDate: new Date(payStub.payDate).toISOString(),
                        from: new Date(payStub.from).toISOString(),
                        to: new Date(payStub.to).toISOString(),
                        exemption_Allowance: 0, // we don't have an input for this
                        totalHours: data.info.paymentMode === PaymentMode.Hourly ? payStub.earnings[0].hours : 1,
                        paymentSchedule: paymentScheduleInfo[data.info.paySchedule].schedule,
                        paymentMode: data.info.paymentMode === PaymentMode.Hourly ? 0 : 1,
                        hourlyRate: data.info.paymentMode === PaymentMode.Hourly ? payStub.earnings[0].rate : payStub.earnings[0].total,
                        additionalEarnings: payStub.earnings.slice(1).map((earning) => (
                            {
                                id: 0,
                                description: earning.description,
                                rate: earning.rate,
                                totalHoursOrQuantity: earning.hours,
                                earningStubId: index,
                                employeeId: 0
                            }
                        )),
                        customDeductions: payStub.deductions.slice(4).map((deduction) => (
                            {
                                id: 0,
                                isCompletedForm: true,
                                rate: deduction.amount,
                                hours: 1,
                                earningStubId: index,
                                description: deduction.description,
                            }
                        )),
                        medicare: medicare?.amount,
                        stateTax: stateTax?.amount,
                        stubDetailId: 0,
                        socialSecurity: socialSecurity?.amount,
                        federalTax: incomeFedTax?.amount,
                        exemption_allowance: 0,
                        adviceNumber: "advice", // we don't have an input for this
                    }
                }
            )
        }
    }

    const parsedData = PaystubSchema.parse(body)
    const response = await reqClient.post(url, parsedData)
    return response.data
}

export const getPaystub = async (guid: string): Promise<IGetFormResponse<IPaystubData>> => {
    const url = `/paystubs/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGetPaystubData> = response.data
    const transformedData: IPaystubData = {
        accept: false,
        agree: false,
        company: {
            name: data.company.name,
            email: data.company.email,
            address: data.company.address.addressLine1,
            state: data.company.address.regionOrState,
            zipcode: data.company.address.postalCode,
            city: data.company.address.city,
        },
        employee: {
            address: data.employee.addressLine1,
            city: data.employee.city,
            eid: data.employee.employeeNumber,
            hireDate: "",
            isContractor: data.company.isContractor,
            isNewHire: false,
            maritalStatus: data.employee.isMarried ? MaritalStatus.Married : MaritalStatus.Single,
            name: data.employee.name,
            ssn: data.employee.ssn,
            taxState: data.employee.state,
            zipcode: data.employee.zip
        },
        info: {
            currentPage: 0,
            dateBufferStatus: "initial",
            hourlyRate: data.paymentMode === 0 ? data.hourlyRate : 20,
            paySchedule: convertNumToPaySchedule(data.schedule),
            payStubCount: data.earnings.length,
            paymentMode: data.paymentMode === 0 ? PaymentMode.Hourly : PaymentMode.Salary,
            salary: data.paymentMode === 0 ? 48000 : data.hourlyRate,
            taxState: data.employee.state
        },
        payStubs: data.earnings.map((stub) => {
            return {
                id: uuidv4(),
                from: format(stub.from, "yyyy-MM-dd"),
                to: format(stub.to, "yyyy-MM-dd"),
                payDate: format(stub.payDate, "yyyy-MM-dd"),
                enableManualTax: false,
                grossPay: {
                    amount: 0,
                    ytd: 0
                },
                netPay: 0,
                check: 0,
                netCheck: 0,
                earnings: [
                    generateEarning({
                        hours: data.paymentMode === 0  ? stub.totalHours : undefined,
                        rate: data.paymentMode === 0 ? stub.hourlyRate : undefined,
                        total: data.paymentMode === 0 ? (stub.totalHours ?? 1) * (stub.hourlyRate ?? 1) : stub.hourlyRate === undefined ? (48000/52) : stub.hourlyRate,
                        description: data.paymentMode === 0 ? "Regular" : "Salary",
                        type: data.paymentMode === 0 ? PaymentMode.Hourly : PaymentMode.Salary,
                        ytd: 0,
                    }),
                    ...stub.additionalEarnings.map((additionalEarning) => {
                        return generateEarning({
                            description: additionalEarning.description,
                            rate: additionalEarning.rate,
                            hours: additionalEarning.totalHoursOrQuantity,
                            type: PaymentMode.Hourly,
                            ytd: 0,
                            total: 0,
                        })
                    })
                ],
                deductions: [
                    generateDeduction({
                        description: "Federal Tax",
                        type: DeductionType.FederalTax,
                        amount: stub.federalTax,
                        ytd: 0
                    }),
                    generateDeduction({
                        description: "FICA - Social Security",
                        type: DeductionType.SocialSecurity,
                        amount: stub.socialSecurity,
                        ytd: 0
                    }),
                    generateDeduction({
                        description: "FICA - Medicare",
                        type: DeductionType.Medicare,
                        amount: stub.medicare,
                        ytd: 0
                    }),
                    generateDeduction({
                        description: "State Tax",
                        type: DeductionType.StateTax,
                        amount: stub.stateTax,
                        ytd: 0
                    }),
                    ...stub.customDeductions.map((deduction) => {
                        return generateDeduction({
                            description: deduction.description,
                            type: DeductionType.AdditionalDeduction,
                            ytd: 0,
                            amount: deduction.rate
                        })
                    })
                ]
            }
        })
    }
    return {isSuccess, data: transformedData, errorMessage}
}


export default submitPaystub