import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text, Wrap, WrapItem
} from "@chakra-ui/react";
import React, {ChangeEvent, useEffect, useState} from "react"
import {IEarning, IPayStub, IPaystubInfo} from "../../../Types/Interface/IPaystub";
import AdditionalEarnings from "./Forms/AdditionalEarnings";
import CustomDeductions from "./Forms/CustomDeductions";
import {DeductionType, PaymentMode} from "../../../Types/Enums/PaymentModeEnum";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";
import {
    setEnableManualTax,
    setFederalTax,
    setPayStubEndDate,
    setPayStubHours,
    setPayStubPayDate,
    setPayStubStartDate,
    setStateTax
} from "../../../Store/Slices/paystubSlice";
import {useToast} from "../../../Hooks/useToast";

type HandleChange = (e: ChangeEvent) => void
type SetFieldValue = (field: string, value: string | boolean | number | IEarning[] | IEarning) => void

type BaseInputProp = {
  index: number;
  value?: number | string,
  isChecked?: boolean,
  handleChange?: HandleChange,
  isDisabled?: boolean
}

type DateInputProp = BaseInputProp & {
  payDate: string
}


const PeriodicPayStubSection = ({ paystubs, handleChange, setFieldValue, info }: { paystubs: IPayStub[], info: IPaystubInfo, handleChange: HandleChange, setFieldValue: SetFieldValue }) => {

  const dispatch = useAppDispatch()

  return (
    <>
      {
        paystubs.map((paystub, index) => (
          <Box key={paystub.id} p={{base: "0px", md: "32px"}} mb={'24px'} borderRadius={"8px"} borderWidth={{base: "0px", md: "1px"}} borderColor={"#E2E4E5"}>
            <PeriodicPayStubHeader index={index} />
            <Wrap gap={{base: "12px", md: "48px"}} my={"32px"} justify={"space-between"}>
              <PayDateStartInput value={paystub.from} index={index} />
              <PayDateEndInput value={paystub.to} index={index} handleChange={handleChange} />
            </Wrap>
            <Wrap gap={{base: "12px", md: "48px"}} justify={"space-between"}>
              <PayDateInput value={paystub.payDate} index={index} handleChange={handleChange} isDisabled={!paystub.to} payDate={paystub.to} />
              {
                paystub.earnings[0].type === PaymentMode.Hourly && <TotalHoursSelector disabled={info.dateBuffer !== undefined} value={paystub.earnings[0].hours} mode={paystub.earnings[0].type} index={index} setFieldValue={setFieldValue} />
              }
            </Wrap>
            <Flex>
              <FormControl mt={"40px"}>
                <Checkbox color={"#208c55"} disabled={info.dateBuffer !== undefined} onChange={() => dispatch(setEnableManualTax({ setFederalStateTaxManually: !paystub.enableManualTax, payStubIndex: index }))} isChecked={paystub.enableManualTax} name={`employee.isContractor`} fontSize={"18px"}>Edit Tax</Checkbox>
              </FormControl>
            </Flex>
            <Flex gap={"48px"} mt={"15px"}>
              <FormControl>
                <FormLabel color={"#208c55"}>
                  Federal Tax
                </FormLabel>
                <Input value={paystub.deductions.filter(deduction => deduction.type === DeductionType.FederalTax)[0]?.amount?.toFixed(2) ?? ""}
                  onChange={(e) => {
                    const amount = e.target.value.length !== 0 ? Number(e.target.value) : 0

                    dispatch(setFederalTax({ amount, payStubIndex: index }))
                  }}
                  name={`payStubs[${index}].deductions[${index + 1}].FederalTax`}
                  type={"number"}
                  disabled={!paystub.enableManualTax} />
              </FormControl>
              <FormControl>
                <FormLabel color={"#208c55"}>
                  State Tax
                </FormLabel>
                <Input value={paystub.deductions.filter(deduction => deduction.type === DeductionType.StateTax)[0]?.amount?.toFixed(2) ?? ""}
                  onChange={(e) => {
                    dispatch(setStateTax({ amount: Number(e.target.value), payStubIndex: index }))
                  }}
                  name={`payStubs[${index}].earnings[${index + 1}].StateTax`}
                  type={"number"}
                  disabled={!paystub.enableManualTax} />
              </FormControl>
            </Flex>

            <AdditionalEarnings index={index} payStubs={paystubs} setFieldValue={setFieldValue} earnings={paystub.earnings.slice(1)} handleChange={handleChange} />
            <CustomDeductions index={index} deductions={paystub.deductions.slice(4)} />
          </Box>
        ))
      }
    </>
  )
}

const PeriodicPayStubHeader = ({ index }: { index: number }) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Text fontSize={"24px"} fontWeight={"semibold"} color={"#208c55"}>
        PayStub {index + 1}
      </Text>
    </Flex>
  )
}

const PayDateStartInput = ({ value, index }: BaseInputProp) => {
    const dispatch = useAppDispatch();
    const {validate} = useDateValidator({errorMsg: `Pay date not valid, input a valid Start date for paystub ${index + 1}`})

    useEffect(() => {
        if (value){
            validate(value)
        }
    }, [value]);

    return (
    <WrapItem w={"47%"}>
        <FormControl flexBasis={"100%"}>
            <FormLabel>
                Pay Date Start
            </FormLabel>
            <Input
                value={value}
                onChange={(e) => {
                    dispatch(setPayStubStartDate({ date: e.target.value, stubIndex: index }))
                }
                }
                name={`payStubs[${index}].from`}
                type={"date"} />
        </FormControl>
    </WrapItem>
  )
}

const PayDateEndInput = ({  value, index }: BaseInputProp) => {
  const dispatch = useAppDispatch()
    const {validate} = useDateValidator({errorMsg: `Pay date not valid, input a valid End date for paystub ${index + 1}`})

    useEffect(() => {
        if (value){
            validate(value)
        }
    }, [value]);
  return (
   <WrapItem w={"47%"}>
       <FormControl>
           <FormLabel>
               Pay Date End
           </FormLabel>
           <Input
               value={value}
               onChange={(e) => {
                   dispatch(setPayStubEndDate({ date: e.target.value, stubIndex: index }))
               }
               }
               name={`payStubs[${index}].to`}
               type={"date"} />
       </FormControl>
   </WrapItem>
  )
}

const PayDateInput = ({  value, index, isDisabled, payDate }: DateInputProp) => {
  const dispatch = useAppDispatch()
    const [currentValue, setCurrentValue] = useState(value)
  const currentPayDate = new Date(payDate); // Get the current date
  const minPayDate = new Date(currentPayDate); // Clone the date
  minPayDate.setDate(currentPayDate.getDate() + 1); // Add 1 day
    const {validate} = useDateValidator({errorMsg: `Pay date not valid, input a valid Pay date for paystub ${index + 1}`})

  return (
      <WrapItem w={"47%"}>
          <FormControl>
              <FormLabel>
                  Pay Date
              </FormLabel>
              <Input value={currentValue === undefined ? currentValue : value}
                     onChange={(e) => {
                         if (validate(e.target.value)){
                             setCurrentValue(e.target.value)
                             dispatch(setPayStubPayDate({ date: e.target.value, stubIndex: index }))
                         } else {
                             setCurrentValue(undefined)
                         }
                     }
                     }
                     name={`payStubs[${index}].payDate`}
                     type={"date"}
                     isDisabled={isNaN(minPayDate.getTime()) ? true : isDisabled}
                     min={isNaN(minPayDate.getTime()) ? new Date().toISOString().split("T")[0] : minPayDate.toISOString().split("T")[0]}
              />
          </FormControl>
      </WrapItem>
  )
}

const TotalHoursSelector = ({ value, index, mode, disabled }: BaseInputProp & { setFieldValue: SetFieldValue, mode: PaymentMode, disabled: boolean }) => {
  const dispatch = useAppDispatch()
  return (
    <WrapItem w={"47%"}>
        <FormControl isRequired>
            <FormLabel>
                Total Hours
            </FormLabel>
            <NumberInput isRequired={mode === PaymentMode.Hourly} value={value} isDisabled={mode === PaymentMode.Hourly? disabled : true} onChange={(valueString) => dispatch(setPayStubHours({ stubIndex: index, hours: valueString.length > 0 ? parseInt(valueString) : 0 }))}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </FormControl>
    </WrapItem>
  )
}

const useDateValidator = ({ errorMsg}: {errorMsg: string}) => {
    const {alert} = useToast()
    const validate = (value: string | Date | number) => {
        if (isNaN(new Date(value).getTime())){
            alert("error", errorMsg)
            return false
        }
        return true
    }
    return {validate}
}

export default PeriodicPayStubSection