import { reqClient } from "Api/reqClient"
import { useCallback, useEffect, useState } from "react"
import IW2 from "Types/Interface/IW2"
import { useDebouncedCallback } from "use-debounce";


export function transformW2Data(w2Data: IW2) {
    return {
        "dto": {
            "taxYear": w2Data.year,
            "numberOfForms": w2Data.formCount,
            "employerInfo": {
                "name": w2Data.employer.companyName,
                "email": w2Data.employer.email,
                "addressLine1": w2Data.employer.address.stateAddress,
                "city": w2Data.employer.address.city,
                "addressLine2": "",
                "zipCode": w2Data.employer.address.zipcode,
                "phoneNumber": "",
                "logo": "",
                "imageFilePath": "",
                "imageFileName": "",
                "ein": w2Data.employer.taxId,
                "stateTaxEinId": w2Data.employer.taxId,
                "controlNumber": w2Data.employees[0].controlNumber,
                "state": w2Data.employer.address.state,
                "employerStateIdNumber": w2Data.employer.stateId
            },
            "employee": {
                "firstName": w2Data.employees[0].firstName,
                "lastName": w2Data.employees[0].lastName,
                "addressLine1": w2Data.employees[0].address.stateAddress,
                "addressLine2": "",
                "zip": w2Data.employees[0].address.zipcode,
                "city": w2Data.employees[0].address.city,
                "state": w2Data.employees[0].address.state,
                "ssn": w2Data.employees[0].ssn,
                "employeeNumber": "",
                "email": w2Data.employees[0].email,
                "isMarried": w2Data.employees[0].maritalStatus === "married",
                "annualIncome": w2Data.employees[0].income ?? 0,
                "dependentCareBenefits": w2Data.employees[0].dependentCareBenefits ?? 0,
                "socialSecurityTips": w2Data.employees[0].tips.socialSecurity ?? 0,
                "socialSecurityWages": w2Data.employees[0].socialSecurityWages ?? 0,
                "medicareWagesAndTips": w2Data.employees[0].medicareWagesAndTips ?? 0,
                "allocatedTips": w2Data.employees[0].tips.allocated ?? 0,
                "statutoryEmployee": w2Data.employees[0].isStatutory ? "On" : "Off",
                "retirementPlan": w2Data.employees[0].hasRetirementPlan ? "On" : "Off",
                "thirdPartySickPay": w2Data.employees[0].receivesSickPay ? "On" : "Off",
                "taxCode12A": w2Data.employees[0].taxCodeA.code,
                "taxCode12AValue": w2Data.employees[0].taxCodeA.amount ?? 0,
                "taxCode12B": w2Data.employees[0].taxCodeB.code,
                "taxCode12BValue": w2Data.employees[0].taxCodeB.amount ?? 0,
                "taxCode12C": w2Data.employees[0].taxCodeC.code,
                "taxCode12CValue": w2Data.employees[0].taxCodeC.amount ?? 0,
                "taxCode12D": w2Data.employees[0].taxCodeD.code,
                "taxCode12DValue": w2Data.employees[0].taxCodeD.amount ?? 0,
                "localWagesTips": w2Data.employees[0].localIncome.income ?? 0,
                "localIncomeTax": w2Data.employees[0].localIncome.tax ?? 0,
                "wagesTipsOtherCompensation": w2Data.employees[0].income ?? 0,
                "tax": {
                    "federalIncomeTax": w2Data.employees[0].tax.federalIncome,
                    "socialSecurityTax": w2Data.employees[0].tax.socialSecurity,
                    "medicareTax": w2Data.employees[0].tax.medicare,
                    "stateIncomeTax": w2Data.employees[0].tax.stateIncome ?? 0.00
                },
                "suffix": "",
                "initials": "",
                "zipCode": w2Data.employees[0].address.zipcode,
                "stateWagesTips": w2Data.employees[0].income ?? 9,
                "stateIncomeTax": w2Data.employees[0].tax.stateIncome ?? 0.00,
                "localityName": w2Data.employees[0].localIncome.name,
                "controlNumber": w2Data.employees[0].controlNumber,
            }
        }
    };
}

const useFillW2 = (w2Data: IW2) => {
    const [data, setData] = useState<File>()
    const [loading, setLoading] = useState(true);
    const refreshData = useCallback(async () => {
        setLoading(true)
        try {
          
            const fData = await reqClient.post("/fillW2", transformW2Data(w2Data), { responseType: "blob" })

            setData(fData.data)
        } catch (e) {
        }
        setLoading(false)
    }, [w2Data.employees, w2Data.employer.address.state, w2Data.employer.address.stateAddress, w2Data.employer.address.zipcode, w2Data.employer.companyName, w2Data.employer.email, w2Data.employer.stateId, w2Data.employer.taxId, w2Data.formCount, w2Data.year])

    const debounce = useDebouncedCallback(
        refreshData, 2000
    )

    useEffect(() => {
        debounce()
    }, [debounce, refreshData])

    return { data, refreshData, loading }
}

export default useFillW2