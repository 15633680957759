import AppLayout from "../../Layouts/AppLayout";
import {Box, Flex} from "@chakra-ui/react";
import QuoteForms from "./Components/QuoteForms";
import QuotePreview from "./Components/QuotePreview";
import { Form, Formik } from "formik";
import { IAddress, IQuotes, ISender } from "../../Types/Interface/IQuotes";
import { useAppSelector } from "../../Hooks/useAppSelector";
import {resetDocumentState, resetLogo, setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import { AppRoutes } from "../../Routes/AppRoutes";
import { useAppDispatch } from "../../Hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../Hooks/useToast";
import React, {useEffect} from "react";
import useFormSubmit from "../../Hooks/useFormSubmit";
import submitQuote, {getQuote} from "../../Services/api/quote";
import PreviewHeader from "../../Components/PreviewHeader";
import NextButtonPreviewBar from "../../Components/NextButtonPreviewBar";
import useMobilePreview from "../../Hooks/useMobilePreview";
import useFetchFormData from "../../Hooks/useFetchFormData";
import {generateQuoteId} from "../../Services/utils";

const initialAddressValues: IAddress = {
    addressLine1: "", addressLine2: "", city: "", country: "", id: 0, postalCode: "", regionOrState: "AK"
}

const initialSenderValues: ISender = {
    address: initialAddressValues, email: "", id: 0, name: "", phoneNumber: "", websiteUrl: "", logo: ""
}

const initialReceiver: ISender = {
    address: initialAddressValues, email: "", id: 0, name: "", phoneNumber: "", websiteUrl: "",
}

const initialQuoteValues: IQuotes = {
    dueDate: "",
    quoteId: generateQuoteId(),
    discountAmount: 0,
    discountPercentage: 0,
    email: "",
    for: initialReceiver,
    from: initialSenderValues,
    id: 0,
    lineItems: [
        { id: 0, description: "", lineTotal: 0, quantity: 0, rate: 0 },
    ],
    logoUrl: "",
    quoteHtmlString: "",
    taxAmount: 0
}

const Quotes = () => {
    const navigate = useNavigate();
    const { alert } = useToast()
    const downloaded = useAppSelector((state) => state.document.downloaded)
    const data = useAppSelector((state) => state.document.documentData.quote) ?? initialQuoteValues;
    const dispatch = useAppDispatch();
    const {submit, isSuccess, isLoading} = useFormSubmit(submitQuote, FormType.Quotes)
    const {isNextPage, setIsNextPage} = useMobilePreview()
    const {loading: dataLoading, data: fetchedQuote} = useFetchFormData(getQuote, FormType.Quotes)



    useEffect(() => {
        dispatch(resetLogo())
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(resetDocumentState())
        }
    }, [])

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.Quotes))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.Quotes))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess]);

    const handleSubmit = (values: IQuotes,) => {
        if (isNextPage){
            handleMobileSubmit()
            return;
        }
        if (!values.agree || !values.accept) {
            alert("error", "Please agree or accept to our Terms")
        } else if (data !== undefined && Object.keys(data).length > 0) {
            if (!isLoading){
                submit(values)
            }
        }
    }

    const handleMobileSubmit = () => {
        if (!data.agree || !data.accept) {
            alert("error", "Please agree or accept to our Terms")
        } else if (Object.keys(data).length > 0) {
            if (!isLoading){
                submit(data)
            }
        }
    }

    return (
        <AppLayout isForm loading={dataLoading}>
            <Formik<IQuotes> initialValues={fetchedQuote !== undefined ? fetchedQuote : downloaded ? initialQuoteValues : data} validateOnBlur={false} validateOnChange={false} validateOnMount={false} onSubmit={handleSubmit}>
                {
                    () => (
                        <Form>
                            <Flex w={"full"} display={{base: "none", lg: "flex" }} direction={{ base: "column", lg: "row" }} px={"50px"} py={"50px"} gap={"32px"} pos={"relative"}>
                                <QuoteForms />
                                <QuotePreview />
                            </Flex>
                            <Box display={{lg: "none" }} px={"16px"} position={"relative"}>
                                {
                                    isNextPage ? (
                                        <>
                                            <PreviewHeader goBack={()=> setIsNextPage(false)}/>
                                            <QuotePreview submit={handleMobileSubmit} />
                                        </>
                                    ) : (
                                        <>
                                            <QuoteForms />
                                            <NextButtonPreviewBar formType={FormType.Quotes} setNextPage={() => setIsNextPage(true)}/>
                                        </>
                                    )
                                }
                            </Box>
                        </Form>
                    )
                }
            </Formik>
        </AppLayout>
    )
}

export default Quotes