import React, { ChangeEvent } from "react"
import BusinessFormsHeading from "../../Components/Forms/BusinessFormsHeading";
import { Box, Flex, Wrap, WrapItem, FormControl, FormLabel, Input } from "@chakra-ui/react";
import TextInput from "../../Components/Inputs/TextInput";
import StateDropSelect from "../../Components/StateDropSelect";
import { IPayer } from "../validator";
import { PatternFormat } from "react-number-format";

const NecPayerForm = ({ handleChange, payer }: { handleChange: (e: ChangeEvent) => void, payer: IPayer }) => {
    return (
        <>
            <BusinessFormsHeading title={"Payer' Info"} currentStep={2} steps={3} />
            <Box mb={"56px"}>

                <Wrap gap={"42px"} justify={'space-between'}>
                    <WrapItem w={{base: "100%", md: "55%"}} >
                        <TextInput isRequired flexBasis={"100%"} value={payer.name} label={"Payer's Name"} onChange={handleChange} name={"payer.name"} />
                    </WrapItem>
                    <WrapItem  w={{base: "100%", md: "40%"}}>
                        <FormControl isRequired mt={"20px"} flexBasis={"100%"}>
                            <FormLabel>
                                {"Payer's Tin(Company EiN)"}
                            </FormLabel>
                            <PatternFormat
                                focusBorderColor="primary"
                                customInput={Input}
                                value={payer.tin}
                                format={"##-#######"}
                                onChange={handleChange}
                                name={"payer.tin"}
                                type={"text"}
                                placeholder={"Ein / Tax Id"} />
                        </FormControl>
                    </WrapItem>
                    {/*<TextInput isRequired flexBasis={"40%"} value={payer.tin} label={"Payer's Tin(Company EiN"} onChange={handleChange} name={"payer.tin"} />*/}
                </Wrap>
                <Wrap>
                    <WrapItem w={{base: "100%", md: "60%"}}>
                        <TextInput isRequired flexBasis={"100%"} value={payer.address.stateAddress} label={"Address"} onChange={handleChange} name={"payer.address.stateAddress"} />
                    </WrapItem>
                </Wrap>
                <Wrap gap={"42px"} justify={'space-between'}>
                    <WrapItem  w={{base: "100%", md: "35%"}}>
                        <TextInput isRequired flexBasis={"100%"} value={payer.address.city} label={"City"} onChange={handleChange} name={"payer.address.city"} />
                    </WrapItem>
                    <WrapItem w={{base: "50%", md: "30%"}}>
                        <StateDropSelect isRequired flexBasis={"100%"} name={"payer.address.state"} value={payer.address.state} label={"State"} onChange={handleChange} notFormik={true} />
                    </WrapItem>
                    <WrapItem w={{base: "45%", md: "30%"}}>
                        <TextInput isRequired flexBasis={"100%"} value={payer.address.zipcode} maxLength={8} label={"Zip Code"} onChange={handleChange} name={"payer.address.zipcode"} />
                    </WrapItem>
                </Wrap>
            </Box>
        </>
    )
}

export default NecPayerForm