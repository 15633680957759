import React from "react"
import AppLayout from "../../Layouts/AppLayout";
import {Box, Flex, Link, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import useDeviceQuery, {ScreenType} from "../../Hooks/useDeviceQuery";


const TermsAndCondition = () => {
    const device = useDeviceQuery()
    return (
        <AppLayout>
            <Box py={device === ScreenType.Iphone ? "64px" : "80px"}>
                <Flex
                    fontWeight={"800"}
                    px={{md: "72px", lg: "111px"}}
                    justifyContent={{base: "center", md: "start"}}
                    alignItems={"center"}
                    h={{base: "106px", md: "120px", lg: "157px"}}
                    textTransform={"uppercase"}
                    fontSize={{base: "36px", md: "40px", lg: "48px"}}
                    color={"white"}
                    bgGradient={{
                        base: "linear(to-r, #1fbc6c, #1fbc6c)",
                        md: "linear(to-r, #1FBC6C 0%, #7DDCAC 123.76%, #C6F6DE 162.58%)"}}>
                    Terms and Condition
                </Flex>
                <Box  px={{base: "24px", md: "50px", lg: "155px"}} mt={"40px"} textTransform={"uppercase"}>

                    <Text fontSize={"20px"} fontWeight={"700"} mb={{base: "40px", md: "8px"}} textAlign={{base: "center", md: "start"}}>
                        Last Updated: 03/17/2025
                    </Text>
                    <Box px={{md: "60px", lg: "72px "}} lineHeight={"51px"} fontWeight={"18px"}>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            1. Introduction
                        </Text>
                        <Text>
                            Welcome to PHCworkhub, a document-creating and generating company. By using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            2. Definitions
                        </Text>
                        <UnorderedList px={{md: "24px"}}>
                            <ListItem>
                                &#34;PHCworkhub,&#34; &#34;we,&#34; &#34;us,&#34; &#34;our,&#34; “PHC”: Refers to the document-generating company, PHCworkhub.
                            </ListItem>
                            <ListItem>
                                &#34;User,&#34; &#34;you,&#34; &#34;your&#34;: Refers to the individual or entity using our services.
                            </ListItem>
                            <ListItem>
                                &#34;Services&#34;: Refers to the document generation services provided by PHCworkhub.
                            </ListItem>
                            <ListItem>
                                &#34;Website&#34;: Refers to our website, including all web pages, subdomains, and related services.
                            </ListItem>
                            <ListItem>
                                &#34;Software&#34;: Refers to our website, including all web pages, subdomains, and related services.
                            </ListItem>
                            <ListItem>
                                &#34;Software&#34;: Refers to our website, including all web pages, subdomains, and related services.
                            </ListItem>
                            <ListItem>
                                &#34;Account&#34;: The means by which PHCworkhub authorizes access to the &#34;Services&#34; (defined herein) through the purchase of and/or establishment of future payment(s);
                            </ListItem>
                        </UnorderedList>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            3. Acceptance of Terms
                        </Text>
                        <Text>
                            By accessing or using our website or service, you agree to be bound by these Terms and Conditions and our Privacy Policy. These Terms constitute the entire, complete, and binding agreement between you and Phcworkhub. Users should review our Privacy Policy, available on our website, as it is incorporated by reference into these Terms.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"} my={"16px"}>
                            If you do not agree with these terms, please do not use our website, software, or service.
                        </Text>
                        <Text>
                            These Terms govern the relationship between Phcworkhub and the User using the Website and Software. The Software is available to you via the Website on both computer and mobile devices.
                        </Text>

                        <Text fontSize={"20px"} fontWeight={"700"}>
                            4. Changes to Terms
                        </Text>
                        <Text>
                            PHCworkhub reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on the Website, and you waive any right to receive specific notice on the change. It is your responsibility to review these Terms regularly. If you disagree with any part of these Terms or any updated version, you must stop using the website and service immediately. Your continued use of the Services after any such changes constitutes your acceptance of the new terms.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            5. Use of Services
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            5.1 Eligibility
                        </Text>
                        <Text>
                            You must be at least 18 years old and capable of entering into legally binding contracts to use our Services.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            5.2 Usage
                        </Text>
                        <Text>
                            You must not use the service to create fraudulent or misleading documents, as the website is intended for use by natural persons and legal entities to create invoices and other documents that may be offered from time to time. Any other use, particularly misuse, is strictly prohibited. By using the Software, you agree not to:
                        </Text>
                        <UnorderedList px={{md: "24px"}}>
                            <ListItem>
                                Create or edit Documents containing unauthorized or restricted information.
                            </ListItem>
                            <ListItem>
                                Impersonate any individual or organization or misrepresent your affiliation with any person or entity.
                            </ListItem>
                            <ListItem>
                                Attempt to reverse engineer, disassemble, decompile, hack, or extract any proprietary components of the Software.
                            </ListItem>
                            <ListItem>
                                Transmit or distribute content you are not authorized to share, including files containing viruses, malware, or any harmful code that could damage or disrupt software, hardware, or communication systems.
                            </ListItem>
                            <ListItem>
                                Disrupt or interfere with the operation of the Software, its servers, or networks, including any attempts to hack or bypass security protocols.
                            </ListItem>
                            <ListItem>
                                Falsify or misrepresent information, including creating fraudulent or deceptive Documents.
                            </ListItem>
                            <ListItem>
                                Violate any applicable laws or regulations or infringe upon the rights of third parties.
                            </ListItem>
                        </UnorderedList>
                        <Text fontSize={"18px"} fontWeight={"500"} fontStyle={"italic"}>
                            5.2.1 Consequences of Violation
                        </Text>
                        <Text>
                            Any misuse or violation of these permitted uses will be considered a material breach of these Terms. PHCWorkHub reserves the right to immediately terminate your access to the Website. Upon termination, you are prohibited from further access to the Website. PHCWorkHub may also pursue additional remedies or legal action as deemed appropriate.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            5.3 Account Registration
                        </Text>
                        <Text>
                            To access certain features of our Services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            5.4 Payment Terms, and Upgrades
                        </Text>
                        <Text>
                            You agree to pay all fees for the Services you use in accordance with the pricing and payment terms presented to you at the time of purchase.
                            You can sign up for a free trial account lasting seven (7) days by providing a valid credit card, which will not be charged during the trial period. If you do not cancel the service at the end of the free trial, your credit card will automatically be charged for the service on a monthly basis. Paid accounts are billed in advance each month on the same date and are non-refundable.
                            Upgrading from a free trial to a paid account will immediately terminate your free trial, and your credit card will be charged for the first month of service. All fees are exclusive of taxes or duties imposed by taxing authorities, and you are responsible for paying any applicable taxes. Downgrading your account may result in a loss of content, features, or account capacity, and PHC is not responsible for any such losses.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            5.5 Refunds
                        </Text>
                        <Text>
                            Refund requests will be handled on a case-by-case basis. PHC is not obliged to provide a refund if the Services have been rendered as agreed. There will be no refunds or credits for partial months, changes in service plans, or failure to use the service.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            5.6 Cancellation of Service
                        </Text>
                        <Text>
                            You may cancel your service or account at any time. Cancellations take effect immediately, and you will not be charged for future months. If you cancel your paid plan but keep your account, you will retain access. If you choose to close your account entirely, you will no longer be able to log in. Once canceled or closed, content associated with your account may be deleted at any time without notice.
                            If you have questions about canceling your account, please contact us at <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link>. PHC reserves the right to suspend or deactivate accounts at its discretion, including cases of excessive resource usage. Such actions may result in account deletion and the loss of all associated content.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            6. Service Provision
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            6.1 Scope of Services
                        </Text>
                        <Text>
                            PHC provides document generation services tailored to your needs. We strive to ensure that our documents are accurate and of high quality.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            6.2 User Responsibilities
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.1</span> You are responsible for
                            ensuring that using the Software complies with the laws of your jurisdiction. If such use is not permitted, do not access or use the Software.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.2</span> You are responsible for providing accurate and complete information for document generation. PHC is not responsible for any errors or omissions in the documents due to inaccurate information provided by you.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.3</span> The Software allows you to create and complete digital products, including but not limited to pay stubs, invoices, Quote, Receipt, W 2 forms, 1099 forms and other forms (each, a &#34;Document&#34;), by:
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.4</span> Document Creation – Selecting a Document template and filling out the form on the Website with the requested information. You must create Documents only for lawful purposes. Before clicking &#34;Checkout&#34; to proceed, you must confirm your acceptance of these Terms and our Privacy Policy and affirm that you are not using the Website or Software for any illegal or fraudulent purpose. After providing the requested information, your document is created upon clicking &#34;Checkout&#34; and completing the payment procedures.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.5</span> After Document creation, you will be prompted to complete the checkout process, which includes payment steps.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.6</span> Connecting with a payment provider is necessary to use the Software&#39;s features, requiring payment through a third-party provider.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.7</span> As part of the document creation process, you must provide certain information, such as your email address, social security number, and location or those of your employees or associates. For more details on the data we collect, refer to the Privacy Policy. All information you provide must be accurate, current, and complete.
                        </Text>
                        <Text>
                            <span style={{fontWeight: "500", fontStyle: "italic"}}>6.2.8</span> Phcworkhub reserves the right to refuse to create a Document at its sole discretion.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            7. Disclaimer
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            7.1 Disclaimer of Financial Advice
                        </Text>
                        <Text>
                            PHCworkhub does not offer Financial, Tax, or other professional advice. PHCworkhub is not a Broker, Financial Advisor, Investment Advisor, Portfolio Manager, or Tax Advisor. Nothing in the software or the service provided should be construed as financial or investment advice. You are responsible for your use of information obtained through the website. Seek assistance from a qualified professional in each field. Use the service at your own risk.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            7.2 Disclaimer of Legal Advice
                        </Text>
                        <Text>
                            PHC is not a law firm and does not offer legal advice, legal defense, legal opinions, legal recommendation, or any entity strategy. The services and information provided by PHC are not a substitute for the advice of an attorney who is licensed to practice law and familiar with the specifics of your situation.
                            Using the website or purchasing services from PHC does not establish a confidential attorney-client relationship. For legal matters, you should seek assistance from a qualified attorney who can provide tailored advice based on your unique circumstances.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            7.3 Warranty Disclaimer
                        </Text>
                        <Text>
                            To the fullest extent permitted by law, PHC disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. PHC does not guarantee that the Services will be error-free or that any defects will be corrected. The website is offered strictly on an &#34;as is,&#34; &#34;where is,&#34; and &#34;where available&#34; basis.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            7.4 General Limitation
                        </Text>
                        <Text>
                            PHCworkhub, its directors, officers, employees, agents or third-party provider shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the Services. The information available on the website is sourced or compiled from what are believed to be reliable sources, PHCworkhub does not guarantee its accuracy, validity, timeliness, or completeness for any purpose.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            7.5 Maximum Liability
                        </Text>
                        <Text>
                            Our maximum aggregate liability to you shall be limited to the fees you have paid to us in the twelve months preceding the claim.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            8. Indemnification
                        </Text>
                        <Text>
                            You agree to indemnify, defend, and hold harmless PHC, its affiliates, and their respective directors, officers, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses arising from your breach of these Terms or your use of the Services:
                            Any breach of this Agreement, including but not limited to the representations and warranties outlined herein;
                            Any third-party claims alleging infringement of copyright, intellectual property rights, or privacy violations resulting from your use of the Website or Services or the availability of content you provide to other users;
                            Any activity related to your use of the Website or Services, whether performed by you or someone else accessing your account, unless such activity results from an act or omission by PHCworkhub.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            9. Intellectual Property
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            9.1 Ownership
                        </Text>
                        <Text>
                            All content and materials provided through our Services, including but not limited to text, graphics, logos, and software, are the property of PHCworkhub or its licensors and are protected by intellectual property laws.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"600"}>
                            9.2 Limited License
                        </Text>
                        <Text>
                            PHC grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our Services for personal or internal business use. You may not reproduce, download, modify, display, distribute, or create derivative works from our content without our express written consent. This license is valid for the duration of your access to the Service and the relevant subscription period, if applicable. The intellectual property associated with the Software (including all information, data, code, products, materials, services, software applications and tools, APIs, design elements, text, forms, images, photographs, illustrations, audio and video content, artwork, and graphics) is licensed to you by Phcworkhub and not sold. You do not have the right to access the Software in its source code form. Phcworkhub may inform you if any part of the Software contains third-party intellectual property governed by separate licenses, and you agree to comply with those terms.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            10. Third-Party Content
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            10.1 Information Use
                        </Text>
                        <Text>
                            Any content provided through our Services is for informational purposes only. You should independently verify and research any information before making financial or investment decisions. PHCworkhub disclaims any responsibility for the accuracy or completeness of third-party information.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            10.2 Links to Third-Party Platforms
                        </Text>
                        <Text>
                            Our Services may contain links to third-party platforms. Phcworkhub is not responsible for the content or functionality of these platforms. Accessing third-party platforms through our website is at your own risk. You should be aware these Terms no longer govern.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            11. Availability of Services
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            11.1 Availability of Services
                        </Text>
                        <Text fontWeight={"600"}>
                            PHC strives to always ensure the availability of its Services but cannot guarantee uninterrupted access. The Services are provided &#34;as is&#34; and &#34;as available.&#34; PHC disclaims any liability for any interruption or unavailability of the Services.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            11.2 Suspension of Services
                        </Text>
                        <Text>
                            Phcworkhub may suspend or interrupt the provision of the Service, in whole or in part, without any liability to the user.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            11.3 Support Services
                        </Text>
                        <Text>
                            We provide support services for the operation of the Software. If you become aware of misuse, including libelous or defamatory conduct, you must report it to PHC. For assistance, contact us at <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link>.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            13. Arbitration and Dispute Resolution
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.1 Pre-Arbitration Notice
                        </Text>
                        <Text>
                            Before initiating arbitration, both parties agree to notify each other of the dispute in writing and attempt to resolve it informally within 30 days. To notify us, email <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link> If unresolved, either party may commence arbitration by filing a Demand for Arbitration with the AAA.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.2 Binding Arbitration
                        </Text>
                        <Text>
                            Any dispute arising from these Terms or the use of our Services will be settled by binding individual arbitration. The arbitrator will decide any questions regarding the enforceability of this section.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.3 Time Limit for Disputes
                        </Text>
                        <Text>
                            Disputes must be initiated within 120 days of the cause of action. For disputes related to a chargeback, follow your financial institution&#39;s rules.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.4 Governing Law and Arbitration Rules
                        </Text>
                        <Text>
                            This section is governed by the Federal Arbitration Act. Arbitration will be administered by the AAA under its Consumer Arbitration Rules. The AAA Rules are available at www.adr.org.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.5 Arbitration Fees
                        </Text>
                        <Text>
                            Arbitration fees and arbitrator compensation will be governed by the AAA Rules. PHC will pay all arbitration fees and expenses if they are deemed excessive by the arbitrator. Attorneys&#39; fees and costs shall be awarded if your claim or defense is groundless or frivolous.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.6 Arbitrator&#39;s Decision
                        </Text>
                        <Text>
                            The arbitrator&#39;s decision will include essential findings and conclusions. The decision may be entered in any court with proper jurisdiction. The arbitrator may award any relief allowed by law or the AAA Rules.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.7 Waiver of Jury Trial
                        </Text>
                        <Text>
                            You waive the right to a trial by jury for any disputes between you and PHC.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            13.8 Changes to Arbitration Section
                        </Text>
                        <Text>
                            If we change this arbitration section, you may reject the change by notifying us in writing within 30 days. This does not affect prior arbitration agreements.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            14. General
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            14.1 Entire Agreement
                        </Text>
                        <Text>
                            These Terms constitute the entire agreement between you and PHC regarding the use of the Services. Failure to enforce any right or remedy under these Terms does not constitute a waiver of such right or remedy. If any provision of these Terms is found to be illegal or unenforceable, the remaining provisions will remain in effect.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            14.2 Independent Contractors
                        </Text>
                        <Text>
                            The relationship between the parties is that of independent contractors. Nothing in these Terms creates any agency, partnership, joint venture, employment, or fiduciary relationship.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            14.3 Governing Law
                        </Text>
                        <Text>
                            These Terms, along with our legal obligations are governed by the laws of the State of Georgia, USA without regard to its conflict of law&#39;s provisions. You hereby consent to this exclusive jurisdiction.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            14.4 Assignment
                        </Text>
                        <Text>
                            You may not assign any rights or delegate any obligations under these Terms without our written consent. PHCworkhub may transfer its rights and obligations to a third party.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            15. Notices
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            15.1 Sending Notice
                        </Text>
                        <Text>
                            We may provide notices by sending an email to the address you provided or by posting on the Website. Notices sent by email are effective when sent; notices posted on the Website are effective upon posting. You can give us notice by sending email to <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link>.
                        </Text>
                        <Text fontSize={"18px"} fontWeight={"600"} fontStyle={"italic"}>
                            15.2 Requests for Consent
                        </Text>
                        <Text>
                            To request consent for actions requiring consent under these Terms, email <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link>. PHCworkhub reserves the right to refuse such requests at its discretion.
                        </Text>
                        <Text fontSize={"20px"} fontWeight={"700"}>
                            16. Contact Information
                        </Text>
                        <Text>
                            For any questions about these Terms and Conditions, please contact us at <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link>.
                        </Text>
                        <Text fontWeight={"600"}>
                            By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                        </Text>
                    </Box>
                </Box>

            </Box>
        </AppLayout>
    )
}


export default TermsAndCondition