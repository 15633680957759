import {Box, Flex} from "@chakra-ui/react";
import AppLayout from "../../Layouts/AppLayout";
import PayStubForms from "./Components/PayStubForms";
import PayStubPreviewSection from "./Components/PayStubPreviewSection";
import React, {useEffect, useState} from "react"
import {useAppSelector} from "../../Hooks/useAppSelector";
import {initialPaystubsState, loadPaystub, resetPayStubs, selectPaystubData} from "../../Store/Slices/paystubSlice";
import {useFormik} from "formik";
import useHandleUploadLogo from "../../Hooks/useHandleUploadLogo";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import {IPaystubData} from "../../Types/Interface/IPaystub";
import {setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import {AppRoutes} from "../../Routes/AppRoutes";
import {useNavigate} from "react-router-dom";
import useFormSubmit from "../../Hooks/useFormSubmit";
import submitPaystub, {getPaystub} from "../../Services/api/paystub";
import NextButtonPreviewBar from "../../Components/NextButtonPreviewBar";
import PreviewHeader from "../../Components/PreviewHeader";
import useMobilePreview from "../../Hooks/useMobilePreview";
import useFetchFormData from "../../Hooks/useFetchFormData";

const PayStub = () => {
    const navigate = useNavigate();
    const data = useAppSelector(selectPaystubData)
    const dispatch = useAppDispatch()
    const [logo, setLogo] = useState("")
    const { downloaded } = data
    const {submit, isSuccess, isLoading} = useFormSubmit(submitPaystub, FormType.Paystub)
    const {isNextPage, setIsNextPage} = useMobilePreview()
    const {loading: dataLoading, data: fetchedPaystub} = useFetchFormData(getPaystub, FormType.Paystub)

    const { handleChange, handleSubmit, values, setFieldValue, } = useFormik({
        initialValues: downloaded ? initialPaystubsState : data ,
        onSubmit: (values, formikHelpers) => {
            if (!isLoading){
                formikHelpers.setSubmitting(true)
                submit(data)
                formikHelpers.setSubmitting(false)
            }
        }
    })

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.Paystub))
            dispatch(setDocumentCount(data.info.payStubCount))
            dispatch(setFormType(FormType.Paystub))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess, data]);

    const handleLogoUpload = useHandleUploadLogo({
        companyName: values.company.name, companyEmail: values.company.zipcode, setLogoUrl: (logo) => {
            setLogo(logo)
            setFieldValue("company.logo", logo)
        }
    })

    useEffect(() => {
        if (downloaded) {
            dispatch(resetPayStubs())
        }
    }, [downloaded])

    useEffect(() => {
        const employee = values.employee
        const company = values.company
        const editedData: IPaystubData = {
            ...data, company, employee
        }
        dispatch(loadPaystub(editedData))
    }, [values.employee, values.company]);

    useEffect(() => {
        if  (fetchedPaystub !== undefined){
            dispatch(loadPaystub(fetchedPaystub))
        }
    }, [fetchedPaystub]);


    return (
        <AppLayout isForm loading={dataLoading}>
            {/*  Forms and Preview*/}
            <form onSubmit={handleSubmit}>
                <Flex w={"full"} display={{base: "none", lg: "flex" }}  px={"50px"} py={"50px"} gap={"64px"} pos={"relative"}>
                    <PayStubForms handleChange={handleChange} values={data} setFieldValue={setFieldValue} handleLogo={handleLogoUpload}/>
                    <PayStubPreviewSection data={data} logo={logo} submit={handleSubmit} />
                </Flex>
                <Box display={{lg: "none" }} px={"16px"} position={"relative"}>
                    {
                        isNextPage ? (
                            <>
                                <PreviewHeader goBack={()=> setIsNextPage(false)}/>
                                <PayStubPreviewSection data={data} logo={logo} submit={handleSubmit} />
                            </>
                        ) : (
                            <>
                                <PayStubForms handleChange={handleChange} values={data} setFieldValue={setFieldValue} handleLogo={handleLogoUpload}/>
                                <NextButtonPreviewBar formType={FormType.Paystub} setNextPage={() => setIsNextPage(true)}/>
                            </>
                        )
                    }
                </Box>
            </form>
        </AppLayout>
    )
}


export default PayStub