import {IFormResponse, IGetFormResponse} from "../../Types/Interface/IForm";
import {reqClient} from "../../Api/reqClient";
import {transformNecData} from "../../Hooks/useFill1099Nec";
import {I1099Nec} from "../../Pages/1099Nec/validator";
import {F1099SubmissionDto} from "../../schemas/F1099NecSchema";
import {IGet1099Nec} from "../../Types/Interface/I1099Nec";

const submit1099Nec = async (data: I1099Nec, guid?: string): Promise<IFormResponse> => {
    const url = `/_1099/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformNecData(data)
    const body = {
        "_1099Dto": {
            ...transformedData._1099Dto,
            payer: {...transformedData._1099Dto.payer, isCompletedForm: true},
            recipient: {
                ...transformedData._1099Dto.recipient, isCompletedForm: true
            },
            _1099NECOptions: {
                ...transformedData._1099Dto._1099NECOptions, isCompletedForm: true
            }
        }
    }

    const parsedData = F1099SubmissionDto.parse(body)
    const response = await reqClient.post(url, parsedData)
    return response.data
}

export const get1099Nec = async (guid: string): Promise<IGetFormResponse<I1099Nec>> => {
    const url = `/_1099/${guid}`
    const response = await reqClient.get(url)
    const {isSuccess, data, errorMessage}: IGetFormResponse<IGet1099Nec> = response.data
    const transformedData: I1099Nec = {
        downloaded: false,
        info: {
            accept: false,
            agree: false,
            email: data.email,
            formCount: data.formCount,
            year: data.year.toString()
        },
        payer: {
            address: {
                stateAddress: data.payer.address,
                city: data.payer.city,
                state: data.payer.state,
                zipcode: data.payer.zipCode,
            },
            name:  data.payer.name,
            tin:  data.payer.tin
        },
        recipients: [
            {
                address: {
                    stateAddress:  data.recipient.address,
                    city: data.recipient.city,
                    state: data.recipient.state,
                    zipcode: data.recipient.zipCode,
                },
                name: data.recipient.name,
                ssn: data.recipient.tin,
                options: {
                    nonEmployeeCompensation: data._1099NECOptions.nonEmployeeCompensation,
                    directSales: data._1099NECOptions.directSalesOver5000,
                    incomeTax: data._1099NECOptions.federalIncomeTaxWithHeld,
                    income: data.lastYearIncomeBeforeTaxes,
                    accountNumber: data.accountNumber,
                    stateTax: data._1099NECOptions.stateTaxWithHeld,
                    payersNo: data.payer.statePayerStateNumber,
                    stateIncome: data._1099NECOptions.stateIncome,
                },
            }
        ]
    }
    return {isSuccess, data: transformedData, errorMessage}
}

export default submit1099Nec