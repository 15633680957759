import AppLayout from "../../Layouts/AppLayout";
import {Box, Flex, Link, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import React from "react";
import useDeviceQuery, {ScreenType} from "../../Hooks/useDeviceQuery";

const PrivacyPolicy = () => {
    const device = useDeviceQuery()
  return (
      <AppLayout>
          <Box py={device === ScreenType.Iphone ? "64px" : "80px"}>
              <Flex
                  fontWeight={"800"}
                  px={{md: "72px", lg: "111px"}}
                  justifyContent={{base: "center", md: "start"}}
                  alignItems={"center"}
                  h={{base: "106px", md: "120px", lg: "157px"}}
                  textTransform={"uppercase"}
                  fontSize={{base: "36px", md: "40px", lg: "48px"}}
                  color={"white"}
                  bgGradient={{
                      base: "linear(to-r, #1fbc6c, #1fbc6c)",
                      md: "linear(to-r, #1FBC6C 0%, #7DDCAC 123.76%, #C6F6DE 162.58%)"}}>
                  Privacy Policy
              </Flex>
              <Box  px={{base: "24px", md: "50px", lg: "155px"}} mt={"40px"} textTransform={"uppercase"}>

              <Text fontSize={"20px"} fontWeight={"700"} mb={{base: "40px", md: "8px"}} textAlign={{base: "center", md: "start"}}>
                  Last Updated: 03/17/2025
              </Text>
                  <Box px={{md: "60px", lg: "72px "}} lineHeight={"51px"} fontWeight={"18px"}>
                      <Text>
                          PHCWorkHub (&#34;we,&#34; &#34;us,&#34; or &#34;our&#34;) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.phcworkhub.com] or use our services. By visiting and registering at phcworkhub, you consent and authorize us to collect and use your personal information as per this document as well as the Terms of Service. PHCworkhub reserves the right to update and modify this Privacy Policies whenever it becomes necessary.
                          Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
                      </Text>
                      <Text fontSize={"20px"} fontWeight={"700"}>
                          1. Information We Collect
                      </Text>
                      <UnorderedList px={{md: "24px"}}>
                          <ListItem>
                              <span style={{fontWeight: "600"}}>Personal Information:</span> We may collect personal information that you voluntarily and directly provided to us, such as your name, email address, mailing address, phone number, and payment information. Payments are made through stripe. Stripe collects credit cards details on their website. <Link color={"blue.500"} href={"https://stripe.com/privacy"} isExternal>Click here for their privacy policy.</Link>
                          </ListItem>
                          <ListItem>
                              <span style={{fontWeight: "600"}}>Document Information:</span> We
                              collect and store the documents you generate, upload, or modify using our services.
                          </ListItem>
                          <ListItem>
                              <span style={{fontWeight: "600"}}>Usage Data:</span> We may collect
                              information about your interactions with our website and services, including your IP address, browser type, operating system, device information, and pages visited.
                          </ListItem>
                          <ListItem>
                              <span style={{fontWeight: "600"}}>Cookies and Tracking Technologies:</span> We
                              use cookies and similar tracking technologies to track activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                          </ListItem>
                      </UnorderedList>
                      <Text fontSize={"20px"} fontWeight={"700"} mt={"16px"}>
                          2. How We Use Your Information
                      </Text>
                      <Text>
                          PHCWorkHub prioritizes your privacy above all else. We implement stringent measures to prevent the loss, alteration, or misuse of your personal information. We do not share, rent, or disclose your information to anyone except our employees, who need access to provide you with comprehensive support and services. All information is protected by passwords, ensuring that only authorized employees or representatives involved in providing support can access it.
                      </Text>
                      <Text>
                          We use the information we collect in various ways, including to:
                      </Text>
                      <UnorderedList px={{md: "24px"}}>
                          <ListItem>
                              Provide, operate, and maintain our website and services.
                          </ListItem>
                          <ListItem>
                              Improve, personalize, and expand our website and services.
                          </ListItem>
                          <ListItem>
                              Process transactions and manage your orders.
                          </ListItem>
                          <ListItem>
                              Communicate with you, either directly
                          </ListItem>
                      </UnorderedList>
                      <Text>
                          If you have any questions regarding this privacy policy, you can email us at <Link href={"mailto:support@phcworkhub.com"}>support@phcworkhub.com</Link> or write us at 3255 Lawrenceville Suwanee Rd Ste P PMB 630 Suwanee, GA 30024.
                      </Text>
                  </Box>
              </Box>

          </Box>
      </AppLayout>
  )
}

export default PrivacyPolicy