import React from "react";
import {
    Box,
    Text,
    Flex,
    InputGroup,
    Input,
    InputRightElement,
    Button,
    Grid,
    GridItem,
    ResponsiveValue
} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {AppRoutes} from "../Routes/AppRoutes";


type TNavLink = {
    description: string,
    notLink?: boolean,
    link: string,
}

const AppFooterV2 = () => {
  return (
      <footer style={{background: "#00645A", boxShadow: ""}}>
          <Box px={{base: "16px", md: "34px"}} py={"24px"}>
              <Box display={{ md: "none"}}>
                  <NewsLetter isFlex={true}/>
              </Box>
              <Grid templateColumns={'repeat(12, 1fr)'} mx={{base: "12px", md: "50px"}} mt={{base: '16px', md: '0px'}} gap={{base: "8px", lg: "52px"}}>
                  <FooterLinkBox
                      span={{base: 4, lg: 3}}
                      width={"201px"}
                      navLinks={[
                          {
                              description: "About Us",
                              link: "#"
                          },
                          {
                              description: "Pricing",
                              link: AppRoutes.pricing
                          },
                          {
                              description: "Blog",
                              link: "#"
                          },
                          {
                              description: "FaQs",
                              link: "#"
                          }
                      ]}
                      title={"Company"}
                      />
                  <FooterLinkBox
                      span={{base: 4, lg: 2}}
                      width={"168px"}
                      navLinks={[
                          {
                              description: "Whatsapp",
                              link: "#"
                          },
                          {
                              description: "Twitter",
                              link: "#"
                          },
                      ]}
                      title={"Support / Live Chat"}
                      mobileTitle={"Support"}
                      />
                  <FooterLinkBox
                      span={{base: 4, lg: 3}}
                      width={"280px"}
                      navLinks={[
                          {
                              description: "support@phcworkhub.com",
                              link: "mailto:support@phcworkhub.com"
                          },
                          {
                              notLink: true,
                              description: "3255 Lawrenceville Suwanee Rd \n" +
                                  "Ste P PMB 630, \n" +
                                  "Suwanee, GA 30024.",
                              link: "#"
                          },
                      ]}
                      title={"Contact Us"}
                      />
                  <GridItem colSpan={4} display={{base: "none", lg: "block"}}>
                      <NewsLetter/>
                  </GridItem>
              </Grid>
              <Grid templateColumns={'repeat(12, 1fr)'} mx={{base: "12px", md: "50px"}} gap={{base: "8px", lg: "52px"}}>
                  <FooterLinkBox
                      span={{base: 12, md: 4, lg: 3}}
                      width={"201px"}
                      navLinks={[
                          {
                              description: "Whatsapp",
                              link: "#"
                          },
                          {
                              description: "Twitter",
                              link: "#"
                          },
                          {
                              description: "TikTok",
                              link: "#"
                          },
                          {
                              description: "LinkedIn",
                              link: "#"
                          },
                          {
                              description: "Instagram",
                              link: "#"
                          }
                      ]}
                      title={"Connect with PhcWorkHub"}
                  />
                  <FooterLinkBox
                      span={{base: 4, lg: 2}}
                      width={"168px"}
                      navLinks={[
                          {
                              description: "Home",
                              link: "/"
                          },
                          {
                              description: "Paystub",
                              link: AppRoutes.payStub
                          },
                          {
                              description: "W2",
                              link: AppRoutes.w2
                          },
                          {
                              description: "1099",
                              link: AppRoutes.f1099Misc
                          },
                          {
                              description: "Real Estate",
                              link: AppRoutes.realEstate
                          },
                          {
                              description: "Business",
                              link: "#"
                          },
                      ]}
                      title={"Explore"}
                  />
                  <GridItem colSpan={4} display={{base: "none", md: "block", lg: "none"}}>
                      <NewsLetter/>
                  </GridItem>
              </Grid>
              <Flex justifyContent={"space-between"} color={"white"} borderTopWidth={"1px"} borderColor={"white"} pt={"14px"} fontSize={{base: "10px", md: "16px"}}>
                  <Flex>
                      <Text mr={"8px"} fontWeight={"light"}>
                          Copyright &copy; {new Date().getFullYear()}
                      </Text>
                      <Text color={"#1fbc6c"} fontWeight={"light"}>
                          <NavLink to={"/"}>
                              phcworkhub.com
                          </NavLink>
                      </Text>
                  </Flex>
                  <Text fontWeight={"light"}>
                      All Rights Reserved.
                  </Text>
                  <Flex gap={"8px"}>
                      <NavLink to={AppRoutes.privacyPolicy}>
                          <Text fontWeight={"light"}>
                              Privacy Policy.
                          </Text>
                      </NavLink>
                      <NavLink to={"/#"}>
                          <Text fontWeight={"light"}>
                              Terms and Conditions.
                          </Text>
                      </NavLink>
                  </Flex>
              </Flex>
          </Box>
      </footer>
  )
}

const FooterLinkBox = ({navLinks, title, width, span, mobileTitle}: {navLinks: TNavLink[], title: string, mobileTitle?: string, width?: string, span?: number | ResponsiveValue<number | "auto">}) => {
    return (
        <GridItem colSpan={span} color={"white"} maxW={width}>
            <Box py={"10px"} borderBottomWidth={"0.5px"} borderColor={"white"} mb={"7px"} width={"100%"}>
                {
                    mobileTitle ? (
                        <>
                            <Text display={{base: "none", md: "block"}} fontSize={"16px"}>
                                {title}
                            </Text>
                            <Text display={{md: "none"}} fontSize={"16px"}>
                                {mobileTitle}
                            </Text>
                        </>
                        ) : (
                        <Text fontSize={"16px"}>
                            {title}
                        </Text>
                    )
                }
            </Box>
            {
                navLinks.map(navLink => (
                    <Text key={navLink.description} maxW={"290px"} fontSize={"14px"} fontWeight={"light"} mb={"7px"}>
                        {navLink.notLink ? navLink.description : <NavLink to={navLink.link}>{navLink.description}</NavLink>}
                    </Text>
                ))
            }
        </GridItem>
    )
}

const NewsLetter = ({isFlex}: {isFlex?: boolean}) => {
    return (
        <Box>
            {
                isFlex ? (
                    <Flex py={"10px"} borderBottomWidth={"0.5px"} borderColor={"white"} mb={"7px"} w={"100%"} justifyContent={"center"}>
                        <Text fontSize={"16px"} color={"white"}>
                            Sign-up to our newsletter to receive updates, invitations to our events and access to special promotions.
                        </Text>
                    </Flex>
                ) : (
                    <Box py={"10px"} borderBottomWidth={"0.5px"} borderColor={"white"} mb={"7px"} maxW={"400px"}>
                        <Text fontSize={"16px"} color={"white"}>
                            Sign-up to our newsletter to receive updates, invitations to our events and access to special promotions.
                        </Text>
                    </Box>
                )
            }
            <InputGroup size='md'>
                <Input
                    colorScheme={"green"}
                    pr='4.5rem'
                    type={'email'}
                    placeholder='Enter Email'
                />
                <InputRightElement width='4.5rem'>
                    <Button colorScheme={"green"} borderLeftRadius={"0px"} borderWidth={"1px"} height={"100%"} borderLeftWidth={"0px"} borderColor={"transparent"} onClick={()=> {}}>
                        Sign up
                    </Button>
                </InputRightElement>
            </InputGroup>
        </Box>
    )
}

export default AppFooterV2