import React, {createContext, useContext, useLayoutEffect, useState} from "react";

enum ScreenType {
    Iphone,
    Ipad,
    Macbook,
}

const DeviceQueryContext = createContext<ScreenType>(ScreenType.Iphone)

const isIpad = (width: number): boolean => 834 < width && width <=1512
const isMobile = (width: number): boolean => 0 < width && width <=834
const isMacbook = (width: number): boolean => 1512 < width

const DeviceQueryWrapper = ({children}: {children: React.ReactNode})  => {
    const [media, setMedia] = useState<ScreenType>(ScreenType.Iphone)

    const getScreenType = (width: number) =>{
        if (isMobile(width)){
            setMedia(ScreenType.Iphone)
        } else if (isIpad(width)){
            setMedia(ScreenType.Ipad)
        } else if (isMacbook(width)){
            setMedia(ScreenType.Macbook)
        }
    }

  useLayoutEffect(()=> {
      const handleScreenSize = (): void => {
          getScreenType(window.innerWidth)
      }
      if (window){
          getScreenType(window.innerWidth)
         window.addEventListener("resize", handleScreenSize)
      }

      return () => {
          window.removeEventListener("resize", handleScreenSize)
      }
  }, [])
    
    return <DeviceQueryContext.Provider value={media}>
        {children}
    </DeviceQueryContext.Provider>
}

const useDeviceQuery = () => {
    return useContext(DeviceQueryContext)
}

export {ScreenType, DeviceQueryContext, DeviceQueryWrapper}
export default useDeviceQuery